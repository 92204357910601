import React, { useEffect, useState } from "react";

const ProgressChart = ({ percentage }) => {
    if (percentage < 0) {
        percentage = 0;
    } else if (percentage > 100) {
        percentage = 100;
    }

    const [dashOffset, setDashOffset] = useState(100);

    useEffect(() => {
        setDashOffset(100 - percentage);
    }, [percentage]);

    return (
        <div className="flex justify-center">
            <svg viewBox="0 0 100 100" className="w-20">
                {/* Background circle */}
                <circle stroke="#d9edd9" strokeWidth="9" cx="50" cy="50" r="30" fill="none" />

                {/* Progress circle */}
                <circle
                    id="progress--circle"
                    stroke="#41a641"
                    strokeWidth="9"
                    cx="50"
                    cy="50"
                    r="30"
                    fill="none"
                    pathLength="100"
                    style={{
                        strokeDasharray: 100,
                        strokeDashoffset: dashOffset,
                        strokeLinecap: "round",
                        stroke: percentage === 0 ? "none" : "#41a641",
                    }}
                />
                {/* Percentage text */}
                <text
                    className={`font-semibold !text-[#41a641] ${percentage === 100 ? "text-base" : "text-xl"}`}
                    x="50"
                    y="50"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        fontFamily: '"JetBrains Mono", monospace',
                        fill: "#41a641",
                        transformOrigin: "center",
                    }}
                >
                    {percentage}%
                </text>
            </svg>
        </div>
    );
};

export default ProgressChart;
