import React from "react";
import Header from "./Header";
import Description from "./Description";

function PersonDirectory() {
    return (
        <main className="flex flex-col sm:text-center">
            <section className="flex flex-col max-md:max-w-full">
                <Header />
                <Description />
            </section>
        </main>
    );
}

export default PersonDirectory;
