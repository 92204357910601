import React, { useState, useRef } from "react";
import useScheduledEmails from "@/hooks/useScheduledEmails";
import AffirmationModal from "./AffirmationModal";
import { EditEmailModal } from "./EditEmailModal";

function EditDropdown({ onDecline, scheduledEmailId, data, handleEditEmail }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const { approveEmails } = useScheduledEmails();
    const approveEmailsRef = useRef();
    approveEmailsRef.current = approveEmails;

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDeleteClick = () => {
        setIsModalVisible(true);
    };

    const declineMail = async () => {
        const response = await approveEmailsRef.current([scheduledEmailId], false);
        setIsModalVisible(false);
        onDecline();
    };

    return (
        <div className="relative" onClick={toggleDropdown}>
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/eeb49a215c7b09e621a4909089328561fdced73749f7d6a30274114545ab23fa?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                alt="Options"
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square cursor-pointer"
            />

            {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <a
                            onClick={() => {
                                setIsEditModalVisible(true);
                            }}
                            href="#"
                            className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                        >
                            Edit
                        </a>
                        <a onClick={handleDeleteClick} href="#" className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100">
                            Delete
                        </a>
                    </div>
                </div>
            )}
            {isModalVisible && (
                <AffirmationModal
                    message="Are you sure you want to decline this email?"
                    handleConfirm={declineMail}
                    onClose={handleCloseModal}
                />
            )}
            <EditEmailModal
                data={data}
                onClose={() => setIsEditModalVisible(false)}
                onConfirm={() => {
                    console.log("Confirm Clicked");
                    setIsEditModalVisible(false);
                }}
                open={isEditModalVisible}
                handleEditEmail={handleEditEmail}
            />
        </div>
    );
}

export default EditDropdown;
