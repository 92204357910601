import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "@/theme/palette";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";

export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const setThWidth = (index) => {
        if (index === 0) {
            return "35%";
        } else if (index === 1) {
            return "25%";
        } else {
            return "auto";
        }
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "5px 10px",
                    fontFamily: "Outfit",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F6F6F6",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell padding="checkbox" className="rounded-tl-[6px]">
                    <Checkbox
                        checked={props.totalRowsOnPage > 0 && props.numSelectedOnPage === props.totalRowsOnPage}
                        onChange={props.onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all contacts",
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.blue,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.blue,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                    />
                </TableCell>
                {props &&
                    props.headCells?.length > 0 &&
                    props.headCells.map((headCell, index) => (
                        <TableCell
                            width={setThWidth(index)}
                            key={headCell.id}
                            align={headCell.id === "action" ? "center" : "left"}
                            padding={"none"}
                            sx={{ borderTop: "none" }}
                            className={`${index === props.headCells?.length - 1 ? "rounded-tr-[6px]" : ""}`}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ?? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                )}
                            </TableSortLabel>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
