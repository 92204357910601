import { create } from "zustand";
import { devtools } from "zustand/middleware";

const defaultState = {
    uploads: [],
    target: "",
    targetEntity: "",
    profileType: "",
    results: null,
    show: false,
    loading: false,
};

const useCRMPushResultStore = create(
    devtools((set, get) => ({
        ...defaultState,
        setResults: (results) => set({ results }),
        setUploads: (uploads) => set({ uploads }),
        setProfileType: (profileType) => {
            set({ profileType });
        },
        setTarget: (target) => set({ target }),
        setTargetEntity: (target) => set({ targetEntity: target }),
        toggleShow: () => {
            set({ show: !get().show });
            if (!get().show) {
                get().reset();
            }
        },
        toggleLoading: () => set({ loading: !get().loading }),
        reset: () => set(defaultState),
    }))
);
export default useCRMPushResultStore;
