import { useEffect, useState, createContext } from "react";
import { useSearchParams, Routes, Route } from "react-router-dom";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import BaseContainer from "@/components/BaseContainer";
import { WarmupSummary } from "@/components/email_warmup/summary/WarmupSummary";
import { EmailAccount } from "@/components/email_warmup/email_account/EmailAccount";
import { WarmupSettings } from "@/components/email_warmup/settings/WarmupSettings";
import { toast } from "react-toastify";
import useAuth from "@/hooks/useAuth";
import MainLoader from "@/components/MainLoader";

// Create a context to share emailAccount data
export const EmailWarmupContext = createContext();

export const EmailWarmupScene = () => {
    const { getAuthToken, getUserInfo } = useAuth();
    const [searchParams] = useSearchParams();
    // const [activePage, setActivePage] = useState("summary");
    const [isLoading, setIsLoading] = useState(true);
    const [userInformation, setUserInformation] = useState(null);
    const [emailAccount, setEmailAccount] = useState(null);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(0);

    useEffect(() => {
        const addNewWarmupCampaign = async ({ authToken, values }) => {
            return await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                    campaign: {
                        provider: "outlook",
                        email: values.email,
                        id: values.id,
                    },
                }),
            });
        };
        const fetchMetricsForCampaign = async (campaignId, authToken) => {
            const response = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    campaign: { campaignId, action: "getMetrics" },
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch metrics for campaignId: ${campaignId}`);
            }

            return await response.json();
        };
        const fetchAllCampaigns = async () => {
            try {
                setIsLoading(true);
                const authToken = await getAuthToken();
                const campaign = searchParams.get("campaign");
                const success = searchParams.get("success");
                const email = searchParams.get("email");

                if (campaign && success === "true" && email) {
                    await addNewWarmupCampaign({
                        authToken,
                        values: { id: campaign, email },
                    });
                }

                const { userInfo } = await getUserInfo();
                const res = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/email-warmup`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const json = await res.json();

                if (!json?.status) {
                    throw new Error("Can't fetch your warmup emails, try again later");
                }

                const metricsPromises = json.data.map((campaign) =>
                    fetchMetricsForCampaign(campaign.id, authToken).then((metrics) => ({
                        ...campaign,
                        metrics: metrics.data,
                    }))
                );

                const campaignsWithMetrics = await Promise.all(metricsPromises);

                setAllCampaigns(campaignsWithMetrics);
                setUserInformation(userInfo);
            } catch (e) {
                toast.error(e.message, { theme: "colored" });
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllCampaigns();
    }, [fetchTrigger]);

    useEffect(() => {
        const redirectStatus = searchParams.get("redirect_status");

        if (redirectStatus === "succeeded") {
            toast.success("Payment successful. Pleasant use");
        }
    }, [searchParams]);

    return (
        <EmailWarmupContext.Provider
            value={{
                emailAccount,
                setEmailAccount,
                allCampaigns,
                setAllCampaigns,
                setFetchTrigger,
                userInformation,
                setUserInformation,
            }}
        >
            <MainLayout>
                <MainTitleBar>{/* <p>AI Email Warmup</p> */}</MainTitleBar>

                {isLoading ? (
                    <MainLoader />
                ) : (
                    <div className="w-full bg-white">
                        <BaseContainer>
                            <Routes>
                                <Route path="/" element={<WarmupSummary />} />
                                <Route path="email/:campaignId" element={<EmailAccount />} />
                                <Route path="settings/:campaignId" element={<WarmupSettings />} />
                            </Routes>
                        </BaseContainer>
                    </div>
                )}
            </MainLayout>
        </EmailWarmupContext.Provider>
    );
};
