import useStrapi from "@/hooks/useStrapi";
import { useEffect, useRef, useState } from "react";
import Card from "../blog/Card";
import greyIcon from "@/assets/image/icons/left-aero.svg";
import blackIcon from "@/assets/image/icons/right-aero.svg";

export default function MostRecentPannel({ currentId }) {
    const [recentPosts, setRecentPosts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { fetchAllPosts } = useStrapi();
    const getThreeRecentPostsRef = useRef();

    const handleLeftButtonCLick = () => {
        setCurrentIndex(currentIndex - 1);
    };

    const handleRightButtonCLick = () => {
        setCurrentIndex(currentIndex + 1);
    };

    const getThreeRecentPost = async (id) => {
        const allPosts = await fetchAllPosts();
        return allPosts.filter((post) => post?.id !== id).slice(0, 3);
    };
    getThreeRecentPostsRef.current = getThreeRecentPost;

    useEffect(() => {
        if (!currentId) return;
        const fetchData = async () => {
            const fetchedPosts = await getThreeRecentPostsRef.current(currentId);
            setRecentPosts(fetchedPosts);
        };

        fetchData();
    }, [currentId]);

    return (
        <>
            {recentPosts?.length > 0 && (
                <div className="lg:mb-12 pt-20 pb-40 px-10 lg:px-20 bg-[#F7F9FD]">
                    <p className="main-title mb-16">Most recent</p>
                    <div className="hidden w-full lg:grid grid-cols-1 lg:grid-cols-3 gap-10">
                        {recentPosts?.length > 0 && recentPosts.map((post, index) => <Card key={index} data={post} />)}
                    </div>
                    <div className="w-full lg:hidden min-h-[500px] flex flex-col justify-between gap-12">
                        {recentPosts?.length > 0 && <Card data={recentPosts[currentIndex]} />}
                        <div className="flex gap-4 justify-center items-center">
                            <button onClick={handleLeftButtonCLick} disabled={currentIndex === 0}>
                                <img
                                    src={currentIndex === 0 ? greyIcon : blackIcon}
                                    alt="PrevButton"
                                    className={currentIndex === 0 ? "" : "rotate-180"}
                                />
                            </button>
                            <button onClick={handleRightButtonCLick} disabled={currentIndex === 2}>
                                <img
                                    src={currentIndex === 2 ? greyIcon : blackIcon}
                                    className={currentIndex === 2 ? "rotate-180" : ""}
                                    alt="NextButton"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
