import useAuth from "@/hooks/useAuth";

const useAutocomplete = () => {
    const { getAuthToken } = useAuth();

    const searchAutoComplete = async ({ params, searchParam }) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(
                `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/autocomplete/${searchParam}?query=${params?.query}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (_resData.ok) {
                const { data } = await _resData.json();
                response = data.response;
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    return {
        searchAutoComplete,
    };
};

export default useAutocomplete;
