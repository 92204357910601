import React from "react";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ContactRow({ firstName, lastName, contactInfo, title: jobTitle, company, country }) {
    let initials;
    if (firstName && lastName) initials = firstName[0] + lastName[0];
    if (!firstName && lastName) initials = lastName[0];
    if (firstName && !lastName) initials = firstName[0];
    let name;
    if (firstName && lastName) name = firstName + " " + lastName;
    if (!firstName && lastName) name = lastName;
    if (firstName && !lastName) name = firstName;

    const navigate = useNavigate();
    return (
        <div className="border-solid border-b-neutral-200 sm:border-none m-0 flex flex-col sm:flex-row justify-center">
            <article className="block sm:hidden gap-4 items-start px-4 py-6 bg-white border-b border-solid border-b-neutral-200">
                <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                    <header className="flex flex-col justify-center w-full">
                        <div
                            className="flex gap-2 items-center self-start"
                            onClick={() => navigate(`/profile/${firstName.toLowerCase()}-${lastName.toLowerCase()}`)}
                        >
                            <div
                                className="self-stretch p-2 pt-3 my-auto w-10 h-10 text-sm leading-none text-center text-black whitespace-nowrap bg-orange-300 min-h-[40px] rounded-[100px]"
                                aria-hidden="true"
                            >
                                {initials}
                            </div>
                            <h1 className="flex flex-col justify-center self-stretch my-auto text-base leading-none text-zinc-950 w-[107px]">
                                <span className="gap-1 self-stretch w-full">{name}</span>
                            </h1>
                        </div>
                        <div className="flex flex-col items-start mt-4 w-full text-sm leading-none">
                            <div
                                className="flex gap-2 items-center"
                                onClick={() => {
                                    navigate("/signin");
                                }}
                            >
                                <span className="self-stretch my-auto text-neutral-600">Contact Info:</span>
                                <div className="flex gap-4 items-center self-stretch my-auto whitespace-nowrap text-zinc-700">
                                    <div className="flex gap-1 items-center self-stretch my-auto">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/23729e7731e7e055d8d9b91f8ea8fda7bf367a9d59b5a5b082ad81d8cd234342?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                                            alt=""
                                            className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                                        />
                                        <span className="self-stretch my-auto">Email</span>
                                    </div>
                                </div>
                                <div className="flex gap-4 items-center self-stretch my-auto whitespace-nowrap text-zinc-700">
                                    <div className="flex gap-1 items-center self-stretch my-auto">
                                        <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fa11fac7b90b5e5b0abcaeb5b57d5086b6a86966b4e3eb9f1a9ccc6352d0d817?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                                            alt=""
                                            className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square"
                                        />
                                        <span className="self-stretch my-auto">Phone</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center mt-2">
                            <span className="self-stretch my-auto text-neutral-600">Company Name:</span>
                            <div className="flex gap-2 items-center self-stretch my-auto min-h-[24px] text-zinc-700">
                                <img loading="lazy" src={company.logo} alt={`${company.name} logo`} className="w-6 h-6" />
                                <span className="self-stretch my-auto">{company.name}</span>
                            </div>
                        </div>

                        <div className="self-stretch mt-2 text-neutral-600">Location: {country}</div>
                    </header>
                </div>
            </article>

            <div className="hidden w-[1400px] sm:flex sm:flex-row items-center border-b border-l border-r border-neutral-200">
                <div
                    className="flex flex-col min-w-[200px] w-[250px] sm:px-4 m-0 bg-white cursor-pointer"
                    onClick={() => navigate(`/profile/${firstName.toLowerCase()}${lastName ? "-" + lastName?.toLowerCase() : ""}`)}
                >
                    <div className="flex gap-4 items-center p-4 pl-2 w-full min-h-[76px]">
                        <div className="flex justify-center px-2 items-center w-7 h-7 text-xs text-center text-black bg-orange-300 rounded-full">
                            {initials}
                        </div>
                        <div className="flex flex-col flex-1 text-sm text-zinc-700">
                            <div>{name}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col text-sm text-zinc-700 w-[250px] pl-6 bg-white">
                    <div className="flex gap-4 items-center p-4 min-h-[76px]">
                        <Tooltip
                            title="Contact Info"
                            className="flex gap-4 items-center cursor-pointer"
                            onClick={() => {
                                navigate("/signin");
                            }}
                        >
                            <div className="flex gap-1 items-center">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/23729e7731e7e055d8d9b91f8ea8fda7bf367a9d59b5a5b082ad81d8cd234342?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                                    className="w-5 h-5"
                                    alt="Email Icon"
                                />
                                <div>Email</div>
                            </div>
                            <div className="flex gap-1 items-center">
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fa11fac7b90b5e5b0abcaeb5b57d5086b6a86966b4e3eb9f1a9ccc6352d0d817?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                                    className="w-5 h-5"
                                    alt="Phone Icon"
                                />
                                <div>Phone</div>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <div className="hidden sm:flex sm:flex-col text-sm text-zinc-700 w-[250px] pl-12 bg-white">
                    <div className="px-2 pt-7 min-h-[76px]">{jobTitle}</div>
                </div>

                <div className="flex flex-col text-sm text-zinc-700 w-[250px] pl-16 bg-white">
                    <div className="flex gap-2 items-center p-4 min-h-[76px]">
                        <img loading="lazy" src={company.logo} className="w-6 h-6 rounded-full" alt={`${company.name} logo`} />
                        <div>{company.name}</div>
                    </div>
                </div>

                <div className="flex flex-col text-sm text-zinc-700 w-[250px] pl-20 bg-white">
                    <div className="p-4 pt-6 min-h-[76px]">{country}</div>
                </div>
            </div>
        </div>
    );
}

export default ContactRow;
