import AllTabs from "@/components/ai_engagement/AllTabs";
import React, { useEffect, useState } from "react";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import Overview from "@/components/ai_engagement/globalAnalyticsTab/Overview";
import EmailSentChart from "@/components/ai_engagement/globalAnalyticsTab/EmailSentChart";
import EmailDeliverReport from "@/components/ai_engagement/globalAnalyticsTab/EmailDeliverReport";
import useAIEngagement from "@/hooks/useAIEngagement";

export default function GlobalAnalytics() {
    const { getGlobalAnalytics } = useAIEngagement();

    // states
    const [apiData, setApiData] = useState({});

    // API CALL, fetching data
    useEffect(() => {
        const fetchGlobalAnalyticsData = async () => {
            try {
                const res = await getGlobalAnalytics();
                setApiData(res?.data || {});
            } catch (error) {
                console.log("Error in fetchGlobalAnalyticsData", error);
                setApiData({});
            }
        };
        fetchGlobalAnalyticsData();
    }, []);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white  pb-10">
                <BaseContainer>
                    <AllTabs />
                    <div className="">
                        <Overview payload={apiData} />
                        <EmailSentChart />
                        <EmailDeliverReport payload={apiData.emailDeliverabilityReport} />
                    </div>
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
