import { v4 as uuid } from "uuid";
import Papa from "papaparse";
import { permissions } from "@/consts/permissions";
import DownSVGIcon from "@/assets/image/icons/down.svg";
import SettingIcon from "@/assets/image/profile-popup/setting.svg";
import TeamIcon from "@/assets/image/profile-popup/team_management.svg";
import IntegrationIcon from "@/assets/image/profile-popup/integration.svg";
// import AnalysticsIcon from "@/assets/image/profile-popup/analytics.svg";
import HelpIcon from "@/assets/image/profile-popup/help.svg";
import HotelClassSVGIcon from "@/assets/image/profile-popup/icon-hotel-class.svg";
import AccountBalanceSVGIcon from "@/assets/image/profile-popup/wallet.svg";
import GetStartedIcon from "@/assets/image/guide-popup/icon-getstarted.svg";
import SubmitIcon from "@/assets/image/guide-popup/icon-submit.svg";
import { ReactComponent as SearchIcon } from "@/assets/image/sidebar-icons/search-loop.svg";
import { ReactComponent as SearchLinkedin } from "@/assets/image/sidebar-icons/search-linkedin.svg";
import { ReactComponent as BulkEnrichment } from "@/assets/image/sidebar-icons/bulk-enrichment.svg";
import { ReactComponent as AiEngagement } from "@/assets/image/sidebar-icons/ai-engagement.svg";
import { ReactComponent as ProspectingList } from "@/assets/image/sidebar-icons/prospecting-list.svg";
import { ReactComponent as WebsiteIntent } from "@/assets/image/sidebar-icons/website-intent.svg";
import csv from "csvtojson";
export const pageContentWidth = 1680;

export const navList = [
    { label: "Pricing", icon: null, router: "/pricing" },
    { label: "Product", icon: DownSVGIcon, router: "/prospecting" },
    { label: "Resources", icon: DownSVGIcon, router: "/faq" },
];

export const mainNavList = [
    { label: "Search Kompass", icon: null, router: "/search" },
    { label: "List", icon: null, router: "/list" },
    { label: "CSV Enrich", icon: null, router: "/bulkenrich" },
    // { label: "Email Validation", icon: null, router: "/email-validation" },
];

export const MAIN_SIDEBAR_LIST = [
    {
        label: "Search Kompass",
        icon: SearchIcon,
        router: "/search",
    },
    {
        label: "Linkedin Automation",
        icon: SearchLinkedin,
        router: "#",
        isComingSoon: true,
    },
    { label: "Prospecting Lists", icon: ProspectingList, router: "/list" },
];

export const SECONDARY_SIDEBAR_LIST = [
    {
        label: "CSV Enrichment",
        icon: BulkEnrichment,
        router: "/bulkenrich",
    },
    // {
    //     label: "Email Validation",
    //     icon: EmailValidation,
    //     router: "/email-validation",
    // },
    {
        label: "AI Engagement",
        icon: AiEngagement,
        router: "/ai-engagement/inbox",
    },
];

export const TERTIARY_SIDEBAR_LIST = [
    // {
    //     label: "AI Email Warmup",
    //     icon: EmailWarmup,
    //     router: "/email-warmup",
    //     isComingSoon: false,
    // },
    {
        label: "Website Intent",
        icon: WebsiteIntent,
        router: "/websitevisitors",
        isComingSoon: false,
    },
];

export const profilePopupNavList = [
    {
        label: "Account & Settings",
        icon: SettingIcon,
        router: "/settings",
        access: true,
    },
    {
        label: "Team Management",
        icon: TeamIcon,
        router: "/team",
        access: [permissions.basicUser, permissions.teamOwner, permissions.superAdmin],
    },
    // { label: "Invite team member", icon: InviteIcon, router: "/invite", access: [permissions.basicUser, permissions.teamOwner] },
    {
        label: "Integrations",
        icon: IntegrationIcon,
        router: "/integrate",
        access: true,
    },

    // {
    //     label: "Analytics",
    //     icon: AnalysticsIcon,
    //     router: "/analytics",
    //     access: true,
    // },
    {
        label: "Upgrade Plan",
        icon: HotelClassSVGIcon,
        router: "/upgrade",
        access: [permissions.basicUser, permissions.teamOwner, permissions.superAdmin],
    },
    {
        label: "Buy Credits",
        icon: AccountBalanceSVGIcon,
        router: "/credits",
        access: [permissions.teamOwner, permissions.superAdmin],
    },
];

export const guidePopupNavList = [
    { label: "Help Center", icon: HelpIcon, router: "/" },
    { label: "Get started guide", icon: GetStartedIcon, router: "/" },
    { label: "Submit a ticket", icon: SubmitIcon, router: "/" },
];

export const LandingHeaderColor = {
    white: "#FFF",
    green: "#75EED9",
};

export const menuProductList = [
    { label: "Intent Signals", icon: null, router: "/#intent-signals" },
    { label: "Prospect", icon: null, router: "/#prospect" },
    { label: "Engage", icon: null, router: "/#engage" },
    { label: "Email Deliverability", icon: null, router: "/#email-deliverability" },
];

export const menuResourceLearnList = [
    { label: "FAQ", icon: null, router: "/faq" },
    // { label: "The Competition", icon: null, router: "/competitors" },
    // { label: "KompassAI Insights", icon: null, router: "/blog" },
];

export const menuResourcePartnerList = [
    { label: "Kompass Advocate", icon: null, router: "#" },
    { label: "Affiliate model", icon: null, router: "#" },
    { label: "Refer a friend", icon: null, router: "#" },
];

export const bulkEnrichTemplateHeader = {
    linkedin: ["LinkedinURLs"],
    search: ["Name", "Company", "Location"],
};
export const emailValidationTemplateHeader = "Email";
export function hexToRGB(hex, alpha) {
    // Remove the # symbol if it's included
    hex = hex.replace("#", "");

    // Parse the hex values to integers
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Ensure the alpha value is between 0 and 100
    alpha = Math.min(Math.max(alpha, 0), 100);

    // Create the rgba string
    return `rgb(${r} ${g} ${b} / ${alpha}%)`;
}

export function getUUID() {
    const unique_id = uuid();
    return unique_id;
}

export function getShortName(userName) {
    try {
        let name = userName;
        let array = name.split(" ");
        if (array.length === 0) return "";
        if (array.length === 1) return array[0][0].toUpperCase();
        if (array.length === 2) return (array[0][0] + (array[1][0] ?? "")).toUpperCase();
        if (array.length >= 3) return (array[0][0] + array[1][0] + array[2][0]).toUpperCase();
    } catch (error) {
        return "";
    }
}

export function indexString(string, n = 5) {
    // takes a string and encodes it as number between 0 and n
    var hash = 0;
    if (!string) return;

    for (var i = 0; i < string?.length; i++) {
        var charCode = string.charCodeAt(i);
        hash += charCode;
    }
    var index = hash % n;

    return index;
}

// Helper function to format emails
function formatEmails(emails) {
    return (emails || []).map((email) => email.email).join(",") || "";
}

// Helper function to format phones based on status
function formatPhones(phones, isHQ) {
    return (
        (phones || [])
            .filter((phone) => (isHQ ? phone?.status === "hq" : !phone?.status || phone?.status !== "hq"))
            .map((phone) => phone.phone_number || phone.phoneNumber)
            .join(",") || ""
    );
}

export function downloadProfilesCSV(fileName, content) {
    const csvContent = [];

    // Headers in the specified order
    const includedHeaders = [
        "No",
        "Linkedin Url",
        "List_Name",
        "Company_Name",
        "Full_Name",
        "First_Name",
        "Last_Name",
        "Valid_Work_Emails",
        "Accept_All_Work_Emails",
        "Valid_Personal_Emails",
        "Unknown_Emails",
        "DNC_Emails",
        "Personal_Phones",
        "HQ_Phones",
        "Title",
        "Level",
        "Department",
        "Industry",
        "Country",
        "Personal_Location",
        "Company_Domain",
    ];

    // Add headers to CSV content
    csvContent.push(includedHeaders);

    // Add rows based on the specified order of headers
    content.forEach((item, index) => {
        const record = [
            index + 1, // "No"
            item?.linkedinUrl || "", // "Linkedin Url"
            fileName, // "List_Name" (placeholder or available field)
            item?.company?.name || "", // "Company_Name"
            item?.name || "", // "Full_Name"
            item?.firstName || "", // "First_Name"
            item?.lastName || "", // "Last_Name"
            formatEmails(item?.workEmails), // "Valid_Work_Emails"
            formatEmails(item?.riskyEmails), // "Accept_All_Work_Emails"
            formatEmails(item?.personalEmails), // "Valid_Personal_Emails"
            formatEmails(item?.unknownEmails), // "Unknown_Emails"
            formatEmails(item?.doNotEmails), // "DNC_Emails"
            formatPhones(item?.validPhones, false), // "Personal_Phones"
            formatPhones(item?.validPhones, true), // "HQ_Phones"
            item?.title || "", // "Title"
            item?.level || "", // "Level"
            item?.department || "", // "Department"
            item?.company?.industry || "", // "Industry"
            getCountryFromLocation(item?.location) || "", // "Country"
            item?.location || "", // "Personal_Location"
            item?.company?.domain || "", // "Company_Domain"
        ];

        csvContent.push(record);
    });

    const csv = Papa.unparse(csvContent);

    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.csv`;
    link.click();
}

export function getCountryFromLocation(location) {
    if (!location) return null;
    const locationParts = location.split(",").map((part) => part.trim());
    return locationParts[locationParts.length - 1]; // Get the last element
}

export function mergeKompassEmails(emails) {
    if (!emails) return [];

    const emailTypes = {
        workEmails: "Valid",
        personalEmails: "Personal",
        riskyEmails: "Risky",
        unknownEmails: "Unknown",
        doNotEmails: "Do not email",
    };

    return Object.entries(emailTypes).flatMap(([key, type]) => (emails[key] ? emails[key].map((item) => ({ ...item, type })) : []));
}

export function extractLocationsFromStr(locations) {
    const countiries = [],
        states = [],
        cities = [];
    locations.forEach((location) => {
        const strs = location?.split(`, `);
        if (strs?.length === 1) {
            countiries.push(location);
        } else if (strs?.length === 2) {
            states.push(location);
        } else if (strs?.length >= 3) {
            cities.push(location);
        }
    });

    const response = { country: countiries, state: states, city: cities };
    if (!response?.country || response?.country?.length === 0) {
        delete response.country;
    }
    if (!response?.state || response?.state?.length === 0) {
        delete response.state;
    }
    if (!response?.city || response?.city?.length === 0) {
        delete response.city;
    }

    return response;
}

export function fetchWrapper({ link, method = "GET", headers = {}, overrideBase = "" }) {
    const asyncRequest = async (params = undefined) => {
        try {
            const param = {
                method,
                // headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
                headers: {
                    "Content-Type": "application/json",
                    ...headers,
                },
            };
            if (params) {
                param.body = params;
            }
            const _resData = await fetch((overrideBase || process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT) + link, param);
            if (_resData.ok) {
                const data = await _resData.json();
                return data;
            } else {
                const data = await _resData.json();
                console.log(data);
                throw data;
            }
        } catch (e) {
            console.log(e);
            const errorResponse = {
                status: false,
                message: e.message || "Something went wrong!",
            };
            throw errorResponse;
        }
    };
    return asyncRequest;
}

export function capitalizeString(value, separator = undefined) {
    // Return null if value is not a string
    if (!value || typeof value !== "string") return null;

    // Function to capitalize a single word
    const capWord = (word) => word.charAt(0).toUpperCase() + word.slice(1);

    // If a separator is provided, split by it, capitalize each word, and join back with the separator
    if (separator) {
        return value.split(separator).map(capWord).join(separator);
    }

    // Otherwise, capitalize the entire string as a single word
    return capWord(value);
}

export function parseHashDelimitedParams(link) {
    let result;
    const params = link.split("#");
    if (params.length > 1) {
        result = {};
        params[1].split("&").forEach((param) => {
            const entry = param.split("=");
            result[entry[0]] = entry[1];
        });
    }
    return result;
}

export async function parseStringCSVtoArray(file) {
    let parsedCSV = await csv().fromString(file);
    return parsedCSV;
}

export function filterNavListByPermissions({ permission, list }) {
    if (permission === null || permission === undefined) {
        return [];
    }
    return list.filter((el) => {
        if (Array.isArray(el?.access) && el.access.includes(permission)) {
            return true;
        }
        if (el?.access === true) {
            return true;
        }

        return false;
    });
}

export function checkRoutesPermissions({ permission, routes }) {
    const filteredRoutes = {};

    if (permission === null || permission === undefined) {
        return {};
    }

    for (const routeKey in routes) {
        const route = routes[routeKey];

        if (route.access === true) {
            filteredRoutes[routeKey] = route;
        }

        if (route?.access && Array.isArray(route?.access) && route.access.includes(permission)) {
            filteredRoutes[routeKey] = route;
        }
    }

    return filteredRoutes;
}

export function getAllQueryParams(queryString) {
    // Extract the query string from the current URL
    // const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);

    // Create an object to hold the key-value pairs
    const queryParams = {};

    // Iterate over all the entries in the URLSearchParams object
    for (const [key, value] of params.entries()) {
        queryParams[key] = value;
    }

    return params.entries().length === 0 ? null : queryParams;
}

export function extractDomain(url) {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.hostname;
    } catch (e) {
        console.error("Invalid URL:", e);
        return null;
    }
}

export function goToTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}

export const EMAIL_WARMUP_SEARCH_FILTERS = [
    {
        label: "All",
        id: "all",
    },
    {
        label: "Only active",
        id: "active",
    },
    {
        label: "Only paused",
        id: "paused",
    },
];

export const BASE_BUTTON_STYLES = {
    fontFamily: "Outfit",
    textTransform: "none",
    py: "12px",
    px: "16px",
    borderRadius: "8px",
    width: "140px",
    fontWeight: 400,
    fontSize: "13px",
    "@media (min-width:1536px)": {
        py: "10px",
        px: "20px",
        fontSize: "14px",
    },
    border: "1px solid #e7e7e7",
};

export const ADD_EMAIL_WARMUP_INPUTS = [
    { label: "First Name", name: "firstName", placeHolder: "John" },
    { label: "Last Name", name: "lastName", placeHolder: "Doe" },
    { label: "Email", name: "email", placeHolder: "john@doe.com" },
    { label: "App Password", name: "appPassword", placeHolder: "" },
];

export const cleanLinkedUrl = (linkedinUrl) => {
    linkedinUrl = linkedinUrl.replace("https://", "");
    linkedinUrl = linkedinUrl.replace("http://", "");
    linkedinUrl = linkedinUrl.replace("www.linkedin.com", "linkedin.com");

    if (linkedinUrl.endsWith("/")) {
        linkedinUrl = linkedinUrl.slice(0, -1);
    }

    return linkedinUrl;
};

export const SAVED_LABELS = {
    fullName: "Full Name",
    personalLocation: "Personal Location",
    currentCompanyWebsite: "Current Company Website",
    currentJobTitle: "Current Job Title",
    currentCompanyName: "Current Company Name",
    currentIndustry: "Current Industry",
    currentJobLevels: "Current Job Levels",
    currentJobDepartments: "Current Job Departments",
    currentJobSubDepartments: "Current Job Sub-Departments",
    currentNumberOfEmployees: "Current Number of Employees",
    hqContinent: "HQ Continent",
    hqCountry: "HQ Country",
    currentRevenue: "Current Revenue",
    currentCompanyLocation: "Current Company Location",
    linkedinUrl: "LinkedIn URL",
    gender: "Gender",
    languagesSpoken: "Languages Spoken",
    skills: "Skills",
    workEmail: "Work Email",
    mobilePhone: "Mobile Phone",
    continent: "Continent",
    country: "Country",
    personalEmail: "Personal Email",
    certification: "Certification",
    schoolName: "School Name",
    degree: "Degree",
    gitHubUrl: "GitHub URL",
    gitHubUsername: "GitHub Username",
    facebookUrl: "Facebook URL",
    pastCompanyWebsite: "Past Company Website",
    actualEmployeeRange: "Actual Employee Range",
    totalYearsOfExperience: "Total Years of Experience",
    fundingRaised: "Funding Raised",
    numbersOfMonthInCurrentRole: "Number of Months in Current Role",
    pastJobTitle: "Past Job Title",
    pastJobLevels: "Past Job Levels",
    pastJobDepartment: "Past Job Department",
    pastJobSubDepartment: "Past Job Sub-Department",
    pastJobCompanyContinent: "Past Job Company Continent",
    pastJobCompanyCountry: "Past Job Company Country",
    pastJobListing: "Past Job Listing",
    pastCompanyName: "Past Company Name",
    pastCompanyIndustry: "Past Company Industry",
    previousCompanyHqLocation: "Previous Company HQ Location",
    state: "State/Province",
};

// URLs starting with http:// or https:// are considered valid
export function isValidUrl(url) {
    const urlPattern = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;
    return urlPattern.test(url);
}

export function truncateText(text, limit = 90) {
    return text?.length > limit ? text?.substring(0, limit) + "..." : text;
}

export const WARMUP_SETTINGS_TITLES = ["Email Account", "Email Settings"];

// Function to format date in the format "dd MMM, hh:mm am/pm"
export const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${day} ${month}, ${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${ampm}`;
    return formattedTime;
};
export const extractDateAndTime = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return {
            date: "Invalid Date",
            time: "Invalid Time",
        };
    }

    // Get the month name and day in UTC
    const options = { month: "long", day: "numeric", timeZone: "UTC" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Get the time in UTC and convert to 12-hour format with AM/PM
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes}${period}`;

    return {
        date: formattedDate,
        time: formattedTime,
    };
};

export const trimKeys = (obj) => {
    const trimmedObj = {};
    Object.keys(obj).forEach((key) => {
        trimmedObj[key.trim()] = obj[key]; // Trim the key and assign the value
    });
    return trimmedObj;
};

export function getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

// Helper function to enforce delay
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const toStylelessDocument = (htmlString) => {
    try {
        const startIndex = htmlString.indexOf("<html");
        if (startIndex < 0) {
            const newlineText = htmlString.replace(/\n/g, "<br />");
            return newlineText;
        }

        const doc = new DOMParser().parseFromString(htmlString, "text/html");

        var treeWalker = document.createTreeWalker(
            doc,
            NodeFilter.SHOW_ELEMENT,
            {
                acceptNode: (node) => {
                    if (node.tagName === "STYLE") {
                        return NodeFilter.FILTER_ACCEPT;
                    }
                },
            },
            false
        );

        let currentNode = treeWalker.currentNode;
        const styles = [];
        while (currentNode) {
            if (currentNode.tagName === "STYLE") {
                styles.push(currentNode);
            }
            currentNode = treeWalker.nextNode();
        }
        for (let style of styles) {
            style.parentElement.removeChild(style);
        }
        return doc.body.innerHTML;
    } catch (error) {
        console.log(`Error while converting a HTML to string in "toStylelessDocument"`, error);
        return "";
    }
};

export function formatNumberWithCommas(number) {
    if (isNaN(number) || number === null || number === undefined) {
        return "0";
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatNumberWithLetters = (num) => {
    if (num >= 1000000) {
        const millionValue = (num / 1000000).toFixed(1);
        return millionValue.endsWith(".0") ? `${(num / 1000000).toFixed(0)}M` : `${millionValue}M`;
    } else if (num >= 1000) {
        const thousandValue = (num / 1000).toFixed(1);
        return thousandValue.endsWith(".0") ? `${(num / 1000).toFixed(0)}K` : `${thousandValue}K`;
    }
    return num.toString();
};

export function formatDateTime(dateStr) {
    const date = new Date(dateStr);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = (hours % 12 || 12).toString().padStart(2, "0");

    const formattedDate = `${month} ${day}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
}

export function formatHour(hour) {
    const suffix = hour >= 12 ? "PM" : "AM";
    const formattedHour = String(hour).padStart(2, "0");
    return `${formattedHour}:00 ${suffix}`;
}

export function getFormattedDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const suffix = getDaySuffix(day);

    // Function to get the day suffix (st, nd, rd, or th)
    function getDaySuffix(day) {
        if (day >= 11 && day <= 13) {
            return "th";
        }
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    const formattedDateWithSuffix = `${date.toLocaleString("en-US", {
        month: "long",
    })} ${day}${suffix}, ${date.getFullYear()}`;
    return formattedDateWithSuffix;
}

async function getCountries(continent) {
    try {
        const response = await fetch(`https://restcountries.com/v3.1/region/${continent}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch countries for ${continent}: ${response.status}`);
        }

        const data = await response.json();
        const countryNames = data.map((country) => {
            return {
                label: country.name.common,
                value: country.name.common.toLowerCase(),
                status: "include",
            };
        });

        console.log(`Countries in ${continent}:`, countryNames);
        return countryNames;
    } catch (error) {
        console.error("Error fetching countries:", error.message);
        return [];
    }
}

export async function getCountriesByContinent(continent) {
    return await getCountries(continent);
}

// Utility function to validate and adjust ranges
export const adjustRange = (range) => {
    if (!range) return null;

    const { min, max } = range;
    if (min > max) {
        return { min: max, max: min }; // Swap values if min > max
    }
    return { min, max };
};
