import useKompassRouter from "@/hooks/useKompassRouter";
import CardButton from "../button/CardButton";
import slugify from "slugify";

export default function Card({ data }) {
    const { kompassNavigate } = useKompassRouter();

    const slugifyOptions = {
        lower: true,
        strict: true,
        remove: /[:]/g,
    };

    return (
        <div
            className="flex flex-col gap-6 cursor-pointer"
            onClick={() => {
                kompassNavigate(`/blog/${data?.id}/${slugify(data?.attributes?.postTitle, slugifyOptions)}`);
            }}
        >
            <div className="w-full rounded-[16px] overflow-hidden">
                <img src={data?.attributes?.postCover.data.attributes.url} className="w-full" alt="cardImage" />
            </div>
            <div className="flex flex-col md:px-6 gap-4">
                <div>
                    <CardButton children={data?.attributes?.postTag} />
                </div>
                <p className="font-Outfit text-[28px] leading-[1.2] font-bold text-stone-950">{data?.attributes?.postTitle}</p>
                {data?.attributes?.postSubtitle && (
                    <p className="font-Outfit text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-[1.2] font-normal text-stone-950">
                        {data.attributes.postSubtitle}
                    </p>
                )}
            </div>
        </div>
    );
}
