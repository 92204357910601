import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";
import { useState } from "react";
import { ReactComponent as PlanetIcon } from "@/assets/image/icons/planet.svg";

const tableCells = {
    [WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS]: [
        { head: "Company Name", body: "companyName" },
        { head: "Location", body: "locationCountry" },
        { head: "Job Level", body: "jobLevel" },
        { head: "Job Role", body: "jobRole" },
        { head: "Industry", body: "companyIndustry" },
        { head: "Visits", body: "count" },
        { head: "Confidence Interval", body: "confidenceInterval" },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES]: [
        { head: "Industry", body: "industry" },
        { head: "Companies", body: "companies" },
        { head: "Countries", body: "countries" },
        { head: "Locations", body: "locations" },
        { head: "Visitors", body: "visitors" },
        { head: "Visits", body: "count" },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES]: [
        { head: "Employees Range", body: "employeeRange" },
        { head: "Companies", body: "companies" },
        { head: "Countries", body: "countries" },
        { head: "Visitors", body: "visitors" },
        { head: "Visits", body: "count" },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS]: [
        { head: "Employee Location", body: "employeeLocation" },
        { head: "Companies", body: "companies" },
        { head: "Industries", body: "industries" },
        { head: "Visitors", body: "visitors" },
        { head: "Visits", body: "count" },
    ],
};

export default function ResultsTable({ data, currentTab, filterProps, setFilterProps }) {
    const [imageLoaded, setImageLoaded] = useState({});
    const [checkedItems, setCheckedItems] = useState(filterProps.reduce((acc, obj) => ({ ...acc, [obj.companyName]: true }), {}));
    const [isAllChecked, setAllChecked] = useState(false);

    const handleImageLoad = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleImageError = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: false,
        }));
    };

    const checkAllRecords = (event) => {
        if (event.target.checked) {
            data.forEach((item) => {
                setProspectFilters(item);
            });
            setAllChecked(true);
        } else {
            setFilterProps([]);
            setCheckedItems([]);
            setAllChecked(false);
        }
    };

    const getConfidenceIntervalStle = (val) => {
        let commonStyle = "px-2 py-1 flex items-center justify-center rounded-[4px] capitalize whitespace-nowrap";
        let value = val;
        if (val === "high") {
            commonStyle += " bg-violet-100 text-violet-600";
            value = "very high";
        } else if (val === "moderate") {
            commonStyle += " bg-green-100 text-green-600";
            value = "high";
        } else if (val === "low") {
            commonStyle += " bg-orange-100 text-orange-600";
            value = "moderate";
        } else if (val === "very low") {
            commonStyle += " bg-red-200 text-red-600";
            value = "low";
        }
        return <p className={`${commonStyle}`}>{value}</p>;
    };

    const setProspectFilters = (item) => {
        if (!filterProps.some((obj) => obj.companyName === item.companyName)) {
            const tempFilterProps = filterProps;
            tempFilterProps.push(item);
            setFilterProps(tempFilterProps);
            setCheckedItems((prevState) => ({
                ...prevState,
                [item.companyName]: !prevState[item.companyName],
            }));
        } else {
            const tempFilterProps = filterProps.filter((obj) => obj !== item);
            setFilterProps(tempFilterProps);
            setCheckedItems((prevState) => {
                const { [item.companyName]: _, ...rest } = prevState;
                return rest;
            });
        }
    };

    return (
        <table className="w-full border-collapse">
            <thead>
                <tr className="font-Outfit text-[12px] text-stone-950 font-bold	leading-[20px] border-none">
                    {tableCells[currentTab].map((cell, index) => (
                        <th key={index} className="p-0">
                            <div
                                className={`bg-stone-100 py-2 px-4 text-left border-y border-[#E7E7E7] whitespace-nowrap ${
                                    index === 0 ? "border-l rounded-tl-[8px]" : ""
                                } ${index === tableCells[currentTab].length - 1 ? "border-r rounded-tr-[8px]" : ""}`}
                            >
                                {cell.body === "companyName" ? (
                                    <div className="flex gap-6">
                                        <input type="checkbox" checked={isAllChecked} onChange={checkAllRecords} />
                                        <p>{cell.head}</p>
                                    </div>
                                ) : (
                                    <p>{cell.head}</p>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={`font-Outfit text-[14px] text-[#494949] leading-[20px] border-x border-b border-[#E7E7E7]`}
                    >
                        {tableCells[currentTab].map((cell, cellIndex) => (
                            <td key={`${rowIndex}-${cellIndex}`}>
                                <div className={`flex items-center gap-4 px-4 py-6 justify-start`}>
                                    {cell.body === "companyName" && (
                                        <div className="flex gap-6">
                                            <input
                                                type="checkbox"
                                                checked={checkedItems[item.companyName]}
                                                onChange={() => {
                                                    setProspectFilters(item);
                                                }}
                                            />
                                            <div
                                                className={`w-6 h-6 flex items-center justify-center rounded-[4px] ${
                                                    imageLoaded[rowIndex] ? "bg-white" : "bg-accents-purple"
                                                } text-wrap`}
                                            >
                                                <img
                                                    src={`https://logo.clearbit.com/${item.companyWebsite}`}
                                                    alt="Company Logo"
                                                    onLoad={() => handleImageLoad(rowIndex)}
                                                    onError={() => handleImageError(rowIndex)}
                                                    style={{
                                                        display: imageLoaded[rowIndex] ? "block" : "none",
                                                    }}
                                                />
                                                {!imageLoaded[rowIndex] && (
                                                    <p className="uppercase text-[20px]">{item.companyName?.charAt(0)}</p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {cell.body !== "companyWebsite" && cell.body !== "confidenceInterval" && (
                                        <p
                                            className={`${currentTab !== WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES ? "capitalize" : ""} whitespace-nowrap`}
                                        >
                                            {item[cell.body]}
                                        </p>
                                    )}
                                    {cell.body === "companyWebsite" && (
                                        <a
                                            href={`http://${item.companyWebsite}`}
                                            className="w-10 h-10 rounded-[8px] bg-stone-150 flex items-center justify-center"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <PlanetIcon />
                                        </a>
                                    )}
                                    {cell.body === "confidenceInterval" && getConfidenceIntervalStle(item[cell.body])}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
