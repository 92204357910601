import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";

export default function LeftBar({ currentTab, setCurrentTab, summary }) {
    return (
        <>
            <p className="font-Outfit text-gray-950 opacity-70 text-[14px] xl:text-[16px] leading-[1.3] mb-9 lg:max-w-[220px] xl:max-w-[250px] 2xl:max-w-[290px]">
                {summary}
            </p>
            <ul className="flex flex-col gap-4 xl:gap-6 font-Outfit text-stone-950 text-[16px] xl:text-[20px] leading-[1.2]">
                {Object.values(WEBSITE_VISITORS_REPORT_ITEMS).map((item) => (
                    <li
                        key={item}
                        className={`cursor-pointer bg-white p-3 text-center rounded-[8px] ${currentTab === item ? "border bg-gray-100  border-black/[0.10] shadow-[0_6px_8px_0_rgba(0,0,0,0.05)]" : "opacity-50"} hover:opacity-100 transition-opacity`}
                        onClick={() => setCurrentTab(item)}
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </>
    );
}
