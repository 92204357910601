import React, { useState, useEffect, useContext } from "react";
import PricingComponent from "@/components/pricing/PricingComponent/PricingComponent";
import PricingTable from "@/components/pricing/PricingTable";
import { useNavigate } from "react-router-dom";
import useBillingStore from "@/store/useBillingStore";
import { AuthContext } from "@/context/AuthContext";
import { useLocation } from "react-router-dom";

const tiers = {
    free: {
        title: "Team",
        slogan: "Test drive it",
        theme: {
            border: "border-stone-250",
            main: "bg-[#E7E7E7]",
        },
    },
    professional: {
        title: "Professional",
        slogan: "Essential for finding your prospects",
        theme: {
            border: "border-[#F1A9FF]",
            main: "bg-[#F1A9FF]",
        },
    },
    premium: {
        title: "Premium",
        slogan: "Perfect for small teams with simple workflows",
        theme: {
            border: "border-accents-green",
            main: "bg-accents-green",
        },
        promo: "Most popular",
    },
    enterprise: {
        title: "Enterprise",
        slogan: "Ideal for prospecting at large scales",
        theme: {
            border: "border-[#9AB2FF]",
            main: "bg-[#9AB2FF]",
        },
    },
};

function FullPricingBlock(props) {
    const { tariffList, subscriptionInfo, annual, topPosition } = props;
    const { changePrices, setRedirect, changePlan } = useBillingStore();
    const authContext = useContext(AuthContext);
    const [signed, setSigned] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const location = useLocation();
    const hideFreePlan = location.pathname === "/upgrade" && subscriptionInfo.plan !== "Free";
    const isPricingPage = location.pathname === "/pricing";
    const navigate = useNavigate();

    //Selecting a plan by clicking a button in a table or on a Plan card
    const handleSelection = (product, price_obj) => {
        if (!signed) {
            setRedirect("/upgrade");
            navigate("/signin");
            return;
        }

        if (isPricingPage) {
            navigate("/upgrade");
        } else {
            changePrices({ main: price_obj });
            changePlan(product);

            if (subscriptionInfo.plan !== "Free") {
                navigate("/planupgrade", { state: { redirected: true } });
                return;
            } else {
                navigate("/billingpay");
            }
        }
    };

    useEffect(() => {
        (async () => {
            const signedIn = await authContext.isSignIn();
            setSigned(signedIn);
        })();
    }, []);

    return (
        <div className="mt-8 xl:pt-16 flex h-fit w-full flex-col">
            {/* <p className="mb-8 text-sm font-medium">* All features enabled until December 31, 2024 for all plans.</p> */}
            <div
                className={`w-full grid gap-x-6 xl:gap-x-10 gap-y-20 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ${hideFreePlan ? "lg:grid-cols-3" : "lg:grid-cols-4"} items-end justify-between justify-items-between xl:gap-8 self-center`}
            >
                {tariffList?.map((product) => {
                    if (hideFreePlan && product.key === "free") return <></>;
                    return (
                        <PricingComponent
                            key={product.id}
                            isCurrent={
                                subscriptionInfo?.plan?.toLowerCase() === product?.name?.toLowerCase() &&
                                subscriptionInfo?.status !== "canceled"
                            }
                            tiers={tiers}
                            handleSelection={handleSelection}
                            product={product}
                            annual={annual}
                            expandState={[expanded, setExpanded]}
                            isPricingPage={isPricingPage}
                        />
                    );
                })}
            </div>
            {tariffList && tariffList.length > 0 && (
                <PricingTable tiers={tiers} annual={annual} products={tariffList} topPosition={topPosition} />
            )}
        </div>
    );
}

export default FullPricingBlock;
