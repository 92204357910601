import { useState } from "react";
import MainLayout from "@/components/MainLayout";
import MainList from "@/components/lists/MainList";
import SubProfileList from "@/components/lists/SubProfileList";
import BaseContainer from "@/components/BaseContainer";
import MainTitleBar from "@/components/MainTitleBar";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import { motion, AnimatePresence } from "framer-motion";
import { Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ListsScene() {
    const [currentContact, setCurrentContact] = useState(null);
    const { show, loading: crmLoading } = useCRMPushResultStore();
    const navigate = useNavigate();

    const onContactSelected = (contact) => {
        navigate(`/list/${contact._id}`);
    };

    return (
        <MainLayout loading={crmLoading}>
            <>
                <AnimatePresence>
                    {show && (
                        <motion.div className="w-full" initial={{ opacity: 0, zIndex: 11 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <Backdrop
                                open
                                className="!absolute"
                                sx={{
                                    backgroundColor: "rgba(0,0,0,0.18)",
                                    backdropFilter: "blur(2px)",
                                    zIndex: 11,
                                }}
                            >
                                <CRMUploadResultsTable />
                            </Backdrop>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
            {currentContact === null && (
                <>
                    <MainTitleBar>
                        <p>Lists</p>
                    </MainTitleBar>
                    <BaseContainer>
                        <MainList onContactSelected={onContactSelected} />
                    </BaseContainer>
                </>
            )}
            {currentContact !== null && <SubProfileList contact={currentContact} goBack={() => setCurrentContact(null)} />}
        </MainLayout>
    );
}
