import React, { useState } from "react";
import Tab from "./Tab";

function SegmentedControls({ initialTabData, onTabChange }) {
    const [tabData, setTabData] = useState(initialTabData);

    const tabClicked = (label) => {
        const updatedTabs = tabData.map((tab) => ({
            ...tab,
            isActive: tab.label === label,
        }));
        setTabData(updatedTabs);
        onTabChange(label); // Inform parent about the tab change
    };

    return (
        <nav className="flex items-center text-base font-medium leading-none text-center text-zinc-950">
            <div className="flex flex-1 shrink justify-center self-stretch p-0.5 my-auto rounded-lg basis-0 bg-neutral-100 min-h-[32px] max-w-[360px]">
                {tabData.map((tab, index) => (
                    <Tab key={index} label={tab.label} isActive={tab.isActive} tabClicked={tabClicked} />
                ))}
            </div>
        </nav>
    );
}

export default SegmentedControls;
