import { NoInfoAvailableVal } from "@/utils/constants";
import { countryList, departmentList, industryList, levelList, numberOfEmployeesList } from "@/utils/filter";
import React from "react";
import { useNavigate } from "react-router-dom";

function SearchProspectButton({ filterProps }) {
    const navigate = useNavigate();

    const goToKompassSearch = () => {
        let companyFilter = [];
        let locationFilter = [];
        let industryFilter = [];
        let sizeFilter = [];
        let level = [];
        let department = [];

        filterProps.forEach((obj) => {
            if (obj.companyName && obj.companyName !== NoInfoAvailableVal) {
                companyFilter.push(obj.companyName);
            }
            if (obj.locationCountry && obj.locationCountry !== NoInfoAvailableVal) {
                const country = countryList.find((country) => country.value === obj.locationCountry);
                if (country) locationFilter.push({ ...country, status: "include" });
            }
            if (obj.companyIndustry && obj.companyIndustry !== NoInfoAvailableVal) {
                const industry = industryList.find((industry) => industry.value === obj.companyIndustry);
                if (industry) industryFilter.push({ ...industry, status: "include" });
            }
            if (obj.employees && obj.employees !== NoInfoAvailableVal) {
                let size = obj.employees.replace(" to ", "-");
                const sizeObj = numberOfEmployeesList.find((number) => number.value === size);
                if (sizeObj) sizeFilter.push({ ...sizeObj, status: "include" });
            }
            if (obj.jobLevel && obj.jobLevel !== NoInfoAvailableVal) {
                const levelObj = levelList.find((level) => level.value === obj.jobLevel);
                if (levelObj) level.push({ ...levelObj, status: "include" });
            }
            if (obj.jobRole && obj.jobRole !== NoInfoAvailableVal) {
                const departmentObj = departmentList.find((dep) => dep.value === obj.jobRole);
                if (departmentObj) department.push({ ...departmentObj, status: "include" });
            }
        });

        // Remove duplicates from each array
        companyFilter = [...new Map(companyFilter.map((item) => [item, item])).values()];
        locationFilter = [...new Map(locationFilter.map((item) => [item.value, item])).values()];
        industryFilter = [...new Map(industryFilter.map((item) => [item.value, item])).values()];
        sizeFilter = [...new Map(sizeFilter.map((item) => [item.value, item])).values()];
        level = [...new Map(level.map((item) => [item.value, item])).values()];
        department = [...new Map(department.map((item) => [item.value, item])).values()];

        navigate("/search", {
            state: { level, department, companyFilter, locationFilter, industryFilter, sizeFilter, isRedirectedFromWeb: true },
        });
    };
    return (
        <button
            className="font-Outfit whitespace-nowrap h-[40px] flex justify-center items-center px-6 text-[14px] xl:text-[16px] text-white capitalize rounded-[8px] bg-stone-950 max-md:px-5"
            onClick={goToKompassSearch}
        >
            Search Prospects
        </button>
    );
}

export default SearchProspectButton;
