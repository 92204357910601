import DataAnalystImg1 from "../../assets/image/advanced_search/contact-search.png";
import DataAnalystImg2 from "../../assets/image/advanced_search/company-search.png";
import DataAnalystImg3 from "@/assets/image/advanced_search/prospect-lists.png";
import { ReactComponent as Checkbox } from "@/assets/image/icons/advanced_check.svg";

const content = [
    {
        image: DataAnalystImg1,
        title: "Contact Search",
        alt: "Contact Search Image",
        points: [
            "Unlock enriched email and phone data with the click of a button",
            "Real-time verified email and phone numbers to prospect with confidence",
            "Identify key decision-makers of prospect globally",
        ],
    },
    {
        image: DataAnalystImg2,
        title: "Company Search",
        alt: "Company Search Image",
        points: [
            "Find companies that match your ideal customer profile",
            "Find comprehensive company data to enhance your CRM ",
            "Target high priority accounts and succeed your goals",
        ],
    },
    {
        image: DataAnalystImg3,
        title: "Customized Prospect Lists",
        alt: "Customized Prospect Lists  Image",
        points: [
            "Organize contacts systematically through creating lists",
            "Leverage lists to personalize outreach and outbound more effectively",
            "Save searches in lists to manage top prospects to multi-thread",
        ],
    },
];

export default function DeliverPanel(props) {
    return (
        <div className="mb-12 w-full space-y-12 bg-stone-150 px-12 py-24">
            {content?.map((c) => {
                return (
                    <div className="flex w-full flex-col-reverse items-center justify-center md:flex-row md:gap-8 lg:gap-16 xl:gap-24">
                        <div className="w-full md:w-1/2">
                            <img className="w-full" src={c.image} alt={c.alt} width={1200} height={800} />
                        </div>
                        <div className="w-full md:w-1/2 shrink-0 space-y-6">
                            <div className="font-Outfit text-3xl font-[600] text-stone-950 md:text-4xl">{c.title}</div>
                            <div className="font-Outfit text-md space-y-6 font-[400] text-stone-950 md:text-lg">
                                {c.points.map((point) => {
                                    return (
                                        <div className="flex gap-2">
                                            <Checkbox className="h-6" />
                                            <div>{point}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
