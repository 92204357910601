import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function Overview({ payload }) {
    const {
        totalLeadsReached,
        totalMailSent,
        uniqueOpened,
        totalOpened,
        uniqueClicked,
        uniqueReplied,
        uniquePositiveReplied,
        uniqueBounced,
        uniqueOpenedRate,
        uniqueClickedRate,
        uniqueRepliedRate,
        uniqueBouncedRate,
    } = payload;

    const data = [
        { label: "Total Sent", value: totalMailSent || 0 },
        { label: "Unique Opened", value: uniqueOpened, subscript: uniqueOpenedRate },
        { label: "Total Opened", value: totalOpened || 0 },
        { label: "Unique Clicked", value: uniqueClicked || 0, subscript: uniqueClickedRate },
        { label: "Unique Replied", value: uniqueReplied || 0, subscript: uniqueRepliedRate },
        { label: "Unique Positive Replied", value: uniquePositiveReplied || 0 },
        { label: "Unique Bounced", value: uniqueBounced || 0, subscript: uniqueBouncedRate },
    ];
    return (
        <>
            <div className="mt-5 rounded-xl bg-[#a3b9fc] p-3 text-center w-full">
                <h1 className="text-2xl font-bold">{totalLeadsReached}</h1>
                <p className="mt-1">Total Leads Reached</p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-10 xl:gap-x-16 gap-y-5 mt-5">
                {data.map((item, index) => (
                    <div key={index} className="campaign-card">
                        <div className="flex items-center text-dgray-500 text gap-x-2">
                            <p>{item.label}</p>
                            <InfoOutlinedIcon className="!h-4 !w-4" />
                        </div>
                        <div>
                            <h1 className="text-xl font-medium mt-2">
                                {item.value} {item.subscript && <sub className="text-blue-500">({item.subscript})</sub>}
                            </h1>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
