import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function HealthScore({ loading, emailHealthData, emailAccount }) {
    if (loading) {
        return (
            <div className="flex justify-center items-center h-[200px]">
                <CircularProgress />
            </div>
        );
    } else if (!emailHealthData) {
        return null;
    }

    return (
        <div className="my-5 p-[20px] 2xl:p-[25px]">
            <div className="flex items-center">
                <h1 className="text-2xl">Health Score</h1>
                <Link
                    to={`/email-health/${emailHealthData.email}`}
                    className="text-blue-500 font-semibold ml-auto hover:underline hover:text-blue-600"
                >
                    View Details
                </Link>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mt-2">
                <div className="border border-stone-400 rounded-lg p-5 flex gap-7">
                    <div>
                        <h1 className="text-md font-medium whitespace-nowrap">DNS Grade</h1>
                        {emailHealthData.hasDMARC && emailHealthData.hasSPF ? (
                            <CheckIcon className="text-green-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                        ) : (
                            <CloseIcon className="text-red-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                        )}
                    </div>
                    <div className="w-full">
                        <div className="flex gap-1 items-center ">
                            <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                            <h2>SPF</h2>
                            <div className="ml-auto flex items-center gap-2">
                                <p className="mb-[3px]">: </p>
                                {emailHealthData.hasSPF ? (
                                    <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                ) : (
                                    <span className="h-[8px] w-[8px] inline-block bg-red-500 rounded-full"></span>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-1 items-center mt-1">
                            <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                            <h2>DMARK</h2>
                            <div className="ml-auto flex items-center gap-2">
                                <p className="mb-[3px]">: </p>
                                {emailHealthData.hasDMARC ? (
                                    <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                ) : (
                                    <span className="h-[8px] w-[8px] inline-block bg-red-500 rounded-full"></span>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-1 items-center mt-1">
                            <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                            <h2>MX</h2>
                            <div className="ml-auto flex items-center gap-2">
                                <p className="mb-[3px]">: </p>
                                <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border border-stone-400 rounded-lg p-5 flex">
                    <div>
                        <div className="flex items-center gap-1">
                            <h1 className="text-md font-medium whitespace-nowrap">Domain Age</h1>
                            <InfoOutlinedIcon className="!h-5 !w-5 mt-1" />
                        </div>
                        {emailHealthData.ageGrade === "A" ? (
                            <h1 className="text-green-500 text-5xl">A</h1>
                        ) : (
                            <h1 className="text-yellow-600 text-5xl">{emailHealthData.ageGrade}</h1>
                        )}
                    </div>
                    <div className="flex items-center justify-center flex-col w-full">
                        <h1 className="text-3xl font-semibold" style={{ lineHeight: "25px" }}>
                            {emailHealthData.domainAgeInDays}
                        </h1>
                        <p className="text-md">Days</p>
                    </div>
                </div>

                <div className="border border-stone-400 rounded-lg p-5 flex">
                    <div>
                        <div className="flex items-center gap-1">
                            <h1 className="text-md font-medium whitespace-nowrap">Spam Rate</h1>
                            <InfoOutlinedIcon className="!h-5 !w-5 mt-1" />
                        </div>
                        <CheckIcon className="text-green-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                    </div>
                    <div className="flex items-center justify-center flex-col w-full">
                        <h1 className="text-5xl font-semibold" style={{ lineHeight: "25px" }}>
                            {emailAccount.spam_rate_in_last_14_days} %
                        </h1>
                        <p className="text-md whitespace-nowrap mt-1">Last 14 Days</p>
                    </div>
                </div>

                <div className="border border-stone-400 rounded-lg p-5 flex gap-7">
                    <div>
                        <div className="flex items-center gap-1">
                            <h1 className="text-md font-medium whitespace-nowrap">Warmup Duration</h1>
                            <InfoOutlinedIcon className="!h-5 !w-5 mt-1" />
                        </div>
                        <h1 className="text-[#D2E34B] text-5xl mt-1">C</h1>
                    </div>
                    <div className="flex items-center justify-center flex-col w-full">
                        <h1 className="text-5xl font-semibold" style={{ lineHeight: "25px" }}>
                            0
                        </h1>
                        <p className="text-md">Days</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
