import { useEffect, useRef, useState, useContext } from "react";
import { Button } from "@material-tailwind/react";
import { AuthContext } from "@/context/AuthContext";
import { useNavigate } from "react-router-dom";
import MainLayout from "@/components/MainLayout";
import useStripe from "@/hooks/useStripe";
import CreditSlider from "@/components/credits/CreditSlider";
import BaseContainer from "@/components/BaseContainer";
import { pageContentWidth } from "@/utils/common";
import { AnimatePresence, motion } from "framer-motion";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import MainLoader from "@/components/MainLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const options = [
    0, 50000, 100000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000, 550000, 600000, 650000, 700000, 750000, 800000,
    850000, 900000, 950000, 1000000,
];

export default function CreditsScene() {
    const navigate = useNavigate();
    const { isTeamOwner } = useContext(AuthContext);
    const [clientSecret, setClientSecret] = useState("");
    const [stripeError, setStripeError] = useState("");
    const [priceIndex, setPriceIndex] = useState(0);

    const canBuyCredits = isTeamOwner;

    useEffect(() => {
        if (!canBuyCredits) {
            navigate("/home");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canBuyCredits]);

    const { getCreditPaymentLink } = useStripe();
    const [loading, setLoading] = useState(false);
    const loadStripe = async (data) => {
        setLoading(true);
        const res = await getCreditPaymentLink(data);
        if (res.status) {
            setClientSecret(res.data.client_secret);
        } else {
            setStripeError(res.message);
        }
        setLoading(false);
    };

    const redirectToSuccessPage = () => {
        navigate("/home");
    };

    const handleCustomSelection = () => {
        const constructedPrice = [
            {
                lookup_key: "additional_credits_key",
                quantity: options[priceIndex] / 1000,
            },
        ];
        loadStripe(constructedPrice);
    };

    const fieldRef = useRef(null);

    const params = new URLSearchParams();
    params.set("mode", "payment");

    const clearStripe = () => {
        setClientSecret("");
        setStripeError("");
    };

    if (!canBuyCredits) {
        return null;
    }

    return (
        <MainLayout>
            {loading && <MainLoader />}
            {!loading && (
                <BaseContainer>
                    <div className="mt-[18px] flex w-full flex-col items-center md:mt-[64px] gap-10">
                        <h3 className="font-Outfit font-semibold relative px-4 text-center text-[3.2rem] text-stone-950 capitalize">
                            Buy extra credits
                        </h3>
                        <p className="text-lg text-[#6D6D6D]">Select how many credits you would like to buy for your team below.</p>
                    </div>
                    {!clientSecret && (
                        <div className="mb-6 flex flex-col items-center">
                            <AnimatePresence>
                                <motion.div
                                    ref={fieldRef}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="mb-[30px] mt-[72px] flex w-full flex-col"
                                >
                                    <div className="flex flex-col gap-12">
                                        <CreditSlider state={[priceIndex, setPriceIndex]} options={options}></CreditSlider>
                                    </div>
                                </motion.div>

                                {priceIndex > 0 && (
                                    <Button
                                        onClick={handleCustomSelection}
                                        className={
                                            "text-md font-Outfit mt-6 flex w-[280px] items-center justify-center rounded-lg bg-black p-[16px_24px] font-[700] tracking-wide text-white transition-all"
                                        }
                                    >
                                        Buy Credits
                                    </Button>
                                )}
                            </AnimatePresence>
                        </div>
                    )}

                    {clientSecret && (
                        <BaseContainer width={pageContentWidth}>
                            <Button
                                onClick={() => clearStripe()}
                                className="font-Outfit my-10 ml-auto mr-auto h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-lg font-medium text-white"
                            >
                                Go back
                            </Button>
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={{
                                    clientSecret,
                                }}
                            >
                                <EmbeddedCheckout onSuccess={() => redirectToSuccessPage()} successUrl={`${window.location.origin}/home`} />
                            </EmbeddedCheckoutProvider>
                        </BaseContainer>
                    )}

                    {stripeError && (
                        <div className="flex w-full flex-col items-center justify-center">
                            <div className="my-24 flex w-5/12 flex-col gap-3 rounded-md border-[1px] border-[#E6E6E6] p-10">
                                <div className="font-Outfit flex flex-col self-stretch text-center text-3xl font-extrabold">
                                    Sorry, we got problems
                                </div>
                                <div className="font-Outfit flex-wrap text-center text-lg">{stripeError}</div>
                                <div className="font-Outfit my-10 flex-wrap text-center text-lg">
                                    <Button
                                        onClick={() => navigate(-1)}
                                        className="font-Outfit h-11 w-56 rounded-md bg-blue-500 px-3 py-1 text-lg font-medium uppercase text-white"
                                    >
                                        Go back
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </BaseContainer>
            )}
        </MainLayout>
    );
}
