import { useState, useEffect, useMemo } from "react";
import { useRef } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { Button } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { toast } from "react-toastify";
import { PaginationItem } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Pagination from "@mui/material/Pagination";
import { kompassColors } from "@/theme/palette";
import BusinessIcon from "@mui/icons-material/Business";
import RiskIcon from "@mui/icons-material/AssistantPhotoOutlined";
import WarningOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import useKompassSearch from "@/hooks/useKompassSearch";
import useEnrichment from "@/hooks/useEnrichment";
import useList from "@/hooks/useList";
import {
    getShortName,
    indexString,
    downloadProfilesCSV,
    extractLocationsFromStr,
    mergeKompassEmails,
    cleanLinkedUrl,
    delay,
} from "@/utils/common";
import MoveGroupIcon from "@/assets/image/team/move-group.svg";
import RemoveTeamIcon from "@/assets/image/team/remove-team.svg";
import { ReactComponent as DownIcon } from "@/assets/image/search/down.svg";
import { ReactComponent as AddListIcon } from "@/assets/image/search/icon-addlist.svg";
import { ReactComponent as LibraryAddIcon } from "@/assets/image/search/icon-library-add.svg";
import { ReactComponent as BulkEnrichIcon } from "@/assets/image/search/bulk_enrich.svg";
import { ReactComponent as MailIcon } from "@/assets/image/search/mail.svg";
import { ReactComponent as PhoneIcon } from "@/assets/image/search/call.svg";
import { ReactComponent as BulbIcon } from "@/assets/image/search/lightbulb.svg";
import { ReactComponent as MailGrayIcon } from "@/assets/image/search/mail2.svg";
import { ReactComponent as PhoneGrayIcon } from "@/assets/image/search/call2.svg";
import { ReactComponent as CopyGrayIcon } from "@/assets/image/search/copy2.svg";
import { ReactComponent as NoResultsFound } from "@/assets/image/search/no-results.svg";
import { ReactComponent as EmptyState } from "@/assets/image/search/empty-state.svg";
import { ReactComponent as LockIcon } from "@/assets/image/search/lock.svg";
import { ReactComponent as PersonCheckIcon } from "@/assets/image/search/person_check.svg";
import { ReactComponent as CheckCircleIcon } from "@/assets/image/search/check_circle.svg";
import SaveToListDialog from "@/components/search/SaveToListDialog";
import CRMPushMenu from "../common/CRMElements/CRMPushMenu";
import { CONTACT_LIST_TYPE, ENRICHMENT_TYPE } from "@/utils/constants";
import { EnhancedTableHead } from "./EnhancedTableHead";
import MainLoader from "../MainLoader";
import CreateNewListDialog from "../lists/CreateNewListDialog";
import { BulkPopup } from "./BulkPopup";
import { TextField } from "@mui/material";
import useBulk from "@/hooks/useBulk";
import FilterDropdown from "@/components/common/FilterDropdown";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import ContactDetailsModal from "./ContactDetailsModal";
import FlexSearch from "./FlexSearch";
import { ScrollArea } from "../common/ScrollArea";
import LinkedinIcon from "@/assets/image/search/linkedin.svg";
import { Link } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
    if (orderBy === "level") {
        if (b.job_title_levels?.[0]?.toUpperCase() < a.job_title_levels?.[0]?.toUpperCase()) {
            return -1;
        }
        if (b.job_title_levels?.[0]?.toUpperCase() > a.job_title_levels?.[0]?.toUpperCase()) {
            return 1;
        }
    } else {
        if (b[orderBy]?.toUpperCase() < a[orderBy]?.toUpperCase()) {
            return -1;
        }
        if (b[orderBy]?.toUpperCase() > a[orderBy]?.toUpperCase()) {
            return 1;
        }
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "companyName",
        numeric: false,
        disablePadding: true,
        label: "Company name",
    },

    {
        id: "level",
        numeric: false,
        disablePadding: true,
        label: "Level",
    },
    {
        id: "country",
        numeric: false,
        disablePadding: true,
        label: "Country",
    },
    {
        id: "action",
        numeric: false,
        disablePadding: true,
        label: "Actions",
    },
];

function EnrichPopup({ anchorEl, onClose, onPhoneEnrich, onEmailEnrich, onEmailAndPhoneEnrich }) {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    "& .MuiList-root": {
                        ml: 1,
                        mr: 1,
                    },
                },
            }}
        >
            <div className="cursor-pointer font-Outfit" onClick={onEmailEnrich}>
                Email
            </div>
            <div className="cursor-pointer font-Outfit" onClick={onPhoneEnrich}>
                Phone
            </div>
            <div className="cursor-pointer font-Outfit" onClick={onEmailAndPhoneEnrich}>
                Email & Phone
            </div>
        </Menu>
    );
}

function EnhancedTableToolbar(props) {
    const {
        selected,
        setSelected,
        tableData,
        numSelected,
        totalCount,
        onSelectionPhoneEnrich,
        onSelectionEmailEnrich,
        onSelectionEmailAndPhoneEnrich,
        onSaveSelected,
        onExportSelected,
        handleBulkEnrich,
    } = props;
    const [enrichPopupAnchor, setEnrichPopupAnchor] = useState(null);
    const [bulkPopupOpen, setBulkPopupOpen] = useState(null);
    const [isEmailIncluded, setEmailIncluded] = useState(true);
    const [isPhoneIncluded, setPhoneIncluded] = useState(true);
    const [contactsNumber, setContactsNumber] = useState(1);
    const [existingBulkListTitle, setExistingBulkListTitle] = useState(null);
    const [newBulkListTitle, setNewBulkListTitle] = useState(null);
    const [contactList, setContactList] = useState([]);
    const selectedData = selected.map((_id) => tableData.find((item) => item.pdlId === _id));
    const { getContactList } = useList();
    const getContactListRef = useRef();
    getContactListRef.current = getContactList;

    useEffect(() => {
        const fetchContactList = async () => {
            try {
                const response = await getContactListRef.current({
                    listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
                });

                if (response?.data?.length > 0) {
                    setContactList(response.data);
                }
            } catch (error) {
                console.error("Failed to fetch contact list:", error);
            }
        };

        fetchContactList();
    }, []);

    return (
        <>
            <div className="w-full">
                <Toolbar
                    sx={{
                        padding: "0px !important",
                        "& .MuiToolbar-root": {
                            padding: "0px !important",
                        },
                    }}
                >
                    <div className="flex flex-row items-center justify-between w-full gap-2 overflow-scroll">
                        <div className="text-sm select-none font-OutfitMedium ">
                            {numSelected > 0
                                ? `${numSelected.toLocaleString("en-US")} Selected / ${totalCount?.toLocaleString("en-US")} Records Found`
                                : totalCount > 0
                                  ? `${totalCount?.toLocaleString("en-US")} Records Found`
                                  : null}
                            {numSelected > 0 && (
                                <span
                                    className="text-stone-350 ml-2 hover:underline cursor-pointer whitespace-nowrap"
                                    onClick={() => setSelected([])}
                                >
                                    Clear All
                                </span>
                            )}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="flex flex-row gap-2 text-sm font-Outfit justify-items-center whitespace-nowrap">
                                <div
                                    className={
                                        "flex cursor-pointer flex-row gap-1 rounded-md border px-2 py-1 bg-white" +
                                        (numSelected > 0
                                            ? " border-stone-500 text-stone-950"
                                            : " border-stone-500 text-stone-350 cursor-not-allowed pointer-events-none")
                                    }
                                    onClick={() => {
                                        if (numSelected > 0) onExportSelected();
                                    }}
                                >
                                    <div>Export</div>
                                </div>
                                <CRMPushMenu selected={selectedData} profileType={"profile_search"}></CRMPushMenu>
                                <div
                                    className={
                                        "flex cursor-pointer flex-row gap-1 rounded-md border bg-white px-2 py-1" +
                                        (numSelected > 0
                                            ? " border-stone-500 text-stone-950"
                                            : " border-stone-500 text-stone-350 cursor-not-allowed pointer-events-none")
                                    }
                                    onClick={() => {
                                        onSaveSelected();
                                    }}
                                >
                                    <AddListIcon
                                        className={
                                            "h-5 w-5" +
                                            (numSelected > 0
                                                ? " fill-current text-stone-950"
                                                : " fill-current text-stone-350 disabled:cursor-not-allowed")
                                        }
                                    />
                                    <div>Save to list</div>
                                </div>
                                <div
                                    className={
                                        "flex cursor-pointer flex-row gap-2 rounded-md border bg-white px-2 py-1" +
                                        (numSelected > 0 ? " border-stone-500 text-stone-950" : " border-stone-500 text-stone-350")
                                    }
                                    onClick={(event) => {
                                        if (numSelected > 0) setEnrichPopupAnchor(event.currentTarget);
                                    }}
                                >
                                    <LibraryAddIcon
                                        className={
                                            "mt-0.5 h-4 w-4" +
                                            (numSelected > 0 ? " fill-current text-stone-950" : " fill-current text-stone-350")
                                        }
                                    />
                                    <div>Enrich</div>
                                    <DownIcon
                                        className={
                                            "mt-1 h-3 w-3" +
                                            (numSelected > 0 ? " stroke-current text-stone-950" : " stroke-current text-stone-350")
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        "flex cursor-pointer flex-row gap-2 rounded-md border bg-white px-2 py-1" +
                                        (tableData?.length && !numSelected > 0
                                            ? " border-stone-500 text-stone-950"
                                            : " border-stone-500 text-stone-350 pointer-events-none")
                                    }
                                    onClick={() => {
                                        if (tableData?.length > 0) {
                                            if (tableData?.length && !numSelected > 0) {
                                                setBulkPopupOpen(true);
                                            }
                                        }
                                    }}
                                >
                                    <BulkEnrichIcon
                                        className={
                                            "mt-0.5 h-4 w-4" +
                                            (tableData?.length && !numSelected > 0
                                                ? " fill-current text-stone-950"
                                                : " fill-current text-stone-350")
                                        }
                                    />
                                    Bulk Enrich
                                    <DownIcon
                                        className={
                                            "mt-1 h-3 w-3" +
                                            (tableData?.length && !numSelected > 0
                                                ? " stroke-current text-stone-950"
                                                : " stroke-current text-stone-350")
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </div>
            <EnrichPopup
                anchorEl={enrichPopupAnchor}
                onClose={() => {
                    setEnrichPopupAnchor(null);
                }}
                onPhoneEnrich={() => {
                    setEnrichPopupAnchor(null);
                    onSelectionPhoneEnrich();
                }}
                onEmailEnrich={() => {
                    setEnrichPopupAnchor(null);
                    onSelectionEmailEnrich();
                }}
                onEmailAndPhoneEnrich={() => {
                    setEnrichPopupAnchor(null);
                    onSelectionEmailAndPhoneEnrich();
                }}
            />
            <BulkPopup
                open={bulkPopupOpen}
                onClose={() => {
                    setBulkPopupOpen(false);
                }}
            >
                <div className="flex flex-col">
                    <div className="flex flex-col px-6 py-6 gap-10">
                        <div className="flex flex-col gap-1">
                            <p className="text-[#050505] text-base font-normal font-['Outfit'] capitalize">Total Number Of Contacts</p>
                            <TextField
                                type="number"
                                value={contactsNumber}
                                onChange={(e) => {
                                    setContactsNumber(e.target.value);
                                }}
                                inputProps={{
                                    min: 1,
                                    max: 500,
                                    step: 1,
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        fontSize: "16px",
                                        fontFamily: "Outfit",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderWidth: "1px",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "16px",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                        fontSize: "16px",
                                    },
                                }}
                            />
                            <div className="text-[#888888] text-xs font-normal font-['Outfit']">Enter a number between 1 and 500</div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-[#050505] text-base font-normal font-['Outfit'] capitalize">
                                Save to Existing Contacts Lists
                            </p>
                            <FilterDropdown
                                options={
                                    contactList
                                        ? contactList.map((contact) => {
                                              return {
                                                  label: contact.listTitle,
                                                  value: contact.listTitle,
                                              };
                                          })
                                        : []
                                }
                                onChange={(value) => {
                                    setExistingBulkListTitle(value);
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-[#050505] text-base font-normal font-['Outfit'] capitalize">Create New Contacts List</p>
                            <TextField
                                type="text"
                                value={newBulkListTitle}
                                onChange={(e) => {
                                    setNewBulkListTitle(e.target.value);
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        fontSize: "16px",
                                        fontFamily: "Outfit",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderWidth: "1px",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: "16px",
                                        padding: "16px",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                    },
                                }}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-[#050505] text-base font-normal font-['Outfit'] capitalize">Select Fields to Enrich</p>
                            <div className="flex flex-row gap-10">
                                <div className="flex flex-row items-center gap-2 font-Outfit text-md">
                                    <Checkbox
                                        checked={isEmailIncluded}
                                        onChange={(event) => {
                                            setEmailIncluded(event.currentTarget.checked);
                                        }}
                                        className="w-6 h-6"
                                        size="small"
                                    />
                                    <div className="text-[#050505] text-base font-normal font-['Outfit'] capitalize">Email</div>
                                </div>
                                <div className="flex flex-row items-center gap-2 font-Outfit text-md">
                                    <Checkbox
                                        checked={isPhoneIncluded}
                                        onChange={(event) => {
                                            setPhoneIncluded(event.currentTarget.checked);
                                        }}
                                        className="w-6 h-6"
                                        size="small"
                                    />
                                    <div className="text-[#050505] text-base font-normal font-['Outfit'] capitalize">Phone</div>
                                </div>
                            </div>
                        </div>
                        <div className="text-[#888888] text-xs font-normal font-['Outfit']">
                            You will not be charged for the contacts that you have already enriched.
                        </div>
                    </div>
                    <div className="flex flex-row px-6 py-4 gap-4 border-t border-[#e7e7e7] justify-end">
                        <Button
                            className="w-[80px] px-3 py-1.5 border border-[#e7e7e7] text-sm text-center font-normal text-stone-950 capitalize rounded-[8px] font-['Outfit'] bg-white 2xl:px-2"
                            onClick={() => {
                                setBulkPopupOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="w-[80px] px-3 py-1.5 text-sm text-center font-normal text-white capitalize rounded-[8px] font-['Outfit'] bg-stone-950 2xl:px-2"
                            onClick={() => {
                                if (contactsNumber <= 0 || contactsNumber > 500) {
                                    toast.error("Number of contacts you want to enrich should be betweeen 1 and 500");
                                    return;
                                }
                                if (!existingBulkListTitle && !newBulkListTitle) {
                                    toast.error("Please indicate name of the list you want to save data to");
                                    return;
                                }
                                const isExistingListFound = contactList.some((list) => list.listTitle === newBulkListTitle);
                                if (isExistingListFound) {
                                    toast.error("You already have an existing list with the same name");
                                    return;
                                }

                                setBulkPopupOpen(false);
                                handleBulkEnrich(
                                    contactsNumber,
                                    { existingBulkListTitle, newBulkListTitle },
                                    { isEmailIncluded, isPhoneIncluded }
                                );
                            }}
                        >
                            Enrich
                        </Button>
                    </div>
                </div>
            </BulkPopup>
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const ContactTableRow = (props) => {
    const { postEnrichContact } = useEnrichment();

    const {
        row,
        isItemSelected,
        labelId,
        handleClick,
        handleSaveToList,
        isVisibleNewList,
        setVisibleNewList,
        updateTableDataAfterEnrich,
        handleToggleDetails,
        openRowIndex,
        filtersOpen,
    } = props;
    const [rowData, setRowdata] = useState(row);
    const [emailEnriching, setEmailEnriching] = useState(false);
    const [phoneEnriching, setPhoneEnriching] = useState(false);
    const [bothEnriching, setBothEnriching] = useState(false);
    const [deepEnriching, setDeepEnriching] = useState(false);
    const [emailEnriched, setEmailEnriched] = useState(false);
    const [phoneEnriched, setPhoneEnriched] = useState(false);
    const [deepEnriched, setDeepEnriched] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(undefined);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    const enrichContact = async (enrichType) => {
        try {
            switch (enrichType) {
                case ENRICHMENT_TYPE.EMAIL_ONLY:
                    setEmailEnriching(true);
                    break;
                case ENRICHMENT_TYPE.PHONE_ONLY:
                    setPhoneEnriching(true);
                    break;
                case ENRICHMENT_TYPE.ALL:
                    setBothEnriching(true);
                    break;
                case ENRICHMENT_TYPE.DEEP_SEARCH:
                    setDeepEnriching(true);
                    break;
                default:
                    break;
            }

            const enrichRequestParams = {
                inputs: {
                    url: rowData?.linkedin_url || "",
                    company_website: rowData?.job_company_website || "",
                    company_name: rowData?.job_company_name || "",
                    full_name: rowData.full_name || "",
                    from: "kompass_search",
                },
            };

            const response = await postEnrichContact(enrichRequestParams, enrichType);

            if (response.status && response.data) {
                let newEmails = [];
                let newPhones = [];
                let isEmailFound;
                let isPhoneFound;
                let emailEnriched;
                let phoneEnriched;

                if (enrichType !== ENRICHMENT_TYPE.PHONE_ONLY && response?.data?.emails) {
                    if (Object.values(response.data.emails).some((array) => array.length > 0)) {
                        newEmails = response.data.emails;
                        isEmailFound = true;
                    } else {
                        isEmailFound = false;
                    }
                    emailEnriched = true;
                }

                if (enrichType !== ENRICHMENT_TYPE.EMAIL_ONLY && response?.data?.phones) {
                    if (response.data.phones.length > 0) {
                        newPhones = response.data.phones;
                        isPhoneFound = true;
                    } else {
                        isPhoneFound = false;
                    }
                    phoneEnriched = true;
                }

                const updatedRowData = {
                    ...rowData,
                    ...(Object.values(newEmails).some((array) => array.length > 0) && {
                        workEmails: newEmails.workEmails ?? rowData.workEmails,
                        personalEmails: newEmails.personalEmails ?? rowData.personalEmails,
                        riskyEmails: newEmails.riskyEmails ?? rowData.riskyEmails,
                        unknownEmails: newEmails.unknownEmails ?? rowData.unknownEmails,
                        doNotEmails: newEmails.doNotEmails ?? rowData.doNotEmails,
                        invalidEmails: newEmails.invalidEmails ?? rowData.invalidEmails,
                    }),
                    ...(newPhones.length > 0 && {
                        validPhones: newPhones,
                    }),
                    ...(isEmailFound !== undefined && { isEmailFound }),
                    ...(isPhoneFound !== undefined && { isPhoneFound }),
                    ...(emailEnriched !== undefined && { emailEnriched }),
                    ...(phoneEnriched !== undefined && { phoneEnriched }),
                };

                if (enrichType === ENRICHMENT_TYPE.DEEP_SEARCH) {
                    setDeepEnriched(true);
                }

                return updatedRowData;
            } else {
                throw new Error(response?.message || "Something went wrong!");
            }
        } catch (error) {
            console.error("Enrichment error: ", error);
            toast.error(error.message, {
                theme: "colored",
            });
        } finally {
            switch (enrichType) {
                case ENRICHMENT_TYPE.EMAIL_ONLY:
                    setEmailEnriching(false);
                    break;
                case ENRICHMENT_TYPE.PHONE_ONLY:
                    setPhoneEnriching(false);
                    break;
                case ENRICHMENT_TYPE.ALL:
                    setBothEnriching(false);
                    break;
                case ENRICHMENT_TYPE.DEEP_SEARCH:
                    setDeepEnriching(false);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEnrichButtonClick = async (enrichType) => {
        const updatedRowData = await enrichContact(enrichType);
        updateTableDataAfterEnrich(updatedRowData);
    };

    const memoizedRow = useMemo(() => ({ ...row }), [row]);

    useEffect(() => {
        if (!memoizedRow) return;

        const newRow = { ...memoizedRow };

        if (newRow?.isEmailFound) {
            newRow.enrichedEmails = mergeKompassEmails(newRow);
        }

        if (newRow?.isPhoneFound) {
            newRow.enrichedPhones = (newRow.validPhones || []).map((item) => ({
                phone_number: item.phoneNumber || item.phone_number,
                status: item.status,
            }));
        }

        setRowdata(newRow);

        setEmailEnriched(newRow?.emailEnriched ?? false);
        setPhoneEnriched(newRow?.phoneEnriched ?? false);
        setEmailEnriching(newRow?.emailEnriching ?? false);
        setPhoneEnriching(newRow?.phoneEnriching ?? false);
        setBothEnriching(newRow?.bothEnriching ?? false);
    }, [memoizedRow]);

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast.success("Copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
                toast.error("Failed to copy to clipboard!");
            });
    };

    return (
        <>
            <ContactDetailsModal open={isDetailsOpen} setOpen={setIsDetailsOpen} rowData={rowData} />
            <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={rowData.pdlId}
                selected={isItemSelected}
                sx={{
                    background: "white",
                    "& .MuiTableCell-root": {
                        padding: "13px 10px",
                        border: "none",
                        borderTop: props.index === 0 ? "none" : 1,
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell
                    padding="checkbox"
                    className={`${props.index === 9 || props.index === props.dataLength - 1 ? "rounded-bl-[6px]" : ""} !pr-0`}
                >
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.blue,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.blue,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        onClick={(event) => handleClick(event, rowData.pdlId)}
                    />
                </TableCell>
                <TableCell id={labelId} scope="row" padding="none">
                    <div className="flex flex-row items-center gap-2 text-sm">
                        <div className="flex">
                            {rowData?.profilePictureUrl ? (
                                <img src={rowData?.profilePictureUrl} width={30} height={30} alt={rowData?.profilePictureUrl} />
                            ) : (
                                <div
                                    className={
                                        "!text-[10px] font-OutfitMedium flex h-[30px] w-[30px] items-center p-1 justify-center rounded-full " +
                                        [
                                            " bg-accents-yellow text-black",
                                            " bg-accents-purple text-black",
                                            " bg-accents-green text-black",
                                            " bg-accents-blue text-black",
                                            " bg-accents-orange text-black",
                                        ][indexString(rowData.full_name, 5)]
                                    }
                                >
                                    {getShortName(rowData.full_name)}
                                </div>
                            )}
                        </div>
                        <div className={`flex flex-col w-full text-sm`}>
                            <div className="flex items-center gap-2">
                                <p
                                    className={`capitalize text-ellipsis cursor-pointer hover:underline whitespace-nowrap overflow-hidden ${filtersOpen ? "max-w-[200px] 3xl:max-w-[350px]" : ""}`}
                                    title={rowData.full_name}
                                    onClick={() => setIsDetailsOpen(true)}
                                >
                                    {rowData.full_name}
                                </p>
                                <Link target="_blank" to={"https://" + rowData?.linkedin_url}>
                                    <ReactSVG src={LinkedinIcon} style={{ height: 13, width: 13 }} />
                                </Link>
                            </div>
                            <p
                                className={`capitalize text-ellipsis text-stone-350 whitespace-nowrap overflow-hidden  ${filtersOpen ? "max-w-[200px] 3xl:max-w-[350px]" : ""}`}
                                title={rowData.job_title}
                            >
                                {rowData.job_title}
                            </p>
                        </div>
                    </div>
                </TableCell>
                <TableCell padding="none" align="left">
                    <div
                        className="flex flex-row items-center gap-2 text-sm cursor-pointer"
                        onClick={() => {
                            window.open("https://" + rowData?.job_company_website, "_blank");
                        }}
                    >
                        <div className="flex h-[30px] w-[30px] shrink-0">
                            <img
                                src={`https://logo.clearbit.com/${rowData?.job_company_website}`}
                                alt="Logo"
                                className="relative object-contain object-center"
                                onLoad={() => setImageLoaded(true)}
                                onError={() => setImageLoaded(false)}
                                style={{
                                    display: imageLoaded ? "block" : "none",
                                }}
                            />
                            {imageLoaded === false && (
                                <div
                                    className={
                                        "!text-[10px] font-OutfitMedium flex h-[30px] w-[30px] p-1 items-center justify-center rounded-full" +
                                        [
                                            " bg-accents-yellow text-black",
                                            " bg-accents-purple text-black",
                                            " bg-accents-green text-black",
                                            " bg-accents-blue text-black",
                                            " bg-accents-orange text-black",
                                        ][indexString(rowData?.job_company_name, 5)]
                                    }
                                >
                                    {rowData?.job_company_name ? getShortName(rowData?.job_company_name) : "-"}
                                </div>
                            )}
                        </div>
                        <div className={`flex flex-col w-full text-sm `}>
                            <p
                                className={`capitalize text-ellipsis whitespace-nowrap overflow-hidden ${filtersOpen ? "max-w-[180px] 3xl:max-w-[350px]" : ""}`}
                            >
                                {rowData?.job_company_name}
                            </p>
                            <p
                                className={`capitalize text-ellipsis whitespace-nowrap overflow-hidden text-stone-350 ${filtersOpen ? "max-w-[180px] 3xl:max-w-[350px]" : ""}`}
                            >
                                {rowData?.industry}
                            </p>
                        </div>
                    </div>
                </TableCell>
                <TableCell padding="none" align="left">
                    <p className={`capitalize text-ellipsis whitespace-nowrap overflow-hidden text-sm`}>
                        {rowData?.job_title_levels ? rowData.job_title_levels[0] : "-"}
                    </p>
                </TableCell>
                <TableCell padding="none" align="left">
                    <p className={`capitalize text-ellipsis whitespace-nowrap overflow-hidden text-sm`}>
                        {rowData?.location.split(",")[rowData?.location?.split(",")?.length - 1] || "-"}
                    </p>
                </TableCell>
                <TableCell
                    padding="none"
                    align="left"
                    width={100}
                    className={`${props.index === 9 || props.index === props.dataLength - 1 ? "rounded-br-[6px]" : ""}`}
                >
                    <div className="relative ">
                        {deepEnriching || emailEnriching || bothEnriching || phoneEnriching ? (
                            <button
                                onClick={(e) => {
                                    handleToggleDetails(props.index);
                                }}
                                className="whitespace-nowrap flex items-center cursor-pointer !text-sm rounded-[8px] font-medium border bg-white px-4 py-2 border-stone-500 text-stone-950 hover:bg-dgray-100"
                            >
                                <div className="loader-circle"></div>
                                <div className="loader-dots-wrapper">
                                    Enriching
                                    <div className="loader-dots"></div>
                                </div>
                            </button>
                        ) : (
                            <button
                                onClick={(e) => {
                                    handleToggleDetails(props.index);
                                }}
                                className={`transition-all whitespace-nowrap flex items-center gap-2 cursor-pointer !text-sm rounded-[8px] font-medium border px-4 py-2 border-dgray-800 hover:bg-stone-100 ${!rowData?.enrichedPhones?.length && !rowData?.enrichedEmails?.length ? "bg-stone-100 hover:bg-white" : "bg-white hover:bg-stone-100"}`}
                            >
                                {!rowData?.enrichedPhones?.length && !rowData?.enrichedEmails?.length && (
                                    <LockIcon className="w-4 h-4 fill-current text-stone-950" />
                                )}
                                View Details
                            </button>
                        )}
                        {openRowIndex === props.index && (
                            <div className="absolute text-sm min-w-[400px] mb-2 top-[50px] 2xl:top-[57px] right-0 shadow-custom3 rounded-lg cursor-default bg-white z-20 border border-stone-250">
                                <div className="triangle-search">
                                    <div className="flex gap-2 text-sm p-4 border-b border-stone-250">
                                        <button
                                            className="bg-black text-white whitespace-nowrap w-full rounded-lg py-1.5 px-2.5 flex gap-1 items-center justify-center flex-nowrap disabled:bg-gray-700"
                                            onClick={() => handleEnrichButtonClick(ENRICHMENT_TYPE.EMAIL_ONLY)}
                                            disabled={
                                                deepEnriching ||
                                                emailEnriching ||
                                                bothEnriching ||
                                                phoneEnriching ||
                                                deepEnriching ||
                                                emailEnriched
                                            }
                                        >
                                            <MailIcon className="w-[16px] h-[16px]" />
                                            Access Email
                                        </button>
                                        <button
                                            className="bg-black text-white whitespace-nowrap w-full rounded-lg py-1 px-2 flex gap-1 items-center justify-center flex-nowrap disabled:bg-gray-700"
                                            onClick={() => handleEnrichButtonClick(ENRICHMENT_TYPE.PHONE_ONLY)}
                                            disabled={
                                                deepEnriching ||
                                                phoneEnriching ||
                                                bothEnriching ||
                                                emailEnriching ||
                                                deepEnriching ||
                                                phoneEnriched
                                            }
                                        >
                                            <PhoneIcon className="w-[16px] h-[16px]" />
                                            Access Phone
                                        </button>
                                        <button
                                            className="bg-black text-white whitespace-nowrap w-full rounded-lg py-1 px-2 flex gap-1 items-center justify-center flex-nowrap disabled:bg-gray-700"
                                            onClick={() => handleEnrichButtonClick(ENRICHMENT_TYPE.DEEP_SEARCH)}
                                            disabled={deepEnriching || bothEnriching || emailEnriching || phoneEnriching || deepEnriched}
                                        >
                                            <BulbIcon className="w-[16px] h-[16px]" />
                                            AI Search
                                        </button>
                                    </div>
                                    <div className="p-4 border-b border-stone-250">
                                        <p className="font-semibold text-start">Contact Emails: </p>
                                        {rowData?.enrichedEmails?.length > 0 &&
                                            rowData.enrichedEmails.map((item, key) => {
                                                return (
                                                    <div key={key} className="flex items-center gap-2 text-gray-950 mt-2.5">
                                                        <MailGrayIcon className="w-[16px] h-[16px]" />
                                                        {item.email}
                                                        <p className="font-Outfit opacity-80">
                                                            {item.type === "Valid" ? (
                                                                <Chip
                                                                    icon={<CheckCircleIcon className="w-[16px] h-[16px]" />}
                                                                    label="Valid"
                                                                    size="small"
                                                                    className="!bg-green-100 !text-green-700 !px-1"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontFamily: "Outfit",
                                                                        lineHeight: "100%",
                                                                        borderRadius: 4,
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {item.type === "Personal" ? (
                                                                <Chip
                                                                    icon={<PersonCheckIcon className="w-[16px] h-[16px]" />}
                                                                    label="Valid"
                                                                    size="small"
                                                                    className="!bg-blue-100 !text-blue-700 !px-1"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontFamily: "Outfit",
                                                                        lineHeight: "100%",
                                                                        borderRadius: 4,
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {item.type === "Risky" ? (
                                                                <Chip
                                                                    icon={<RiskIcon className="!text-orange-600 !w-[16px] !h-[16px]" />}
                                                                    label="Accept All"
                                                                    size="small"
                                                                    className="!bg-orange-100 !text-orange-600 !px-1"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontFamily: "Outfit",
                                                                        lineHeight: "100%",
                                                                        borderRadius: 4,
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {item.type === "Unknown" ? (
                                                                <Chip
                                                                    icon={
                                                                        <WarningOutlinedIcon className="!text-yellow-700 !w-[16px] !h-[16px]" />
                                                                    }
                                                                    label="Suspicious"
                                                                    size="small"
                                                                    className="!bg-yellow-100 !text-yellow-700 !px-1"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontFamily: "Outfit",
                                                                        lineHeight: "100%",
                                                                        borderRadius: 4,
                                                                    }}
                                                                />
                                                            ) : null}
                                                            {item.type === "Do not email" ? (
                                                                <Chip
                                                                    icon={<RiskIcon className="!text-red-700 !w-[16px] !h-[16px]" />}
                                                                    label="Do Not Email"
                                                                    size="small"
                                                                    className="!bg-red-100 !text-red-700 !px-1"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontFamily: "Outfit",
                                                                        lineHeight: "100%",
                                                                        borderRadius: 4,
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </p>
                                                        <CopyGrayIcon
                                                            className="w-[16px] h-[16px] ml-auto cursor-pointer"
                                                            onClick={() => copyToClipboard(item.email)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        {rowData?.isEmailFound === false && (
                                            <div className="text-sm text-gray-950 mt-1">Email not available</div>
                                        )}
                                    </div>

                                    <div className="p-4 border-b border-stone-250">
                                        <p className="font-semibold text-start">Contact Phones: </p>
                                        {rowData?.enrichedPhones?.length > 0 &&
                                            rowData.enrichedPhones.map((item, key) => {
                                                return (
                                                    <div key={key} className="flex items-center gap-1 text-gray-950 mt-2.5">
                                                        <PhoneGrayIcon className="w-[16px] h-[16px]" />
                                                        {item.phone_number}
                                                        <p className="font-Outfit opacity-80">
                                                            {item.status === "valid" ? (
                                                                <Chip
                                                                    icon={<BusinessIcon className="!text-green-700 !w-[16px] !h-[16px] " />}
                                                                    label="Direct dial"
                                                                    size="small"
                                                                    className="!bg-green-100 !text-green-700 !px-1"
                                                                    style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                />
                                                            ) : null}
                                                            {item.status === "hq" ? (
                                                                <Chip
                                                                    icon={<BusinessIcon className="!text-blue-700 !w-[16px] !h-[16px]" />}
                                                                    label="Headquarters"
                                                                    size="small"
                                                                    className="!bg-blue-100 !text-blue-700 !px-1"
                                                                    style={{ fontSize: 12, fontFamily: "Outfit", borderRadius: 4 }}
                                                                />
                                                            ) : null}
                                                        </p>
                                                        <CopyGrayIcon
                                                            className="w-[16px] h-[16px] ml-auto cursor-pointer"
                                                            onClick={() => copyToClipboard(item.phone_number)}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        {rowData?.isPhoneFound === false && (
                                            <div className="text-sm text-gray-950 mt-1">Phone not available</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </TableCell>
            </TableRow>

            <CreateNewListDialog
                open={isVisibleNewList}
                close={() => {
                    setVisibleNewList(false);
                }}
                onsave={() => handleSaveToList({ pdlId: rowData.pdlId })}
            />
        </>
    );
};

const submitDialogParams = {
    listData: null,
    contactList: null,
};

export default function ContactTable({
    filter,
    selected,
    setSelected,
    filtersOpen,
    openRowIndex,
    setOpenRowIndex,
    isLoading,
    setIsLoading,
    setIsSearchClicked,
    isSearchClicked,
    setContactFilter,
}) {
    const { postKompassSearchContactsByFilters } = useKompassSearch();
    const { planInfo } = useKompassFilters();
    const { bulkEnrichByLinkedinFetch } = useBulk();
    const { getContactList, getProfilesByContactId } = useList();

    const dense = false;
    const rowsPerPage = 10;
    const [tableData, setTableData] = useState([]);
    const [cursorMark, setCursorMark] = useState(null);
    const [totalCount, setTotalCount] = useState(null);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [detailVisible, setDetailVisible] = useState([]);
    const [page, setPage] = useState(0);
    const [visibleRows, setVisibleRows] = useState([]);
    const [isPushDialogOpen, setPushDialogOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const [isVisibleNewList, setVisibleNewList] = useState(false);
    const [scrollToken, setScrollToken] = useState("");
    const [isBulkLoading, setBulkLoading] = useState(false);

    const { postEnrichContact } = useEnrichment();
    const lastApiCallRef = useRef(0);
    const { listId } = useParams();
    const scrollAreaRef = useRef(null);

    const numSelectedOnPage = visibleRows.filter((row) => selected.includes(row.pdlId)).length;

    const menuOptions = [
        {
            icon: MoveGroupIcon,
            label: "Move to the group",
        },
        {
            icon: RemoveTeamIcon,
            label: "Remove team member",
        },
    ];

    const clearTable = () => {
        setVisibleRows([]);
        setTableData([]);
        setTotalCount(null);
        setCursorMark(null);
        setSelected([]);
    };

    const handleSearchByFilter = async (contactFilter, scrollToken = null) => {
        if (!contactFilter || Object.keys(contactFilter).length === 0) {
            clearTable();
            return;
        }

        contactFilter = {
            ...contactFilter,
            ...(extractLocationsFromStr([contactFilter?.location]) ?? {}),
        };
        delete contactFilter.location;

        let _tableData = tableData;

        if (scrollToken) {
            contactFilter["scroll_token"] = scrollToken;
        } else {
            setTableData([]);
            _tableData = [];
        }

        setIsLoading(true);
        try {
            const now = Date.now();

            // Check if 7 seconds have passed since the last API call
            if (now - lastApiCallRef.current < 7000) {
                // Calculate the remaining time
                const timeRemaining = 7000 - (now - lastApiCallRef.current);
                // Wait for the remaining time
                await delay(timeRemaining);
            }

            // Set the last API call time to the current time
            lastApiCallRef.current = Date.now();

            const _response = await postKompassSearchContactsByFilters(contactFilter);

            if (!_response.status && !_response?.response) {
                throw new Error(_response.message);
            }

            if (!_response.error) {
                const newTableData = _response.response.data.map((item) => {
                    const newItem = {
                        ...item,
                        name: item?.full_name,
                        companyName: item?.job_company_name,
                        location: item?.location_name,
                    };
                    return newItem;
                });

                setTableData([..._tableData, ...newTableData]);
                setScrollToken(_response.response.scroll_token);
                setTotalCount(_response.response.total);

                setPage(0);
            } else {
                setTableData([..._tableData]);
            }
        } catch (e) {
            console.log({ e });
            if (e.message === "Credit balance can not be negative.") {
                toast.error("For free plan, 10 searches available, please upgrade plan");
            }
            setTableData([..._tableData]);
            setTotalCount(0);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setSelected([]);
        if (filter) {
            handleSearchByFilter({ ...filter });
        }
    }, [filter]);

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const openMenu = () => {
        return menuAnchorEl !== null;
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // Select all contacts on the current page
            const newSelected = [...selected, ...visibleRows.map((n) => n.pdlId).filter((id) => !selected.includes(id))];
            setSelected(newSelected);
        } else {
            // Deselect all contacts on the current page
            const newSelected = selected.filter((id) => !visibleRows.some((n) => n.pdlId === id));
            setSelected(newSelected);
        }
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleClickDetail = (event, id) => {
        const detailIndex = detailVisible.indexOf(id);
        let newDetailVisible = [];

        if (detailIndex === -1) {
            newDetailVisible = newDetailVisible.concat(detailVisible, id);
        } else if (detailIndex === 0) {
            newDetailVisible = newDetailVisible.concat(detailVisible.slice(1));
        } else if (detailIndex === detailVisible.length - 1) {
            newDetailVisible = newDetailVisible.concat(detailVisible.slice(0, -1));
        } else if (detailIndex > 0) {
            newDetailVisible = newDetailVisible.concat(detailVisible.slice(0, detailIndex), detailVisible.slice(detailIndex + 1));
        }

        setDetailVisible(newDetailVisible);
    };

    const handleToggleDetails = (rowIndex) => {
        setOpenRowIndex((prevIndex) => (prevIndex === rowIndex ? null : rowIndex));
    };

    const handleFavorite = (index, bSelected, favoriteId = null) => {
        const newTableData = [...tableData];
        newTableData[page * rowsPerPage + index]["favoriteId"] = favoriteId;
        setTableData(newTableData);
    };

    const handleChangePage = async (event, newPage) => {
        setOpenRowIndex(null);
        if (planInfo?.name === "Free" && !planInfo?.isTeamMember && newPage === 2) {
            toast.info("Please get a paid plan to view additional pages on search.");
            return; // Prevent actual page change
        }

        if (newPage > Math.floor(tableData.length / rowsPerPage) && totalCount > tableData.length) {
            await handleSearchByFilter(filter, scrollToken);
        }

        setPage(newPage - 1);
        setDetailVisible([]);
        // Scroll to top of ScrollArea
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollToTop();
        }
    };

    const onSelectionEnrich = async (enrichType) => {
        let emailEnriching;
        let phoneEnriching;
        let bothEnriching;

        switch (enrichType) {
            case ENRICHMENT_TYPE.EMAIL_ONLY:
                emailEnriching = true;
                break;
            case ENRICHMENT_TYPE.PHONE_ONLY:
                phoneEnriching = true;
                break;
            case ENRICHMENT_TYPE.ALL:
                bothEnriching = true;
                break;
            default:
                break;
        }

        const updatedTableDataBeforeEnrich = tableData.map((row) => {
            const indexOfRow = selected.findIndex((selectedId) => row.pdlId === selectedId);
            if (indexOfRow >= 0) {
                return { ...row, emailEnriching, phoneEnriching, bothEnriching };
            } else {
                return row;
            }
        });

        setTableData(updatedTableDataBeforeEnrich);

        switch (enrichType) {
            case ENRICHMENT_TYPE.EMAIL_ONLY:
                emailEnriching = false;
                break;
            case ENRICHMENT_TYPE.PHONE_ONLY:
                phoneEnriching = false;
                break;
            case ENRICHMENT_TYPE.ALL:
                bothEnriching = false;
                break;
            default:
                break;
        }

        try {
            const updatedTableDataAfterEnrich = [];
            for (const row of tableData) {
                const indexOfRow = selected.findIndex((selectedId) => row.pdlId === selectedId);
                if (indexOfRow >= 0) {
                    const enrichRequestParams = {
                        inputs: {
                            url: row?.linkedin_url || "",
                            company_website: row?.job_company_website || "",
                            company_name: row?.job_company_name || "",
                            full_name: row.full_name || "",
                            from: "kompass_search",
                        },
                    };

                    try {
                        const response = await postEnrichContact(enrichRequestParams, enrichType);

                        if (response.status && response.data) {
                            let newEmails = [];
                            let newPhones = [];
                            let isEmailFound;
                            let isPhoneFound;
                            let emailEnriched;
                            let phoneEnriched;

                            if (enrichType !== ENRICHMENT_TYPE.PHONE_ONLY && response?.data?.emails) {
                                if (Object.values(response.data.emails).some((array) => array.length > 0)) {
                                    newEmails = response.data.emails;
                                    isEmailFound = true;
                                } else {
                                    isEmailFound = false;
                                }
                                emailEnriched = true;
                            }

                            if (enrichType !== ENRICHMENT_TYPE.EMAIL_ONLY && response?.data?.phones) {
                                if (response.data.phones.length > 0) {
                                    newPhones = response.data.phones;
                                    isPhoneFound = true;
                                } else {
                                    isPhoneFound = false;
                                }
                                phoneEnriched = true;
                            }

                            const updatedRowData = {
                                ...row,
                                ...(Object.values(newEmails).some((array) => array.length > 0) && {
                                    workEmails: newEmails.workEmails ?? row.workEmails,
                                    personalEmails: newEmails.personalEmails ?? row.personalEmails,
                                    riskyEmails: newEmails.riskyEmails ?? row.riskyEmails,
                                    unknownEmails: newEmails.unknownEmails ?? row.unknownEmails,
                                    doNotEmails: newEmails.doNotEmails ?? row.doNotEmails,
                                    invalidEmails: newEmails.invalidEmails ?? row.invalidEmails,
                                }),
                                ...(newPhones.length > 0 && {
                                    validPhones: newPhones,
                                }),
                                ...(isEmailFound !== undefined && { isEmailFound }),
                                ...(isPhoneFound !== undefined && { isPhoneFound }),
                                ...(emailEnriched !== undefined && { emailEnriched }),
                                ...(phoneEnriched !== undefined && { phoneEnriched }),
                                ...(emailEnriching !== undefined && { emailEnriching }),
                                ...(phoneEnriching !== undefined && { phoneEnriching }),
                                ...(bothEnriching !== undefined && { bothEnriching }),
                            };

                            updatedTableDataAfterEnrich.push(updatedRowData);
                        } else {
                            throw new Error(response?.message);
                        }
                    } catch (error) {
                        console.error("Enrichment request failed: ", error);
                        toast.error(`Enrichment error for contact: ${row?.linkedin_url || row?.full_name || ""}: ${error.message}`, {
                            theme: "colored",
                        });
                        updatedTableDataAfterEnrich.push({ ...row });
                    }
                } else {
                    updatedTableDataAfterEnrich.push(row);
                }
            }

            setTableData(updatedTableDataAfterEnrich);
        } catch (e) {
            const updatedTableDataIfError = tableData.map((row) => {
                const indexOfRow = selected.findIndex((selectedId) => row.pdlId === selectedId);
                if (indexOfRow >= 0) {
                    return { ...row, emailEnriching, phoneEnriching, bothEnriching };
                } else {
                    return row;
                }
            });

            setTableData(updatedTableDataIfError);

            console.log(e);
            toast.error(`Error during enrich: ${e?.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const isSelected = (pdlId) => selected.indexOf(pdlId) !== -1;
    const isDetailVisible = (pdlId) => detailVisible.indexOf(pdlId) !== -1;

    const visibleRowsMemo = useMemo(() => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const rows = tableData.slice(startIndex, endIndex);

        return stableSort(rows, getComparator(order, orderBy));
    }, [order, orderBy, page, rowsPerPage, tableData]);

    useEffect(() => {
        setVisibleRows(visibleRowsMemo);
    }, [visibleRowsMemo]);

    const saveToList = async (selectedPdlIds) => {
        console.log("tableData before save to list: ", tableData);
        const filteredTableData = tableData.filter(
            (item) =>
                selectedPdlIds.includes(item.pdlId) &&
                (item?.isEmailFound ||
                    item?.isPhoneFound ||
                    (item?.validPhones && item.validPhones.length > 0) ||
                    (item?.personalEmails && item.personalEmails.length > 0) ||
                    (item?.workEmails && item.workEmails.length > 0))
        );

        if (!filteredTableData || filteredTableData.length <= 0) {
            toast.error("There is no data to save. Only enriched records can be saved", { theme: "colored" });
            return;
        }

        submitDialogParams.listData = filteredTableData;

        if (!submitDialogParams.contactList) {
            setIsLoading(true);
            const response = await getContactList({
                listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
            });
            setIsLoading(false);

            if (!response.status) {
                toast.error(response.message, { theme: "colored" });
                return;
            }

            const contactList = [...response.data];

            if (contactList.length <= 0) {
                setVisibleNewList(true);
                return;
            }

            submitDialogParams.contactList = contactList;
        }

        setPushDialogOpen(true);
    };

    const handleSubmitDialogClose = async () => {
        setPushDialogOpen(false);
        const response = await getContactList({
            listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
        });
        if (response.status) {
            const contactList = [...response.data];
            if (contactList.length > 0) {
                submitDialogParams.contactList = contactList;
            }
        }
    };

    const onSaveSelected = async (params) => {
        const selectedPdlIds = params?.pdlId ? [params.pdlId] : selected;
        await saveToList(selectedPdlIds);
    };

    const onExportSelected = async () => {
        const selectedData = selected.map((_id) => tableData.find((item) => item.pdlId === _id));
        debugger;
        downloadProfilesCSV(`export_${Date.now()}`, selectedData);
    };

    const updateTableDataAfterEnrich = async (newRowData) => {
        setTableData((prevTableData) => prevTableData.map((row) => (row?.pdlId === newRowData?.pdlId ? { ...row, ...newRowData } : row)));
    };

    const handleBulkEnrich = async (bulkNumber, listTitles, enrichParams) => {
        setBulkLoading(true);
        let _scrollToken = scrollToken;
        let _tableData = tableData;
        let _totalCount = totalCount;
        let totalProfilesLoaded = tableData.length;
        let enrichType;
        const maxSize = 100; // Maximum profiles per PDL request
        let totalProfilesNeeded = bulkNumber > 500 ? 500 : bulkNumber;
        if (totalCount < totalProfilesNeeded) totalProfilesNeeded = totalCount;
        const { existingBulkListTitle, newBulkListTitle } = listTitles;
        const { isEmailIncluded, isPhoneIncluded } = enrichParams;

        if (isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.ALL;
        if (isEmailIncluded && !isPhoneIncluded) enrichType = ENRICHMENT_TYPE.EMAIL_ONLY;
        if (!isEmailIncluded && isPhoneIncluded) enrichType = ENRICHMENT_TYPE.PHONE_ONLY;

        try {
            // Continue fetching data until we've loaded enough profiles
            while (totalProfilesLoaded < totalProfilesNeeded) {
                // Calculate the size dynamically for each fetch
                const size = Math.max(maxSize, totalProfilesNeeded - totalProfilesLoaded);

                const contactFilter = {
                    ...filter,
                    ...(extractLocationsFromStr([filter?.location]) ?? {}),
                    size,
                };
                delete contactFilter.location;

                if (_scrollToken) {
                    contactFilter["scroll_token"] = _scrollToken;
                }

                const _response = await postKompassSearchContactsByFilters(contactFilter);

                if (_response?.response?.data?.length) {
                    const newTableData = _response.response.data.map((item) => ({
                        ...item,
                        name: item?.full_name,
                        companyName: item?.job_company_name,
                        location: item?.location_name,
                    }));

                    _tableData = [..._tableData, ...newTableData];

                    _scrollToken = _response.response.scroll_token;
                    _totalCount = _response.response.total;

                    // Update the totalProfilesLoaded with the newly fetched data count
                    totalProfilesLoaded += newTableData.length;
                } else if (!_response.status) {
                    throw new Error(_response.message);
                }
            }

            setScrollToken(_scrollToken);
            setTotalCount(_totalCount);

            _tableData = stableSort(_tableData, getComparator(order, orderBy));

            const dataForBulkRequest = _tableData.slice(0, totalProfilesNeeded);

            if (dataForBulkRequest?.length > 0) {
                const bulkResponse = await bulkEnrichByLinkedinFetch({
                    inputs: dataForBulkRequest?.map((data) => ({
                        ...data,
                        url: data.linkedin_url,
                    })),
                    listTitle: existingBulkListTitle || newBulkListTitle,
                    isNewList: existingBulkListTitle === "",
                    enrichType,
                    isPollingFromUI: true,
                });

                if (bulkResponse?.status && bulkResponse?.data?.length > 0) {
                    const enrichedData = bulkResponse?.data.flat();

                    const mergedData = _tableData.map((profile) => {
                        const match = enrichedData.find(
                            (item) => cleanLinkedUrl(profile?.linkedin_url || "") === cleanLinkedUrl(item?.linkedinUrl || "")
                        );
                        if (match) {
                            return {
                                ...profile,
                                ...(Object.values(match.emails).some((array) => array.length > 0) && {
                                    workEmails: match.emails.workEmails ?? [],
                                    personalEmails: match.emails.personalEmails ?? [],
                                    riskyEmails: match.emails.riskyEmails ?? [],
                                    unknownEmails: match.emails.unknownEmails ?? [],
                                    doNotEmails: match.emails.doNotEmails ?? [],
                                    invalidEmails: match.emails.invalidEmails ?? [],
                                    isEmailFound: true,
                                }),
                                ...(Object.values(match.emails).every((array) => array.length <= 0) && {
                                    isEmailFound: false,
                                }),
                                ...(match.phones.length > 0 && {
                                    validPhones: match.phones,
                                    isPhoneFound: true,
                                }),
                                ...(match.phones.length <= 0 && {
                                    isPhoneFound: false,
                                }),
                            };
                        }
                        return profile;
                    });

                    _tableData = stableSort(mergedData, getComparator(order, orderBy));
                    setTableData(_tableData);
                } else if (bulkResponse?.status && bulkResponse.message) {
                    toast.success(bulkResponse.message, {
                        theme: "colored",
                    });
                } else {
                    toast.error(bulkResponse.message);
                }
            }

            // handling the below code in BE, due to polling
            // const dataForSavingToList = _tableData?.slice(0, totalProfilesNeeded)?.filter((item) => item.isEmailFound || item.isPhoneFound);

            // if (newBulkListTitle && dataForSavingToList?.length > 0) {
            //     const createResult = await createContactList({
            //         listTitle: newBulkListTitle,
            //         listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
            //     });

            //     if (createResult.status) {
            //         toast.success(`"${newBulkListTitle}" Contact List has been created.`, {
            //             theme: "colored",
            //         });

            // const saveResult = await postSaveKompassSearchContacts({
            //             listTitle: newBulkListTitle,
            //             listData: dataForSavingToList,
            //         });

            //         if (saveResult.status) {
            //             toast.success(saveResult.message, { theme: "colored" });
            //         } else toast.error(saveResult.message, { theme: "colored" });
            //     } else toast.error(createResult.message, { theme: "colored" });
            // }

            // if (existingBulkListTitle && dataForSavingToList?.length > 0) {
            //     const result = await postSaveKompassSearchContacts({
            //         listTitle: existingBulkListTitle,
            //         listData: dataForSavingToList,
            //     });

            //     if (result.status) {
            //         toast.success(result.message, { theme: "colored" });
            //     } else toast.error(result.message, { theme: "colored" });
            // }
        } catch (e) {
            toast.error(`Error during bulk enrich: ${e.message}`);
        } finally {
            setBulkLoading(false);
            setTableData(_tableData);

            const newPage = Math.max(0, Math.floor(_tableData?.length / 10) - 1);
            setPage(newPage);
        }
    };

    const someConditionToDisablePage = (pageNumber) => {
        // For free users, disable all pages except page 2
        if (planInfo?.name === "Free" && !planInfo?.isTeamMember) {
            return pageNumber !== 2;
        }
        return false; // No pages are disabled for non-free users
    };

    const fetchContactsByListId = async () => {
        setIsLoading(true);
        const response = await getProfilesByContactId({ contactId: listId });

        if (response.status) {
            const newTableData = [...response.data];
            setTableData(newTableData);
            setTotalCount(newTableData.length);
            setIsSearchClicked(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchContactsByListId();
    }, []);

    return (
        <>
            <FlexSearch
                setContactFilter={setContactFilter}
                handleSearchByFilter={handleSearchByFilter}
                setIsLoading={setIsLoading}
                setIsSearchClicked={setIsSearchClicked}
            />
            {isLoading || isBulkLoading ? (
                <div className="relative w-full main-loader-wrapper">
                    <MainLoader isAllPage={false} />
                </div>
            ) : !isSearchClicked ? (
                <div className="flex justify-center text-sm flex-col gap-4 items-center mt-1 lg:mt-0 lg:h-[calc(100vh-120px)]">
                    <EmptyState className="w-[220px] h-[150px]" />
                    <p className="text-2xl font-bold">Let's Find Some Prospects!</p>
                    <p className="text-dgray-600 !mt-[22px]">
                        <strong className="text-black">Type your query:</strong> Start by typing a job title, location or industry in the
                        search field
                    </p>
                    <p className="text-dgray-600">OR</p>
                    <p className="text-dgray-600">
                        <strong className="text-black">Use search filters:</strong> Apply filters directly to refine your search criteria
                        from the very beginning
                    </p>
                </div>
            ) : visibleRows?.length > 0 ? (
                <>
                    <div className="my-8 mt-3">
                        <div className="mx-8">
                            <EnhancedTableToolbar
                                selected={selected}
                                setSelected={setSelected}
                                tableData={tableData}
                                numSelected={selected.length}
                                numSelectedOnPage={numSelectedOnPage}
                                totalRowsOnPage={visibleRows.length}
                                totalCount={totalCount}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={visibleRows.length}
                                onSelectionPhoneEnrich={() => onSelectionEnrich(ENRICHMENT_TYPE.PHONE_ONLY)}
                                onSelectionEmailEnrich={() => onSelectionEnrich(ENRICHMENT_TYPE.EMAIL_ONLY)}
                                onSelectionEmailAndPhoneEnrich={() => onSelectionEnrich(ENRICHMENT_TYPE.ALL)}
                                onSaveSelected={onSaveSelected}
                                onExportSelected={onExportSelected}
                                handleBulkEnrich={handleBulkEnrich}
                            />
                        </div>
                        <ScrollArea className="h-[calc(100vh-200px)]" ref={scrollAreaRef}>
                            <div className="mx-8 overflow-x-scroll min-h-screen" style={{ scrollbarWidth: "none" }}>
                                <Table
                                    sx={{
                                        width: "100%",
                                        borderCollapse: "separate",
                                    }}
                                    className="border rounded-[6px] border-stone-250 w-full"
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                >
                                    <EnhancedTableHead
                                        headCells={headCells}
                                        numSelected={selected.length}
                                        numSelectedOnPage={numSelectedOnPage}
                                        totalRowsOnPage={visibleRows.length}
                                        onSelectAllClick={handleSelectAllClick}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={visibleRows.length}
                                    />
                                    <TableBody
                                        sx={{
                                            "& .MuiTableCell-sizeMedium": {
                                                paddingTop: "20px",
                                                paddingBottom: "20px",
                                                paddingLeft: "18px",
                                                paddingRight: "24px",
                                                fontFamily: "Outfit",
                                                fontSize: 14,
                                                border: "1px solid #E8E7E7",
                                            },
                                        }}
                                    >
                                        {visibleRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.pdlId);
                                            const detailVisible = isDetailVisible(row.pdlId);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <ContactTableRow
                                                    key={index}
                                                    isItemSelected={isItemSelected}
                                                    row={row}
                                                    index={index}
                                                    labelId={labelId}
                                                    handleClick={handleClick}
                                                    handleClickDetail={handleClickDetail}
                                                    detailVisible={detailVisible}
                                                    handleToggleDetails={handleToggleDetails}
                                                    openRowIndex={openRowIndex}
                                                    handleFavorite={handleFavorite}
                                                    handleSaveToList={onSaveSelected}
                                                    isVisibleNewList={isVisibleNewList}
                                                    setVisibleNewList={setVisibleNewList}
                                                    updateTableDataAfterEnrich={updateTableDataAfterEnrich}
                                                    filtersOpen={filtersOpen}
                                                    dataLength={visibleRows.length}
                                                />
                                            );
                                        })}
                                    </TableBody>
                                </Table>

                                <Menu
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={menuAnchorEl}
                                    open={openMenu()}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        style: {
                                            // maxHeight: ITEM_HEIGHT * 4.5,
                                            // width: "20ch",
                                        },
                                    }}
                                >
                                    {menuOptions.map((option) => (
                                        <MenuItem key={option} selected={option === "Pyxis"} onClick={handleMenuClose}>
                                            <ListItemIcon>
                                                <ReactSVG src={option.icon} />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{option.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <div className="flex justify-center w-full py-10">
                                    <Pagination
                                        className="font-Outfit"
                                        disabled={!planInfo || (planInfo?.name === "Free" && !planInfo?.isTeamMember)}
                                        count={Math.ceil(tableData.length / rowsPerPage) + (totalCount > tableData.length ? 1 : 0)}
                                        page={page + 1}
                                        onChange={handleChangePage}
                                        showFirstButton
                                        showLastButton
                                        sx={{
                                            "& .MuiPaginationItem-text": {
                                                fontFamily: "Outfit",
                                                fontSize: "14px",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "#4873FA !important", // Change the background color when selected
                                                color: "#fff", // Change the text color for the selected item
                                            },
                                        }}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                {...item}
                                                disabled={someConditionToDisablePage(item.page)}
                                                sx={{
                                                    "& .MuiPaginationItem-text": {
                                                        fontFamily: "Outfit",
                                                        fontSize: "14px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                    <SaveToListDialog params={submitDialogParams} open={isPushDialogOpen} close={handleSubmitDialogClose} />
                </>
            ) : (
                <div className="flex justify-center flex-col gap-4 items-center mt-16 lg:mt-0 lg:h-[calc(100vh-122px)]">
                    <NoResultsFound />
                    <p className="text-3xl font-bold">No Results Found</p>
                    <p className="text-dgray-600">Try adjusting your filters or keywords for better results.</p>
                </div>
            )}
        </>
    );
}
