import { ReactComponent as Logo } from "../assets/image/icons/logo.svg";
import LinkedInIcon from "../assets/image/icons/LinkedIn.svg";
import TwitterIcon from "../assets/image/icons/Twitter.svg";
import FacebookIcon from "../assets/image/icons/Facebook.svg";
import ChromeIcon from "../assets/image/icons/Chrome.svg";
import LoginPlatformIcon from "../assets/image/icons/LoginPlatform.svg";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { goToTop } from "@/utils/common";
import { HashLink } from "react-router-hash-link";

export default function LandingFooter(props) {
    return (
        <div className=" justify-center bg-stone-950 px-12 pb-6 pt-12">
            <div className="mb-6 w-full">
                <div className="flex flex-wrap gap-12 justify-between">
                    <div className="">
                        <Logo className="h-12 fill-current text-white" />
                        <div className="font-OutfitMedium mt-6 w-80 text-sm text-white">
                            Gather verified email addresses & phone numbers directly from LinkedIn, reach out, and see when they open your
                            emails.
                        </div>
                        <div className="mt-10 flex flex-row gap-4">
                            <a
                                href={"https://www.linkedin.com/company/kompassai1/about/"}
                                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg bg-white"
                            >
                                <img src={LinkedInIcon} alt="LinkedInIcon" width={10} height={10} />
                            </a>
                            <a
                                href={"https://www.linkedin.com/company/kompassai1/about/"}
                                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg bg-white"
                            >
                                <img src={FacebookIcon} alt="FacebookIcon" width={10} height={10} />
                            </a>
                            <a
                                href={"https://www.linkedin.com/company/kompassai1/about/"}
                                className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg bg-white"
                            >
                                <img src={TwitterIcon} alt="TwitterIcon" width={10} height={10} />
                            </a>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="font-OutfitBold mb-2 text-lg text-white">Product</div>
                        <HashLink to="/#intent-signals" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Intent Signals</div>
                        </HashLink>
                        <HashLink to="/#prospect" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Prospect</div>
                        </HashLink>
                        <HashLink to="/#engage" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Engage</div>
                        </HashLink>
                        {/* <HashLink to="/#email-deliverability" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">
                                Email Deliverability
                            </div>
                        </HashLink> */}
                    </div>
                    <div className="space-y-4">
                        <div className="font-OutfitBold mb-2 text-lg text-white">Resources</div>
                        <Link to="/faq" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">FAQ</div>
                        </Link>
                        <Link to="/pricing" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Pricing</div>
                        </Link>
                        <Link to="/competitors" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">The Competition</div>
                        </Link>
                        {/* <Link to="/blog" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">
                                KompassAI Insights
                            </div>
                        </Link> */}
                    </div>

                    <div className="space-y-4">
                        <div className="font-OutfitBold mb-2 text-lg text-white">Company</div>
                        <Link to="/" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">About Us</div>
                        </Link>
                        <Link to="/terms-and-conditions" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">
                                Legal Terms & Conditions
                            </div>
                        </Link>
                        <Link to="/privacy-policy" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Privacy Policy</div>
                        </Link>
                        <Link to="/sending-policy" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Sending Policy</div>
                        </Link>
                        <Link to="/" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">
                                Opt Out of the Email List
                            </div>
                        </Link>
                        <Link to="/directory" onClick={goToTop}>
                            <div className="font-Outfit my-1 cursor-pointer text-sm text-white hover:text-[#7babf9]">Directory</div>
                        </Link>
                    </div>

                    <div className="">
                        <Link to="https://chromewebstore.google.com/detail/kompassai/imkmjfajnjfpfkdojdmabcphojonjjjf?hl=en&authuser=2">
                            <Button className="flex w-full flex-row items-center justify-center rounded-xl border border-white bg-[#2A2A2A] bg-transparent p-[12px] md:p-[16px]">
                                <img src={ChromeIcon} alt="ChromeIcon" width={10} height={10} />
                                <div className="font-OutfitBold mx-[8px] cursor-pointer text-sm normal-case text-white">
                                    Chrome Extension
                                </div>
                                <span className="arrow-icons flex flex-row justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" role="presentation">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0h24v24H0z"></path>
                                            <path
                                                fill="currentColor"
                                                d="M10.53 17.53l-1.06-1.06L13.94 12 9.47 7.53l1.06-1.06L16.06 12z"
                                            ></path>
                                        </g>
                                    </svg>
                                </span>
                            </Button>
                        </Link>
                        <Link to="/signin">
                            <Button className="mt-[16px] flex w-full flex-row items-center justify-center rounded-xl border border-white bg-[#2A2A2A] bg-transparent p-[12px] md:p-[16px]">
                                <img src={LoginPlatformIcon} alt="LoginPlatformIcon" width={10} height={10} />
                                <div className="font-OutfitBold mx-[8px] cursor-pointer text-sm normal-case text-white">
                                    Login to Platform
                                </div>
                                <span className="arrow-icons flex flex-row justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" role="presentation">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0h24v24H0z"></path>
                                            <path
                                                fill="currentColor"
                                                d="M10.53 17.53l-1.06-1.06L13.94 12 9.47 7.53l1.06-1.06L16.06 12z"
                                            ></path>
                                        </g>
                                    </svg>
                                </span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
