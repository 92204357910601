export const handleConnect = async (token, service) => {
    const qp = new URLSearchParams();
    qp.set("service", service);
    const res = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/oauth?${qp.toString()}`, {
        method: "POST",
        redirect: "follow",
        headers: {
            Authorization: token,
        },
    });

    const response = await res.json();
    if (response.headers?.location) {
        window.location.replace(`https://${response.headers.location[0].value}`);
    }
};
export const handleDisconnect = async (token, service) => {
    const qp = new URLSearchParams();
    qp.set("service", service);
    const res = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/oauth?${qp.toString()}`, {
        method: "DELETE",
        redirect: "follow",
        headers: {
            Authorization: token,
        },
    });
    if (res.status !== 200) {
        return false;
    }
    return await res.json();
};
export const handleAuthCheck = async (token, service) => {
    const qp = new URLSearchParams();
    qp.set("service", service);
    const res = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/oauth?${qp.toString()}`, {
        method: "GET",
        headers: {
            Authorization: token,
        },
    });
    if (res.status !== 200) {
        return false;
    }
    return await res.json();
};
export const handleCodeAuth = async (method, info, token, service) => {
    console.log("handleCodeAuth", method, token);
    const qp = new URLSearchParams();
    qp.set("service", service);
    if (method === "code") {
        qp.set("code", info);
    } else if (method === "token") {
        qp.set("tokenData", JSON.stringify(info));
    }
    qp.set("method", method);
    const res = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/oauth?${qp.toString()}`, {
        method: "GET",
        headers: {
            Authorization: token,
        },
    });
    if (res.status !== 200) {
        return false;
    }
    return await res.json();
};
// export const handleSalesforceAuth = async (code, token) => {
//     if (!code) return false; //TODO: Hide all constants in env
//     const urlParams = new URLSearchParams();
//     urlParams.set("code", code);

//     const res = await fetch(
//         `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/salesforce?${urlParams.toString()}`,
//         {
//             method: "GET",
//             headers: {
//                 Authorization: token,
//             },
//         },
//     );
//     const resJSON = await res.json();
//     if (res.status !== 200) {
//         throw new Error("Something went wrong");
//     }
//     return resJSON;
// };
