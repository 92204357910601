import React, { useEffect, useState } from "react";
import ContactRow from "./ContactRow";
import TableHeader from "./TableHeader";
import Pagination from "@mui/material/Pagination";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import { PaginationItem } from "@mui/material";
import useProfile from "@/hooks/useProfile";
import LoadingSpinner from "../LoadingSpinner";

function ContactList({ selectedLetter }) {
    const { getKompassProfile } = useProfile();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const totalPages = Math.ceil(data?.noOfProfiles / itemsPerPage);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const res = await getKompassProfile({
                type: "all",
                userName: "",
                userId: "",
                url: "",
                initial: selectedLetter,
                skip: (currentPage - 1) * itemsPerPage,
            });
            console.log("This is result: ", res);
            setData(res.data);
            setLoading(false);
        };
        fetchData();
    }, [currentPage, selectedLetter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedLetter]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            {data?.noOfProfiles && data?.profiles && (
                <div className="flex sm:w-[560px] md:w-[700px] lg:w-[1000px] xl:w-[1400px] flex-col mx-auto mt-10 mb-20">
                    <header className="ml-4 sm:ml-0 text-base capitalize text-neutral-500 max-md:max-w-full">
                        {itemsPerPage * (currentPage - 1) + 1}-{Math.min(itemsPerPage * currentPage, data?.noOfProfiles)} of{" "}
                        {data?.noOfProfiles} contacts
                    </header>
                    {loading && <LoadingSpinner />}
                    {data?.profiles && !loading && (
                        <div className="hidden sm:mx-auto sm:flex w-[1400px] overflow-hidden sm:flex-wrap items-start bg-neutral-100 mt-6 rounded-tl-lg rounded-tr-lg border border-solid border-neutral-200 max-md:max-w-full">
                            <TableHeader title="Name" width="w-[270px]" />
                            <TableHeader title="Contact Info" width="w-[270px]" />
                            <TableHeader title="Job Title" width="w-[270px]" />
                            <TableHeader title="Company Name" width="w-[270px]" />
                            <TableHeader title="Location" width="w-[270px]" />
                        </div>
                    )}
                    {!loading && data?.profiles?.map((contact, index) => <ContactRow key={index} {...contact} />)}
                    <div className="flex w-full justify-center py-4 sm:py-8">
                        <Pagination
                            className="font-Outfit"
                            count={totalPages}
                            page={currentPage}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            renderItem={(item) => (
                                <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />
                            )}
                            sx={{
                                "& .MuiPaginationItem-text": {
                                    fontFamily: "Outfit",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: 1.43,
                                    color: "#090C05",
                                },
                                "& .MuiPaginationItem-text.Mui-selected": {
                                    color: "#fff",
                                    backgroundColor: "black",
                                    "&:hover": {
                                        backgroundColor: "gray",
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default ContactList;
