import ArrowLeftIcon from "@/assets/image/icons/Arrow_Left.svg";
import useKompassRouter from "@/hooks/useKompassRouter";

export default function LeftPannel({ titles, scrollToContent }) {
    const { kompassNavigate } = useKompassRouter();

    return (
        <div className="w-full flex flex-col gap-10 lg:sticky lg:top-[175px] 2xl:top-[195px] h-min bg-white z-[98]">
            <button
                onClick={() => {
                    kompassNavigate("/blog");
                }}
                className="w-full lg:w-[217px] font-Outfit flex gap-4 py-4 lg:py-2 lg:pl-4 border-b border-[#E6E6E6] lg:border lg:border-stone-950 lg:rounded-[8px] text-[16px] leading-[1.2] font-bold text-stone-950 "
            >
                <img src={ArrowLeftIcon} alt="ArrowLeftIcon" width={16} height={16} />
                <p>Back to Articles</p>
            </button>
            <div className="hidden lg:block">
                <p className="font-Outfit text-[26px] leading-[1.2] text-stone-950 mb-6">Table of contents</p>
                <div className="flex flex-col gap-4 font-Outfit text-[16px] leading-[1.4] opacity-70 text-stone-950">
                    {titles?.length > 0 &&
                        titles.map((title) => (
                            <button
                                key={title}
                                className="text-left hover:text-[#4873FA]"
                                onClick={() => {
                                    scrollToContent(title);
                                }}
                            >
                                {title}
                            </button>
                        ))}
                </div>
            </div>
        </div>
    );
}
