import DataAnalystImg1 from "@/assets/image/CSV_export/data_analytics_1.svg";
import DataAnalystImg2 from "@/assets/image/CSV_export/data_analytics_2.svg";
import DataAnalystImg3 from "@/assets/image/CSV_export/data_analytics_3.svg";

export default function DeliverPanel(props) {
    return (
        <div className="bg-white mx-auto px-6 py-20 max-w-[1021px] w-full flex flex-col gap-16">
            <div className="w-full flex flex-col xs:flex-row justify-center gap-12 lg:gap-24">
                <div className="flex flex-col gap-8 md:gap-16 items-center justify-center shrink-0 px-8 w-full h-auto py-8 xs:py-12 xs:w-[208px] md:w-[298px] border rounded-[10px] border-accents-blue custom-shadow">
                    <img src={DataAnalystImg1} alt="DataAnalystImg1" width={227} height={252} />
                    <p className="text-base font-medium text-stone-950 text-center">Simplified Use</p>
                </div>
                <div className="w-full my-auto space-y-4">
                    <div className="font-OutfitBold text-stone-950 text-4xl">Get control of your stale data instantly</div>
                    <div className="font-Outfit text-lg text-stone-950">
                        There is no need to set-up integrations or manage a complex CRM - our bulk enrichment platform enables any company
                        to get instant access to fresh data through a CSV upload.
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col-reverse xs:flex-row justify-center gap-12 lg:gap-24">
                <div className="w-full my-auto space-y-4">
                    <div className="font-OutfitBold text-stone-950 text-4xl">Reduce time to enrich large batches of records</div>
                    <div className="font-Outfit text-lg text-stone-950">
                        Speed up your time to enrich individual records, through the bulk enrichment that enables you to get fresh data for
                        1000s of records within minutes.
                    </div>
                </div>
                <div className="flex flex-col gap-8 md:gap-16 items-center justify-center shrink-0 px-8 w-full h-auto py-8 xs:py-12 xs:w-[208px] md:w-[298px] border rounded-[10px] border-accents-blue custom-shadow">
                    <img src={DataAnalystImg2} alt="DataAnalystImg2" width={227} height={252} />
                    <p className="text-base font-medium text-stone-950 text-center">Quick Turnaround Time</p>
                </div>
            </div>
            <div className="w-full flex flex-col xs:flex-row justify-center gap-12 lg:gap-24">
                <div className="flex flex-col gap-8 md:gap-16 items-center justify-center shrink-0 px-8 w-full h-auto py-8 xs:py-12 xs:w-[208px] md:w-[298px] border rounded-[10px] border-accents-blue custom-shadow">
                    <img src={DataAnalystImg3} alt="DataAnalystImg3" width={227} height={252} />
                    <p className="text-base font-medium text-stone-950 text-center">Transparent Pricing</p>
                </div>
                <div className="w-full my-auto space-y-4">
                    <div className="font-OutfitBold text-stone-950 text-4xl">Only pay for validated and enriched data</div>
                    <div className="font-Outfit text-lg text-stone-950">
                        Save thousands of dollars compared to other platforms that charge for stale data - KompassAI only charges for data
                        we’re able to find and verify to ensure you can have a high level of confidence while prospecting.
                    </div>
                </div>
            </div>
        </div>
    );
}
