import React from "react";

function Header() {
    return (
        <>
            <header className="w-full text-6xl mt-4 sm:mt-10 px-5 pb-6 font-semibold leading-none text-zinc-950 max-md:max-w-full max-md:text-4xl border-b border-b-neutral-200 sm:border-none">
                Person Directory
            </header>
        </>
    );
}

export default Header;
