import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "@/context/AuthContext";
import { APP_ROUTES, LOGIN_ROUTES, LANDING_ROUTES } from "@/consts/routes";
import { checkRoutesPermissions } from "@/utils/common";

export default function Router() {
    const { isGuest, userInfo } = useContext(AuthContext);

    const redirectPath = isGuest ? "/signin" : "/home";

    const AVAILABLE_ROUTES = checkRoutesPermissions({
        permission: userInfo?.permission,
        routes: APP_ROUTES,
    });

    return (
        <Routes>
            {/* Redirect user if he get a wrong path */}
            <Route path="*" element={<Navigate replace to={redirectPath} />} />

            {Object.keys(LANDING_ROUTES).map((key) => {
                const { Component, path, props } = LANDING_ROUTES[key];
                return (
                    // You can wrap your pages into Layout here
                    <Route key={key} path={path} element={<Component {...props} />} />
                );
            })}

            {Object.keys(LOGIN_ROUTES).map((key) => {
                const { Component, path, props } = LOGIN_ROUTES[key];
                return (
                    // You can wrap your pages into Layout here
                    <Route key={key} path={path} element={<Component {...props} />} />
                );
            })}

            {Object.keys(AVAILABLE_ROUTES).map((key) => {
                const { Component, path, props } = AVAILABLE_ROUTES[key];
                return (
                    // You can wrap your pages into Layout here
                    <Route key={key} path={path} element={<Component {...props} />} />
                );
            })}
        </Routes>
    );
}
