import useKompassRouter from "@/hooks/useKompassRouter";
import CardButton from "../button/CardButton";
import slugify from "slugify";

export default function MainCard({ data }) {
    const { kompassNavigate } = useKompassRouter();

    const slugifyOptions = {
        lower: true,
        strict: true,
        remove: /[:]/g,
    };

    return (
        <div className="my-10 md:my-20 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 justify-between">
            <div className="rounded-[30px] overflow-hidden">
                <img src={data?.attributes?.postCover?.data?.attributes?.url} alt="BlogImage" className="w-full" />
            </div>
            <div className="flex flex-col gap-8 lg:gap-10">
                <div className="flex flex-col gap-4 text-stone-950">
                    <div>
                        <CardButton children="data analytics" />
                    </div>
                    <p className="font-Outfit text-[28px] lg:text-[40px] xl:text-[46px] leading-[1.2] font-bold text-start">
                        {data?.attributes?.postTitle}
                    </p>
                    {data?.attributes?.postSubtitle && (
                        <p className="font-Outfit text-[14px] lg:text-[18px] xl:text-[20px] leading-[1.4] text-start">
                            {data.attributes.postSubtitle}
                        </p>
                    )}
                </div>
                <div>
                    <button
                        onClick={() => {
                            kompassNavigate(`/blog/${data?.id}/${slugify(data?.attributes?.postTitle, slugifyOptions)}`);
                        }}
                        className="font-Outfit normal-case text-base font-bold leading-[1.2] border rounded-[8px] bg-white border-stone-950 text-stone-950 px-6 py-4"
                    >
                        Read More
                    </button>
                </div>
            </div>
        </div>
    );
}
