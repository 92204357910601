import { goToTop } from "@/utils/common";
import { useNavigate } from "react-router-dom";

const useKompassRouter = () => {
    const navigate = useNavigate();

    const kompassNavigate = (path) => {
        navigate(path);
        goToTop();
    };

    return {
        kompassNavigate,
    };
};

export default useKompassRouter;
