import useAuth from "@/hooks/useAuth";

const useIPsDeanonymization = () => {
    const { getAuthToken } = useAuth();

    const getWebsiteVisitors = async (params) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/enriched-data?${params?.query}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const getAnalytics = async (params) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/analytics?${params?.query}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const updateAllowedDomains = async (params) => {
        // console.log("===============updateAllowedDomains===============");
        const authToken = await getAuthToken();
        let response = null;
        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(params),
            });

            response = await _resData.json();
            if (response.status) {
                response = response.message;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const getAvailableCredits = async () => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/credits`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            response = await _resData.json();
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const allocateCredits = async (params) => {
        // console.log("===============enrichIP===============");

        const authToken = await getAuthToken();
        let response = null;
        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/ip-addresses/credits`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(params),
            });

            response = await _resData.json();
            if (!response.status) {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    return {
        getWebsiteVisitors,
        getAnalytics,
        updateAllowedDomains,
        getAvailableCredits,
        allocateCredits,
    };
};

export default useIPsDeanonymization;
