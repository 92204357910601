import React, { useState } from "react";
import LetterBox from "./LetterBox";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

function AlphabetGrid({ selectedLetter, setSelectedLetter }) {
    const handleLetterClick = (letter) => {
        setSelectedLetter(letter);
        console.log(`${letter} clicked`);
    };

    return (
        <main className="mt-20 mb-28 flex flex-col justify-center items-center text-xl text-center whitespace-nowrap text-neutral-500">
            <section className="grid grid-cols-5 gap-2 sm:gap-8 sm:flex sm:flex-wrap sm:w-4/5 justify-center items-center w-11/12">
                {alphabet.map((letter) => (
                    <LetterBox
                        key={letter}
                        letter={letter}
                        isHighlighted={letter === selectedLetter}
                        onClick={() => handleLetterClick(letter)}
                    />
                ))}
            </section>
        </main>
    );
}

export default AlphabetGrid;
