import { useState } from "react";
import ActionButtons from "./ActionButtons";
import CompanySize from "@/assets/image/icp-filters/headcount.svg";
import AreaChart from "@/assets/image/icp-filters/corporate_fare.svg";
import Location from "@/assets/image/icp-filters/location_on.svg";
import useICPFilters from "@/hooks/useICPFilters";
import { AccordianComponent } from "@/components/search/AccordianComponents";
import FilterAutocomplete from "@/components/common/FilterAutocomplete";
import { toast } from "react-toastify";
import DepartmentIcon from "@/assets/image/icp-filters/department.svg";
import BuildIcon from "@/assets/image/icp-filters/job_level.svg";
import { departmentList, industryList, levelList, numberOfEmployeesList } from "@/utils/filter";
import { ReactComponent as EditIcon } from "@/assets/image/edit.svg";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import AutocompleteInput from "@/components/common/AutocompleteInput";

export default function ICPFilter({ iCPFilters, setICPFilters }) {
    const [filterName, setFilterName] = useState("");
    const { createICPFilter } = useICPFilters();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const {
        jobDepartment,
        setJobDepartment,
        jobLevel,
        setJobLevel,
        numberOfEmployees,
        setNumberOfEmployees,
        companyIndustry,
        setCompanyIndustry,
        companyLocation,
        setCompanyLocation,
    } = useKompassFilters();

    const saveICPFilter = async () => {
        setIsButtonDisabled(true);
        if (!filterName) {
            toast.error("Please indicate filter name!");
            return;
        }
        if (numberOfEmployees?.length <= 0 && !companyIndustry && companyLocation?.length <= 0 && !jobDepartment && !jobLevel) {
            toast.error("Please choose at least one filter!");
            return;
        }

        const response = await createICPFilter({
            name: filterName,
            size: numberOfEmployees,
            industry: companyIndustry,
            location: companyLocation,
            department: jobDepartment,
            level: jobLevel,
        });

        if (response) {
            toast.success("New ICP Added successfully");
            const tempFilters = iCPFilters;
            tempFilters.push({
                name: filterName,
                size: numberOfEmployees,
                industry: companyIndustry,
                location: companyLocation,
                department: jobDepartment,
                level: jobLevel,
            });
            setICPFilters(tempFilters);
            discardChanges();
        } else {
            toast.error("Failed to add new ICP");
        }
        setIsButtonDisabled(false);
    };

    const discardChanges = () => {
        setFilterName("");
        setJobDepartment(null);
        setJobLevel(null);
        setNumberOfEmployees([]);
        setCompanyIndustry(null);
        setCompanyLocation([]);
    };

    return (
        <div className="font-Outfit p-[24px] flex flex-col gap-12">
            <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Edit your ICP filter</h2>
            <div className="flex flex-col gap-10 md:max-w-[1096px]">
                <div className="flex flex-col w-full gap-1">
                    <label htmlFor="filterName" className="flex gap-1 text-[14px] xl:text-[16px] leading-[1.2] text-[#090C05]">
                        <EditIcon />
                        <p>Name</p>
                    </label>
                    <input
                        id="filterName"
                        type="text"
                        className="p-4 w-full border border-[#E7E7E7] text-[14px] rounded-[8px] text-[#090C05] placeholder:text-[#B0B0B0]"
                        required
                        aria-required="true"
                        value={filterName}
                        placeholder="Give your filter a name"
                        onChange={(e) => {
                            setFilterName(e?.target?.value);
                        }}
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-10 w-full">
                    <div className="w-full">
                        <p className="mb-6 text-[#888] text-[14px] xl:text-[16px] leading-[1.2]">Company Filters</p>
                        <div className="border rounded-[8px] border-[#E7E7E7]">
                            <div className="border-b border-[#E7E7E7]">
                                <AccordianComponent
                                    title="Headcount"
                                    icon={CompanySize}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && country?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            value={numberOfEmployees}
                                            options={numberOfEmployeesList}
                                            onFilterChange={(value) => setNumberOfEmployees(value)}
                                            showExcludeButton={false}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            <div className="border-b border-[#E7E7E7]">
                                <AccordianComponent
                                    title="Industry"
                                    icon={AreaChart}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && country?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            value={companyIndustry}
                                            options={industryList}
                                            onFilterChange={(value) => setCompanyIndustry(value)}
                                            showExcludeButton={false}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            <div>
                                <AccordianComponent title="Company Location" icon={Location} isAddBorder={true}>
                                    <div className="mt-1 flex w-full flex-col gap-1 !capitalize">
                                        <AutocompleteInput
                                            value={companyLocation}
                                            multiple={true}
                                            searchBy="location"
                                            onNameFilterChange={(location) => setCompanyLocation(location?.name || location || "")}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="mb-6 text-[#888] text-[14px] xl:text-[16px] leading-[1.2]">Job Filters</p>
                        <div className="border rounded-[8px] border-[#E7E7E7]">
                            <div className="border-b border-[#E7E7E7]">
                                <AccordianComponent
                                    title="Job Level"
                                    icon={BuildIcon}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && level?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            value={jobLevel}
                                            options={levelList}
                                            onFilterChange={(value) => setJobLevel(value)}
                                            showExcludeButton={false}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                            <div>
                                <AccordianComponent
                                    title="Department"
                                    icon={DepartmentIcon}
                                    isAddBorder={false}
                                    // expand={isFromSavedFiltersPage && jobDepartment?.length}
                                >
                                    <div className="mt-1 w-full">
                                        <FilterAutocomplete
                                            multiple={true}
                                            isAddBorder={true}
                                            value={jobDepartment}
                                            options={departmentList}
                                            onFilterChange={(value) => setJobDepartment(value)}
                                            showExcludeButton={false}
                                        />
                                    </div>
                                </AccordianComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <ActionButtons saveICPFilter={saveICPFilter} discardChanges={discardChanges} isDisabled={isButtonDisabled} />
            </div>
        </div>
    );
}
