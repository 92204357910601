import BusinessLeaderPanel from "@/components/email_verification/BusinessLeaderPanel";
import BestValidatorPanel from "@/components/email_verification/BestValidatorPanel";
import CompliancePanel from "@/components/email_verification/CompliancePanel";
import Product from "@/components/prospecting/Products";
import LandingLayout from "@/components/LandingLayout";
import SupportPanel from "@/components/landing/SupportPanel";
import WhyKompassAIPanel from "@/components/landing/WhyKompassAIPanel";
import TopBar from "@/components/landing/TopBar";
import ConnectPanel from "@/components/landing/ConnectPanel";
import { Helmet } from "react-helmet";

const topBarData = {
    tag: "Email Verification",
    title: "Bulk validate emails quickly and cheaply",
    subTitle:
        "Invalid emails can impact your sender reputation and lead ISPs to reject your email. Protect your email reputation score and avoid being flagged as spam.",
};

const description = {
    heading: "What is the KompassAI email verification tool?",
    subHeading:
        "We employ a multi-level validation workflow to cross-reference multiple databases to ensure the validity of an email, enabling us to provide email verification services with industry-leading accuracy rates.",
};

export default function EmailVerificationScene() {
    return (
        <>
            <Helmet>
                <title>Email Verification | Kompass AI</title>
                <link rel="canonical" href="https://kompassai.com/emailverification" />
            </Helmet>
            <LandingLayout pageName="emailverification" background="#F1A9FF">
                <div>
                    <TopBar data={topBarData} background="bg-accents-purple" productName="emailverification" />
                    <SupportPanel title={false} />
                    <WhyKompassAIPanel data={description} />
                    <BusinessLeaderPanel />
                    <BestValidatorPanel />
                    <CompliancePanel />
                    <Product pageName="emailverification" />
                    <ConnectPanel background="bg-accents-purple" accent="bg-accents-hotPurple" />
                </div>
            </LandingLayout>
        </>
    );
}
