import useAuth from "@/hooks/useAuth";

const usePlan = () => {
    const { getAuthToken } = useAuth();

    const getPlanPreview = async (body) => {
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/billing/getPlanPreview`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    return {
        getPlanPreview,
    };
};

export default usePlan;
