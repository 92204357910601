import SwitchButton from "@/components/common/SwitchButton";
import SupportPanel from "@/components/landing/SupportPanel";
import { useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import MainLayout from "@/components/MainLayout";
import { useQuery } from "@tanstack/react-query";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import FullPricingBlock from "@/components/pricing/FullPricingBlock";
import BaseContainer from "@/components/BaseContainer";
import MainLoader from "@/components/MainLoader";

export default function UpgradeScene() {
    const [annual, setAnnual] = useState(false);
    const { ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: "debounce",
        refreshRate: 1000,
    });
    const subscriptionInfoFunction = useHTTPRequest(`/billing/getSubscriptionInfo`);

    const tariffsInfoFunction = useHTTPRequest(`/billing/getTariffs`);

    const { data: subscriptionInfo, isLoading: isLoadingSubscription } = useQuery({
        queryKey: ["Subscription"],
        queryFn: () => subscriptionInfoFunction(),
    });
    const { data: tariffsList, isLoading: isLoadingTariffs } = useQuery({
        queryKey: ["Tariffs"],
        queryFn: () => tariffsInfoFunction(),
    });

    const loading = isLoadingSubscription || isLoadingTariffs;

    return (
        <MainLayout>
            {loading && <MainLoader />}
            {!loading && (
                <>
                    <BaseContainer>
                        <div ref={ref} className="mt-[18px] flex w-full flex-col gap-2.5 items-center md:mt-[112px] mb-16">
                            <span className="font-OutfitBold px-4 text-center text-[4rem] text-stone-950 leading-[4.4rem]">
                                Select a plan
                            </span>
                            <span className="font-Outfit px-4 text-center text-[1.625rem] leading-[1.95rem] text-gray-950 opacity-70">
                                Reach millions of decision makers and their teams
                            </span>
                            <SwitchButton switchState={[annual, setAnnual]} />
                        </div>
                        <div className="w-full mb-20">
                            <FullPricingBlock subscriptionInfo={subscriptionInfo?.data} annual={annual} tariffList={tariffsList?.data} />
                        </div>
                    </BaseContainer>
                    <SupportPanel title={false} />
                </>
            )}
        </MainLayout>
    );
}
