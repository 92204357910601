import * as React from "react";
import { Button } from "@material-tailwind/react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { CircularProgress, TextField } from "@mui/material";
import FilterDropdown from "@/components/common/FilterDropdown";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { toast } from "react-toastify";
import useKompassSearch from "@/hooks/useKompassSearch";
import useList from "@/hooks/useList";
import { CONTACT_LIST_TYPE } from "@/utils/constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
    },
}));

export default function SaveToListDialog({ open, close, params }) {
    const { postSaveKompassSearchContacts } = useKompassSearch();
    const { createContactList } = useList();
    const { listData, contactList } = params;
    const [listTitle, setListTitle] = React.useState(null);
    const [newListTitle, setNewListTitle] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const onSave = async () => {
        if (listTitle == null || listTitle?.length === 0) {
            toast.error("Please select a list or create a new one.", {
                theme: "colored",
            });
            return;
        }

        setSaving(true);
        const result = await postSaveKompassSearchContacts({
            listTitle,
            listData,
        });
        setSaving(false);

        if (result.status) {
            toast.success(result.message, { theme: "colored" });
            setListTitle(null);
            close(true);
        } else toast.error(result.message, { theme: "colored" });
    };

    const onSaveToNewList = async () => {
        if (newListTitle.trim().length === 0) {
            toast.error("Please enter the new list name.", { theme: "colored" });
            return;
        }

        setSaving(true);
        const createListResult = await createContactList({
            listTitle: newListTitle,
            listType: CONTACT_LIST_TYPE.KOMPASS_SEARCH,
        });
        if (createListResult.status) {
            toast.success(`"${newListTitle}" Contact List has been created.`, {
                theme: "colored",
            });
            const saveToListResult = await postSaveKompassSearchContacts({
                listTitle: newListTitle,
                listData,
            });
            if (saveToListResult.status) {
                toast.success(saveToListResult.message, { theme: "colored" });
                setNewListTitle(null);
                close(true);
            } else {
                toast.error(saveToListResult.message, { theme: "colored" });
            }
        } else {
            toast.error(createListResult.message, { theme: "colored" });
        }
        setSaving(false);
    };

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={close}>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className="text-[#090c05] text-[18px] font-semibold font-['Outfit'] capitalize">Select list</div>
                <button onClick={close} className=" flex flex-row justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ padding: 0 }}>
                <div className="flex flex-col justify-start items-start p-6 border-b border-[#e7e7e7]">
                    <div className="text-[#050505] text-[14px] font-normal font-['Outfit'] pb-1">List names</div>
                    <div className="min-w-[400px]">
                        <FilterDropdown
                            options={
                                contactList
                                    ? contactList.map((contact) => {
                                          return {
                                              label: contact.listTitle,
                                              value: contact.listTitle,
                                          };
                                      })
                                    : [{ label: "123123" }]
                            }
                            onChange={(value) => {
                                setListTitle(value);
                            }}
                        />
                    </div>
                    <div className="text-[#050505] text-[14px] font-normal font-['Outfit'] mt-10">Create new list</div>
                    <div className="mt-1 min-w-[400px]">
                        <TextField
                            className="w-full"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                    fontSize: "16px",
                                    fontFamily: "Outfit",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "1px",
                                },
                                "& .MuiOutlinedInput-input": {
                                    fontSize: "16px",
                                    padding: "16px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                },
                            }}
                            placeholder="List name"
                            value={newListTitle}
                            onChange={(e) => {
                                setNewListTitle(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-row px-6 py-4 gap-4 justify-end">
                    <Button
                        className="w-[80px] px-3 py-1.5 border border-[#e7e7e7] text-sm text-center font-normal text-stone-950 capitalize rounded-[8px] font-['Outfit'] bg-white 2xl:px-2"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={saving}
                        className="w-[80px] px-3 py-1.5 text-sm text-center font-normal text-white capitalize rounded-[8px] font-['Outfit'] bg-stone-950 2xl:px-2"
                        onClick={newListTitle ? onSaveToNewList : onSave}
                    >
                        {saving ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                        Save
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
