const data = [
    {
        percentage: "90%",
        height: "h-[440px]",
        bgColor: "bg-[#0D0D0D]",
        textColor: "text-[#0D0D0D]",
        title: "KompassAI",
    },
    {
        percentage: "81%",
        height: "h-[395px]",
        bgColor: "bg-[#FFDE38]",
        textColor: "text-[#FFDE38]",
        title: "Competitor 1",
    },
    {
        percentage: "77%",
        height: "h-[361px]",
        bgColor: "bg-[#4873FA]",
        textColor: "text-[#4873FA]",
        title: "Competitor 2",
    },
    {
        percentage: "75%",
        height: "h-[350px]",
        bgColor: "bg-[#FFB44F]",
        textColor: "text-[#FFB44F]",
        title: "Competitor 3",
    },
    {
        percentage: "67%",
        height: "h-[310px]",
        bgColor: "bg-[#9F97F7]",
        textColor: "text-[#9F97F7]",
        title: "Competitor 4",
    },
];

const ProvidersRating = () => {
    return (
        <div className="px-12 max-w-[1190px] flex flex-col gap-5 mx-auto pb-10">
            <div className="w-full flex flex-nowrap py-6 items-end gap-2 sm:gap-4 md:gap-6 lg:gap-10 justify-between">
                {data.map((item) => (
                    <div className="w-full flex flex-col items-center">
                        <p className="mb-2.5 text-stone-350 leading-[1.2] text-[16px] sm:text-[20px] md:text-[24px] lg:text-[32px]">
                            {item.percentage}
                        </p>
                        <div className={`w-full shrink-0 ${item.bgColor} ${item.height} mb-4 rounded-[6px]`}></div>
                        <p
                            className={`whitespace-nowrap ${item.textColor} text-[10px] sm:text-[12px] md:text-[18px] lg:text-[22px] xl:text-[26px] font-medium leading-[1.4]`}
                        >
                            {item.title}
                        </p>
                    </div>
                ))}
            </div>
            <p className="text-[12px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[26px] leading-[1.2] text-stone-950 opacity-70">
                Note: Testing based on a randomized set of data enriched across each provider.
            </p>
        </div>
    );
};

export default ProvidersRating;
