import turnRight from "@/assets/image/arrow-right.png";
import { ReactComponent as MainLogo } from "@/assets/image/icons/logo.svg";
import { hexToRGB } from "@/utils/common";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useAiEngagement from "@/hooks/useAIEngagement";

export default function CalendarRedirect() {
    const [counter, setCounter] = useState(5);
    const [calendarLink, setCalendarLink] = useState("#");
    const [searchParams] = useSearchParams();
    const { updateClickData } = useAiEngagement();

    const updateClick = async (trackingId) => await updateClickData(trackingId);

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    useEffect(() => {
        const link = searchParams.get("calendarLink");
        if (link) setCalendarLink(link);
        const trackingId = searchParams.get("id");
        updateClick(trackingId);
    }, []);

    if (counter === 0) {
        window.location.href = calendarLink;
    }
    return (
        <div className="w-full h-screen bg-slate-100">
            <Box
                sx={{
                    backgroundColor: hexToRGB("#FFFFFF", 100) + " !important",
                }}
                className={"h-[75px] z-[100] w-full px-12 max-lg:px-4 sticky left-0 top-0 content-center"}
            >
                <Link to="/" className="flex justify-center">
                    <MainLogo />
                </Link>
            </Box>
            <div className="container flex items-center mt-12 font-Outfit justify-center h-auto flex-1 flex-col mx-auto my-auto rounded-lg shadow-lg w-96 px-2 py-6 bg-slate-50">
                <div className="flex justify-center overflow-hidden">
                    <img src={turnRight} alt="Costbo logo" width={85} className="calendar-rediect" />
                </div>
                <div className="flex flex-col">
                    <div className="text-center text-lg mt-5 mb-4">Hang Tight !</div>
                    <div className="flex flex-col items-center text-base">
                        You're being redirected to another<p> page, in {counter} secs</p>
                    </div>
                </div>
                <div className="mt-8">
                    <CircularProgress size="32px"></CircularProgress>
                </div>
            </div>
            <div className="absolute bottom-0 mb-2 ml-2 text-blue-400">
                <a href="https://www.flaticon.com/free-icons/user-interface" title="user-interface icons">
                    User-interface icons created by See Icons - Flaticon
                </a>
            </div>
        </div>
    );
}
