import BaseContainer from "../../components/BaseContainer";

const datas = [
    {
        title: "Automatic Email Validations",
        content:
            "Email verification is automatic through our LinkedIn prospecting tool. Automatically validate emails at the same time you prospect on LinkedIn.",
    },
    {
        title: "Validate on any budget",
        content: "Accurately verify your emails with reasonable pricing plans that don’t break the bank!",
    },
    {
        title: "Validate emails in bulk",
        content: "Upload your bulk email list in any file format (CSV, Excel, ODS, etc.).",
    },
];
export default function BusinessLeaderPanel(props) {
    return (
        <div className="bg-stone-50">
            <BaseContainer width="1680">
                <div className="w-full p-10">
                    <div className="w-full">
                        <div className="w-[12rem] font-Outfit flex items-center justify-center py-1 text-md rounded-full text-stone-950 bg-accents-purple">
                            Business Leader
                        </div>
                        <div className="w-full flex flex-col 2xl:flex-row justify-between items-end">
                            <div className="w-full font-Outfit text-[28px] lg:text-[40px] xl:text-[46px] text-stone-950 font-semibold mt-6 leading-[1.2]">
                                A faster, more efficient way
                                <br />
                                to validate emails
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row justify-center bg-stone-50 mt-[40px] md:mt-[40px]">
                        <div className="w-full flex flex-col md:flex-row gap-4">
                            {datas.map((data, index) => {
                                return (
                                    <div
                                        className="w-full md:w-1/3 flex flex-col rounded-3xl border border-stone-250 bg-white p-12"
                                        key={index}
                                    >
                                        <div className="w-full text-[28px] lg:text-[40px] xl:text-[46px] text-stone-950 font-Outfit font-[1000]">
                                            {index + 1}
                                        </div>
                                        <div className="w-full text-[20px] xl:text-[28px] text-stone-950 font-OutfitBold mt-8">
                                            {data.title}
                                        </div>
                                        <div className="h-full flex flex-col justify-between">
                                            <div className="w-full text-md text-stone-950 font-Outfit font-[400] mt-4">{data.content}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </BaseContainer>
        </div>
    );
}
