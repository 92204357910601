import React, { useState, useEffect } from "react";
import LandingHeader from "../components/LandingHeader";
import LandingFooter from "../components/LandingFooter";

const LandingLayout = ({ children, pageName, background = "transparent", inverse = false, isMain = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 5) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleOpen = () => {
        setIsOpen(true);
        document.body.style.overflow = "hidden";
    };

    const handleClose = () => {
        setIsOpen(false);
        document.body.style.overflow = "";
    };

    return (
        <main className={`relative m-auto min-h-screen ${pageName}`}>
            <LandingHeader
                backColor={background}
                invertColor={inverse}
                isOpen={isOpen}
                handleOpen={handleOpen}
                isSticky={isSticky}
                handleClose={handleClose}
            />
            <div className="w-full">{children}</div>
            <LandingFooter />
        </main>
    );
};
export default LandingLayout;
