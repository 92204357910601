import React from "react";
import { formatHour } from "@/utils/common";

function ScheduleDetails({ name, email, emailDays, emailStartHour, emailEndHour, campaignStartDate }) {
    const formatDateString = (dateString) => {
        const date = new Date(dateString);

        const options = { weekday: "long", year: "numeric", month: "short", day: "2-digit" };

        const formattedDate = date.toLocaleDateString("en-US", options).replace(",", "");
        return formattedDate;
    };
    const details = [
        { label: "To:", value: name, extra: email },
        { label: "Frequency:", value: emailDays.join(", ") },
        { label: "Time:", value: `${formatHour(emailStartHour)} to ${formatHour(emailEndHour)}` },
        { label: "Start Date:", value: formatDateString(campaignStartDate) },
    ];

    const truncateEmail = (email) => (email.length > 15 ? `${email.slice(0, 15)}...>` : email);

    return (
        <>
            {details.map((detail, index) => (
                <div key={index} className="flex gap-2 items-center mt-6">
                    <span className="self-stretch my-auto text-zinc-500">{detail.label}</span>
                    <div className="flex gap-2 items-center self-stretch my-auto">
                        <span className="self-stretch my-auto text-zinc-950">{detail.value}</span>

                        {detail.extra && (
                            <>
                                <span className="self-stretch my-auto text-zinc-500 sm:hidden">&lt;{truncateEmail(detail.extra)}</span>
                                <span className="self-stretch my-auto text-zinc-500 hidden sm:inline">&lt;{detail.extra}&gt;</span>
                            </>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
}

export default ScheduleDetails;
