import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Button } from "@material-tailwind/react";

export const AdvancedFilters = ({ open, onClose, children }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" className="!rounded-xl">
            <DialogTitle sx={{ m: 0, p: 2, fontFamily: "Outfit" }} className="!text-xl !font-semibold" id="customized-dialog-title">
                Advanced Search Filters
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ padding: 0 }}>
                {children}
            </DialogContent>
            <DialogActions>
                <div className="w-full flex justify-center bg-white">
                    <Button
                        onClick={onClose}
                        className="font-OutfitMedium m-2 w-full max-w-[500px] rounded-md bg-stone-950 px-2 py-2 text-base font-normal capitalize text-white "
                    >
                        Save Filters
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
