import React from "react";

function Recipient({ name, email }) {
    const truncatedEmail = email.length > 10 ? `${email.slice(0, 15)}...` : email;

    return (
        <div className="flex gap-2 items-center self-start mt-6">
            <div className="self-stretch my-auto text-zinc-500">To:</div>
            <div className="flex gap-2 items-center self-stretch my-auto">
                <div className="self-stretch my-auto text-zinc-950">{name}</div>
                <div className="self-stretch my-auto text-zinc-500">
                    <span className="sm:hidden">&lt;{truncatedEmail}&gt;</span>
                    <span className="hidden sm:inline">&lt;{email}&gt;</span>
                </div>
            </div>
        </div>
    );
}

export default Recipient;
