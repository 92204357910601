import Button from "@mui/material/Button";
import { ReactComponent as SearchIcon } from "@/assets/image/sidebar-icons/search-loop.svg";
import { useState } from "react";
import { BASE_BUTTON_STYLES, EMAIL_WARMUP_SEARCH_FILTERS } from "@/utils/common";
import classNames from "classnames";

export const SearchBar = ({
    handleOpenAddNewAccount,
    handleOpenDeleteAccount,
    handleFilterEmails,
    handleSortEmails,
    handleSearch,
    campaigns,
}) => {
    const [selectedFilter, setSelectedFilter] = useState("all");

    const baseFilterStyles = {
        fontFamily: "Outfit",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "1.4",
        borderRadius: "8px",
        textTransform: "capitalize",
        py: "7px",
        px: "12px",
        "@media (min-width:1536px)": {
            py: "10px",
            px: "16px",
            fontSize: "14px",
        },
    };

    return (
        <div className="flex items center justify-center gap-3">
            <div className="relative h-fit">
                <input
                    placeholder="Search email addresses"
                    onChange={handleSearch}
                    className="pl-[40px] pr-[16px] py-[6px] border border-stone-250 rounded-[40px] w-[300px] placeholder:text-stone-350 placeholder:text-[14px] font-light leading-[1.2]"
                />
                <SearchIcon className="absolute text-stone-350 w-[24px] h-[24px] transform -translate-y-1/2 top-1/2 left-[10px]" />
            </div>
            <div>
                <select
                    name="sortBy"
                    defaultValue="newest"
                    onChange={(e) => {
                        handleSortEmails(e.target.value);
                    }}
                    className="warmup-search-input min-w-[95px] px-[16px] py-[10px] text-stone-950 text-[14px] font-Outfit font-normal leading-[1.2] bg-stone-150 rounded-lg appearance-none cursor-pointer"
                >
                    <option disabled={true}>Sort by</option>
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="updated">Updated</option>
                </select>
            </div>
            <div className="flex">
                {EMAIL_WARMUP_SEARCH_FILTERS.map(({ id, label }) => (
                    <Button
                        key={id}
                        sx={{
                            ...baseFilterStyles,
                            color: selectedFilter === id ? "#F7F9FD" : "#4873FA",
                            backgroundColor: selectedFilter === id ? "#4873FA" : "transparent",
                            border: selectedFilter === id ? "1px solid #4873FA" : "none",
                            "& .warmup-filter-text": {
                                borderBottom: selectedFilter === id ? "none" : "1px solid transparent",
                            },

                            ":hover": {
                                backgroundColor: "transparent",
                                color: "#4873FA",
                                "& .warmup-filter-text": {
                                    borderBottomColor: selectedFilter === id ? "none" : "#4873FA",
                                },
                            },
                        }}
                        onClick={() => {
                            setSelectedFilter(id);
                            handleFilterEmails(id);
                        }}
                    >
                        <span
                            className={classNames("warmup-filter-text", {
                                "transition-colors": selectedFilter !== id,
                            })}
                        >
                            {label}
                        </span>
                    </Button>
                ))}
            </div>
            <div className="ml-auto flex items-center justify-center gap-2">
                {campaigns && campaigns.length > 0 && (
                    <Button
                        sx={{
                            ...BASE_BUTTON_STYLES,
                            backgroundColor: "transparent",
                            color: "#090C05",
                            ":hover": {
                                backgroundColor: "#090C05",
                                color: "#fff",
                            },
                        }}
                        onClick={handleOpenDeleteAccount}
                    >
                        Delete account
                    </Button>
                )}
                <Button
                    sx={{
                        ...BASE_BUTTON_STYLES,
                        backgroundColor: "#090C05",
                        color: "#fff",
                        ":hover": {
                            backgroundColor: "transparent",
                            color: "#090C05",
                        },
                    }}
                    onClick={() => {
                        handleOpenAddNewAccount();
                    }}
                >
                    Add New Account
                </Button>
            </div>
        </div>
    );
};
