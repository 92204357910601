import React, { useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const HtmlEditor = ({ value, onChange = () => {} }) => {
    const handleChange = (event, editor) => {
        const data = editor.getData();
        onChange(data);
    };

    return <CKEditor editor={ClassicEditor} data={value} onChange={(event, editor) => handleChange(event, editor)} />;
};

export default HtmlEditor;
