import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";
import ProductsFilterPopup, { productFilterOptions } from "../popup/ProductsFilterPopup";

export default function TotalCreditsUsedComponent({ creditsData = [], periodFilter, setPeriodFilter, productFilter, setProductFilter }) {
    const [anchorPeriodEl, setAnchorPeriodEl] = useState(false);
    const [anchorProductEl, setAnchorProductEl] = useState(false);
    const [openPeriodFilter, setOpenPeriodFilter] = useState(false);
    const [openProductFilter, setOpenProductFilter] = useState(false);

    useEffect(() => {
        const data = {
            data: creditsData?.map((item) => item.value),
            categories: creditsData?.map((item, i) => {
                // Show all labels if the period is "THIS_YEAR"
                if (periodFilter === "THIS_YEAR") return item.label;

                // If there are 8 or fewer items, show all labels
                if (creditsData.length <= 8) return item.label;

                // For 9 to 16 items, show every other label and the last one
                if (creditsData.length <= 16) {
                    return i % 2 === 0 || i === creditsData.length - 1 ? item.label : "";
                }

                // For more than 16 items, show every 4th label and the last one
                return i % 4 === 0 || i === creditsData.length - 1 ? item.label : "";
            }),
        };

        const chartOptions = {
            chart: {
                type: "column",
                spacingBottom: 30,
            },
            title: {
                text: "",
            },
            plotOptions: {
                column: {
                    color: "#9AB2FF",
                    borderRadius: 5,
                },
            },
            xAxis: {
                categories: data.categories,
                labels: {
                    style: {
                        color: "#929292",
                        fontSize: "0.775rem",
                        fontFamily: "Outfit",
                    },
                },
                lineColor: "#E8E7E7",
            },
            yAxis: {
                title: {
                    text: "",
                },
                gridLineColor: "#fff",
                tickColor: "#E8E7E7",
                labels: {
                    style: {
                        color: "#929292",
                        fontSize: "0.775rem",
                        fontFamily: "Outfit",
                    },
                },
                allowDecimals: false,
            },
            legend: { enabled: false },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: "Credits Used",
                    data: data.data,
                    color: "rgba(59, 130, 246, 0.7)",
                },
            ],
        };

        const chart = Highcharts.chart("totalCreditsUsedChart", chartOptions);

        return () => {
            chart.destroy();
        };
    }, [creditsData, creditsData.length]);

    return (
        <div className="flex w-full flex-col items-center justify-start gap-4 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
            <div className="inline-flex w-full shrink basis-0 flex-col items-start justify-start gap-4">
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                    <div className="font-Outfit shrink grow basis-0 text-md 2xl:text-lg font-normal text-black">Total Credits Used</div>

                    <div className="flex items-center justify-start gap-4">
                        <button
                            className="flex items-center justify-start gap-2"
                            onClick={(e) => {
                                setAnchorPeriodEl(e.currentTarget);
                                setOpenPeriodFilter((cur) => !cur);
                            }}
                        >
                            <div className="font-Outfit leading-tight text-sm 2xl:text-base font-normal text-black">
                                {filterOptions[periodFilter]}
                            </div>
                            <DownSVGIcon className="relative h-4 w-4" />
                        </button>
                    </div>
                    <div className="flex items-center justify-start gap-4">
                        <button
                            className="flex items-center justify-start gap-2"
                            onClick={(e) => {
                                setAnchorProductEl(e.currentTarget);
                                setOpenProductFilter((cur) => !cur);
                            }}
                        >
                            <div className="font-Outfit leading-tight text-sm 2xl:text-base font-normal text-black">{productFilter}</div>
                            <DownSVGIcon className="relative h-4 w-4" />
                        </button>
                    </div>
                </div>
                <div className="font-Outfit leading-tight text-sm font-normal text-neutral-400">Total credits used over time.</div>
                <div id="totalCreditsUsedChart" className="w-full "></div>
            </div>
            <FilterPopup
                anchorEl={anchorPeriodEl}
                open={openPeriodFilter}
                handleClose={(value) => {
                    if (value) {
                        setPeriodFilter(value);
                    }
                    setAnchorPeriodEl(null);
                    setOpenPeriodFilter(false);
                }}
            />
            <ProductsFilterPopup
                anchorEl={anchorProductEl}
                open={openProductFilter}
                handleClose={(value) => {
                    if (value) {
                        setProductFilter(productFilterOptions[value]);
                    }
                    setAnchorProductEl(null);
                    setOpenProductFilter(false);
                }}
            />
        </div>
    );
}
