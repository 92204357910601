import React, { useState, useEffect, useRef } from "react";
import ScheduledMessage from "./scheduleCard/ScheduledMessage";
import useScheduledEmails from "@/hooks/useScheduledEmails";
import useAIEngagement from "@/hooks/useAIEngagement";

function ScheduledEmails() {
    const { getScheduledEmails, getTargetContacts } = useScheduledEmails();
    const getScheduledEmailsRef = useRef();
    getScheduledEmailsRef.current = getScheduledEmails;

    const getTargetContactsRef = useRef();
    getTargetContactsRef.current = getTargetContacts;

    const { getCampaignData } = useAIEngagement();
    const getCampaignDataRef = useRef();
    getCampaignDataRef.current = getCampaignData;

    const [scheduledData, setScheduledData] = useState([]);

    const removeCard = (id) => {
        setScheduledData((prevCards) => prevCards.filter((card) => card.id !== id));
    };

    const handleEditEmail = (editedEmail) => {
        const updatedCards = scheduledData.map((card) => (card.id === editedEmail.id ? { ...card, ...editedEmail } : card));
        setScheduledData(updatedCards);
    };

    useEffect(() => {
        const fetchScheduledEmails = async () => {
            const scheduledEmails = await getScheduledEmailsRef.current();
            const mailCards = [];
            for (let i in scheduledEmails) {
                const card = {
                    id: scheduledEmails[i]._id,
                    body: scheduledEmails[i].body,
                    subject: scheduledEmails[i].subject,
                    scheduledDateUTC: scheduledEmails[i].scheduledDateUTC,
                };
                const response = await getCampaignDataRef.current(scheduledEmails[i].campaignId);
                card.emailDays = response?.data?.emailDays;
                card.emailStartHour = response?.data?.emailStartHour;
                card.emailEndHour = response?.data?.emailEndHour;
                card.campaignStartDate = response?.data?.campaignStartDate;
                let campaignContactsResponse = await getTargetContactsRef.current(scheduledEmails[i].campaignId);
                let allCampaignContacts = campaignContactsResponse.response;
                for (let j in allCampaignContacts) {
                    if (allCampaignContacts[j]._id === scheduledEmails[i].targetContactId) {
                        card.name = allCampaignContacts[j].fullName;
                        card.email = allCampaignContacts[j].primaryEmail;
                        break;
                    }
                }
                mailCards.push(card);
            }
            setScheduledData(mailCards);
        };
        fetchScheduledEmails();
    }, []);

    return (
        <div>
            {scheduledData.map((data) => (
                <ScheduledMessage key={data.id} data={data} onCardRemove={removeCard} handleEditEmail={handleEditEmail} />
            ))}
        </div>
    );
}

export default ScheduledEmails;
