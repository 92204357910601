import AllTabs from "@/components/ai_engagement/AllTabs";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import TargetCustomerPersona from "@/components/ai_engagement/campaignsTab/TargetCustomerPersona";
import CampaignPitch from "@/components/ai_engagement/campaignsTab/CampaignPitch";
import CampaignSequence from "@/components/ai_engagement/campaignsTab/CampaignSequence";
import CampaignSchedule from "@/components/ai_engagement/campaignsTab/CampaignSchedule";
import { useLocation } from "react-router-dom";
import useAIEngagement from "@/hooks/useAIEngagement";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const steps = ["1. Target Customer Persona", "2. Campaign Pitch", "3. Sequence", "4. Schedule"];

export default function CreateNewCampaign() {
    const [selectedStep, setSelectedStep] = useState(steps[0]);
    const [campaignId, setCampaignId] = useState(null);
    const [editCampaignData, setEditCampaignData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const swiperRef = useRef(null);

    const { getCampaignById } = useAIEngagement();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const editCampaignId = query.get("campaignId");

    useEffect(() => {
        const getEditCampaignData = async () => {
            if (editCampaignId) {
                setIsEdit(true);
                const res = await getCampaignById(editCampaignId);
                if (res.status) {
                    setEditCampaignData(res.data);
                    setCampaignId(editCampaignId);
                }
                setIsEdit(false);
            }
        };
        getEditCampaignData();
    }, [editCampaignId]);
    console.log(editCampaignData, "editCampaignData");

    // Effect to sync the swiper with the selected step
    useEffect(() => {
        if (swiperRef.current) {
            const index = steps.indexOf(selectedStep);
            swiperRef.current?.swiper?.slideTo(index, 500, false);
        }
    }, [selectedStep]);

    const handleBackClick = () => {
        const index = steps.indexOf(selectedStep);
        if (index > 0) {
            setSelectedStep(steps[index - 1]);
        }
    };

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white pb-10">
                <BaseContainer>
                    <AllTabs />
                    <div className="my-8 flex gap-x-4 text-sm items-center">
                        <Swiper ref={swiperRef} slidesPerView={"auto"} spaceBetween={10} className="!mx-0">
                            {steps.map((step, index) => (
                                <SwiperSlide className="!w-auto" key={index}>
                                    <p
                                        className={clsx(
                                            {
                                                "bg-stone-100": selectedStep === step,
                                            },
                                            "py-1 px-3 rounded font-medium text-sm"
                                        )}
                                    >
                                        {step}
                                    </p>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {steps.indexOf(selectedStep) > 0 && (
                            <button
                                onClick={handleBackClick}
                                className="rounded-lg border border-black px-4 py-1 bg-black text-white ml-auto flex items-center gap-1 hover:opacity-85"
                            >
                                <KeyboardBackspaceIcon className="w-4 h-4 mb-0.5" />
                                Back
                            </button>
                        )}
                    </div>
                    {/* Conditionally render components based on selected step */}
                    <div className={`${selectedStep === "1. Target Customer Persona" ? "block" : "hidden"}`}>
                        <TargetCustomerPersona
                            setSelectedStep={setSelectedStep}
                            editCampaignData={editCampaignData}
                            campaignId={campaignId}
                            setCampaignId={setCampaignId}
                            isEdit={isEdit}
                        />
                    </div>
                    <div className={`${selectedStep === "2. Campaign Pitch" ? "block" : "hidden"}`}>
                        <CampaignPitch editCampaignData={editCampaignData} setSelectedStep={setSelectedStep} campaignId={campaignId} />
                    </div>
                    <div className={`${selectedStep === "3. Sequence" ? "block" : "hidden"}`}>
                        <CampaignSequence
                            editCampaignData={editCampaignData}
                            setSelectedStep={setSelectedStep}
                            selectedStep={selectedStep}
                            campaignId={campaignId}
                        />
                    </div>
                    <div className={`${selectedStep === "4. Schedule" ? "block" : "hidden"}`}>
                        <CampaignSchedule editCampaignData={editCampaignData} campaignId={campaignId} setCampaignId={setCampaignId} />
                    </div>
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
