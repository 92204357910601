export const handleCRMPushData = async (method, service, profileType, selectedData, token) => {
    const urlParams = new URLSearchParams();
    urlParams.set("pushMethod", method);
    urlParams.set("profileType", profileType);
    let body = {};
    if (profileType === "profile_list" || profileType === "profile_search") {
        body = { data: selectedData };
    } else {
        body = { ids: selectedData };
    }
    const res = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/${service}/data?${urlParams.toString()}`, {
        method: "POST",
        headers: {
            Authorization: token,
        },
        body: JSON.stringify(body),
    });
    const resJSON = await res.json();
    return resJSON;
};
