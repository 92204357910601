import React, { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import useImage from "@/hooks/useImage";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DeleteIcon from "@mui/icons-material/Delete";

import ConfirmationModal from "@/components/payment/ConfirmationModal";

const modalPresets = {
    delete: {
        icon: <WarningRoundedIcon className={" !h-full !w-full !fill-white"} />,
        title: "Confirm deletion",
        message: "You are about to delete payment method, you may add it again later, proceed? ",
        footnotes: [
            "If you don't add active payment methods before next subscription payment, your subscription might be suspended",
            "If it was default payment method, another one will be chosen automatically",
        ],
    },
    setDefault: {
        icon: <WarningRoundedIcon className={" !h-full !w-full !fill-white"} />,
        title: "Confirm operation",
        message: "You are setting card as default payment method, this card will be used by default during future payments",
    },
};

function CardDetailsCard({ id, card, customer }) {
    const { last4, brand, exp_month, exp_year } = card;
    const def = customer?.invoice_settings?.default_payment_method === id;
    const { loading, image } = useImage(`banks/${brand}.svg`);
    const [over, setOver] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modal, setModal] = useState({});
    const queryClient = useQueryClient();

    const deleteFiles = useHTTPRequestObject({
        link: "/billing/removePaymentMethod",
        method: "DELETE",
    });

    const deleteMethodMutation = async (params) => {
        const res = await deleteFiles(JSON.stringify(params));
        return res;
    };
    const { mutateAsync: deleteMethodTrigger, isPending: pendingDeleteMethod } = useMutation({
        mutationFn: deleteMethodMutation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["PaymentMethods"] });
        },
    });
    const handleCardDelete = () => {
        setModal({
            ...modalPresets.delete,
            handleConfirm: handleConfirmCardDelete,
        });
        setModalShow(true);
    };
    const handleConfirmCardDelete = async () => {
        await deleteMethodTrigger({ cardId: id });
    };

    const setAsDefault = useHTTPRequestObject({
        link: "/billing/setPaymentMethodAsDefault",
        method: "POST",
    });
    const setAsDefaultMutation = async (params) => {
        const res = await setAsDefault(JSON.stringify(params));
        return res;
    };
    const { mutateAsync: setAsDefaultTrigger, isPending: pendingSetAsDefault } = useMutation({
        mutationFn: setAsDefaultMutation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["PaymentMethods"] });
        },
    });
    const handleSetDefault = () => {
        setModal({
            ...modalPresets.setDefault,
            handleConfirm: handleConfirmSettingAsDefault,
        });
        setModalShow(true);
    };
    const handleConfirmSettingAsDefault = async () => {
        await setAsDefaultTrigger({ cardId: id });
    };

    return (
        <div className="flex w-full items-center" onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
            {!loading && (
                <div className="flex w-full items-center rounded-lg bg-white px-5 py-3 transition hover:bg-stone-250">
                    <img className="mr-2 flex h-8 w-8 items-center justify-center" src={image} alt="" />
                    <div className="width-fit font-OutfitMedium ml-4 mr-auto flex items-center gap-4 text-lg">
                        {new Array(3).fill(0, 0, 3).map(() => {
                            return <span className="h-0 translate-y-[4px] leading-[0px]">****</span>;
                        })}
                        <span>{last4}</span>
                        {over && !def && (
                            <div
                                className=" group flex cursor-pointer items-center rounded-3xl border-1 border-black p-2 transition hover:bg-black"
                                onClick={handleSetDefault}
                            >
                                <span className="font-Outfit text-stone-450 text-center text-sm leading-[100%] group-hover:text-white">
                                    Set as default
                                </span>
                            </div>
                        )}
                        {def && <span className="color-grey-450 font-Outfit text-center text-sm leading-[100%] opacity-50">Default</span>}
                    </div>

                    <div></div>
                    <div className="flex items-center gap-2">
                        <div className="font-Outfit text-lg opacity-40">
                            {exp_month} / {exp_year}
                        </div>
                        <div className="flex items-center gap-1">
                            <div className="group cursor-pointer rounded-full p-2 transition hover:bg-white hover:transition">
                                <DeleteIcon className="group-hover:fill-red-500" onClick={handleCardDelete} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ConfirmationModal showState={[modalShow, setModalShow]} modal={modal} loading={pendingDeleteMethod || pendingSetAsDefault} />
        </div>
    );
}

export default CardDetailsCard;
