import { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import FileUploaderWidget from "@/components/email_validation/FileUploaderWidget";
import { ReactComponent as InformationSVG } from "@/assets/image/bulk/info.svg";
import StyledInput from "./StyledInput";
import { toast } from "react-toastify";
import { useHTTPRequest } from "@/hooks/useHTTPRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import { emailValidationTemplateHeader, parseStringCSVtoArray } from "@/utils/common";
import { useNavigate } from "react-router-dom";

function FileUploadValidationWidget({ setCurrentWidgetIndex, setLoading }) {
    const [attachedFileInfo, setAttachedFileInfo] = useState(null);
    const getUsageData = useHTTPRequest("/credits/getUserCreditsUsed?", "GET");
    const sendDataAsList = useHTTPRequest("/email-validation", "PUT", false, {
        // type: "file",
    });
    const navigate = useNavigate();
    const { mutateAsync: sendDataAsListMutation } = useMutation({
        mutationFn: sendDataAsList,
    });
    const { data: creditsData } = useQuery({
        queryKey: ["CreditsUsage"],
        queryFn: () => getUsageData(),
    });
    const initialValues = {
        file: {},
        fileName: "",
    };

    const handleFileContent = (fileName, fileContent) => {
        let attachedInfo = {
            name: fileName,
            content: fileContent,
        };

        const inputs = [];
        for (let i = 0; i < fileContent.length; i++) {
            if (emailValidationTemplateHeader !== fileContent[i][0] && fileContent[i][0] !== "") {
                inputs.push({ email: fileContent[i][0] });
            }
        }
        attachedInfo.content = inputs;
        setAttachedFileInfo(attachedInfo);
    };

    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .transform((v) => (!v.name ? undefined : v))
            .required("No file or it's broken")
            .test("Test file size", "File", (value, text) => {
                if (value.size >= 5000000) return false;
                return true;
            }),
        fileName: Yup.string().required("Please name the list").max(80, "List name is too long!"),
    });

    const handleUploading = async (values, evt) => {
        const res = await parseStringCSVtoArray(evt?.currentTarget?.result);
        if (creditsData?.data?.available <= res.length) {
            toast.error("You don't have enough email validation credits, please refill your credits");
            setLoading(false);
            return;
        }
        const { fileName } = values;

        await sendDataAsListMutation(
            JSON.stringify({
                emails: attachedFileInfo.content,
                fileName,
            })
        );
        toast.success("Successfully uploaded!");
        setLoading(false);
        setTimeout(() => {
            navigate("/bulkenrich");
        }, 2000);
    };

    const handleInitiateLoading = async (values) => {
        try {
            setLoading(true);
            var reader = new FileReader();
            reader.readAsText(values.file, "UTF-8");
            reader.onload = async function (evt) {
                await handleUploading(values, evt);
            };
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong, try again");
            return;
        }
    };

    useEffect(() => {
        setAttachedFileInfo(null);
    }, []);

    return (
        <>
            <div className="flex w-full  flex-col items-center justify-center">
                <div className=" flex w-full flex-col gap-8 ">
                    {/* <div className="font-Outfit flex flex-col text-left text-3xl font-bold">Email Validation</div>
                    <div className="flex gap-4 items-center">
                        <div className="flex-row items-center justify-center">
                            <BulkValidateSVG className="h-15 w-15" />
                        </div>
                        <div className="font-Outfit flex-col justify-between">
                            <div className="text-lg font-Outfit select-none text-left text-gray-950">Validate</div>
                            <div className="font-Outfit select-none text-left text-base leading-md text-gray-950">View results</div>
                        </div>
                    </div> */}
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleInitiateLoading}>
                        {({ setFieldValue, errors, handleChange }) => (
                            <Form className="flex flex-col gap-8">
                                <div className="flex flex-col rounded-tr-lg rounded-bl-lg rounded-br-lg border-[1px] border-[#E8E7E7] p-6 gap-8">
                                    <div className="flex flex-col gap-2">
                                        <span className="font-Outfit text-left text-[#050505] text-base font-normal font-['Outfit'] leading-tight">
                                            List name
                                        </span>
                                        <StyledInput
                                            name="fileName"
                                            error={errors["fileName"]}
                                            placeholder="e.g. My Emails"
                                            className="outline-none max-w-[428px] py-3 pl-4 pr-6 font-Outfit text-base w-full placeholder:text-stone-950 placeholder:opacity-40 placeholder:font-light border-[e7e7e7] border bg-white rounded-[8px]"
                                        ></StyledInput>

                                        <ErrorMessage
                                            name="fileName"
                                            component="div"
                                            className="font-Outfit leading-tight text-sm font-normal text-red-600"
                                        ></ErrorMessage>
                                    </div>
                                    <hr className="border-[#e7e7e7]"></hr>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-row justify-between">
                                            <span className="font-Outfit text-base font-normal text-gray-950">
                                                Or upload your file (Maximum 1000)
                                            </span>
                                            <div className="font-Outfit text-sm font-light text-gray-500">
                                                Uploader supports CSV and TXT files
                                            </div>
                                        </div>
                                        <FileUploaderWidget
                                            error={errors["file"]}
                                            onChange={(file) => setFieldValue("file", file)}
                                            attachedFileName={attachedFileInfo?.name}
                                            onFileContent={handleFileContent}
                                            name="file"
                                            reset={() => {
                                                setAttachedFileInfo(null);
                                            }}
                                        />
                                        <ErrorMessage
                                            name="file"
                                            component="div"
                                            className="font-Outfit leading-tight text-sm font-normal text-red-600"
                                        ></ErrorMessage>
                                        <div className="flex flex-row items-center gap-1">
                                            <InformationSVG />
                                            <span className="text-[#888888] text-xs font-normal font-['Outfit'] leading-none">
                                                If your file contains multiple columns, we’ll automatically detect the one containing the
                                                email addresses
                                            </span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <div className="h-[136px] p-2 bg-[#f6f6f6] rounded-lg flex-col justify-start items-start gap-2.5 inline-flex">
                                            <div className="flex-col justify-center items-start gap-3 flex">
                                                <div className="text-[#090c05] text-sm font-normal font-['Outfit'] leading-[16.80px]">
                                                    Note:{" "}
                                                </div>
                                                <div className="text-[#454545] text-xs font-light font-['Outfit']">
                                                    - You can only verify up to 200 email addresses per domain name every 24 hours.
                                                    <br />
                                                    - You can verify up to 50,000 email addresses per bulk.
                                                    <br /> - Additional rows will be skipped.
                                                    <br />
                                                    - Duplicate email addresses will be skipped.
                                                    <br />- File size max is 5 MB
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <hr></hr>
                                <div className="flex flex-row justify-between">
                                    <Button
                                        onClick={() => {
                                            setCurrentWidgetIndex(1);
                                        }}
                                        className="font-Outfit rounded-md border-[1px] border-[#000] bg-white px-6 py-2 font-medium text-base leading-md normal-case text-black"
                                    >
                                        Previous
                                    </Button>
                                </div> */}
                                <div className="flex flex-row mt-10 justify-start gap-4">
                                    <Button
                                        onClick={() => {
                                            setCurrentWidgetIndex(1);
                                        }}
                                        className="bg-white w-[110px] border border-[#d1d1d1] font-Outfit rounded-lg text-[#888888] text-base font-normal font-['Outfit'] leading-tight tracking-tight py-2 px-6 normal-case "
                                    >
                                        Go back
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="w-[110px] text-white bg-[#050505] font-Outfit rounded-lg py-2 px-6 normal-case font-normal text-base leading-tight"
                                    >
                                        Finish
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
}

export default FileUploadValidationWidget;
