import React from "react";
import { Button } from "@material-tailwind/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import BlueSwitch from "../team/BlueSwitch";
import { ReactComponent as LockSVGIcon } from "@/assets/image/icons/icon-lock.svg";
import { ReactComponent as PersonSVGIcon } from "@/assets/image/icons/icon-account-circle.svg";
import { ReactComponent as InfoSVGIcon } from "@/assets/image/icons/Info.svg";
import { Divider } from "@mui/material";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email is required"),
});

export default function AccountTabComponent() {
    const initialValues = {
        company_name: "",
        email: "anna.a@pinedev.eu",
        sso: true,
    };

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ setFieldValue, values }) => (
                <Form className="p-6">
                    <div className="mb-4 inline-flex w-full items-center justify-start gap-2.5">
                        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
                            <div className="font-Outfit leading-loose self-stretch text-3xl font-normal text-black">Account</div>
                            <div className="font-Outfit leading-tight self-stretch text-sm font-normal text-stone-350">
                                Manage your profile and preferences here.
                            </div>
                        </div>
                        <Button type="submit" className="flex items-center justify-center rounded-md bg-stone-950 px-3 py-2">
                            <div className="font-Outfit leading-tight text-sm font-bold uppercase tracking-tight text-white">
                                Save changes
                            </div>
                        </Button>
                    </div>

                    <div className="flex flex-col gap-4 ">
                        <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                            <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                                        <LockSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                                    </div>
                                </div>
                                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                                        Account info
                                    </div>
                                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                        Tell us a bit about your company
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col items-start justify-start gap-y-6">
                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">Company name</div>
                                        <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                            You will use it to login
                                        </div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                        <Field
                                            type="text"
                                            name="company_name"
                                            className="font-Outfit leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light text-black"
                                            placeholder=""
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="company_name"
                                        component="div"
                                        className="font-Outfit leading-tight text-sm font-normal text-red-500"
                                    />
                                </div>

                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">Contact email</div>
                                        <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                            Used to contact you regarding your account
                                        </div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                        <Field
                                            type="email"
                                            name="email"
                                            className="font-Outfit leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light text-black"
                                            placeholder="anna.a@pinedev.eu"
                                        />
                                    </div>
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="font-Outfit leading-tight text-sm font-normal text-red-500"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full flex-col items-center justify-start gap-2 rounded-xl border border-stone-250 bg-white p-6">
                            <div className="mb-8 flex w-full flex-row items-center gap-2.5">
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-150 p-2.5">
                                    <div className="relative flex h-5 w-5 flex-col items-start justify-start">
                                        <PersonSVGIcon className="relative h-5 w-5 fill-current text-blue-500" />
                                    </div>
                                </div>
                                <div className="inline-flex w-full shrink grow basis-0 flex-col items-start justify-start">
                                    <div className="font-Outfit leading-relaxed self-stretch text-xl font-normal text-black">
                                        Single Sign On
                                    </div>
                                    <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                        Enable SAML single sign on for your entire team
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col items-start justify-start gap-y-6">
                                <div className="flex w-full  flex-col items-start justify-center gap-2 lg:w-3/5">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">Email notifications</div>
                                        <div className="font-Outfit leading-tight text-right text-sm font-normal text-stone-350">
                                            Opt-in to various notifications
                                        </div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch rounded-3xl bg-white">
                                        <BlueSwitch />
                                    </div>
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="font-Outfit leading-tight text-sm font-normal text-red-500"
                                    />
                                </div>
                                <Divider className="w-full" />
                                <div className="flex flex-row items-center gap-2">
                                    <InfoSVGIcon className="relative h-5 w-5 fill-current text-stone-350" />
                                    <div className="font-'Outfit' leading-none text-xs font-normal text-stone-350">
                                        Please contact sales to enable this feature.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
