import * as React from "react";
import { Button } from "@material-tailwind/react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function NoMailBoxButton() {
    const navigate = useNavigate();

    return (
        <div className="flex justify-center items-center h-[500px]">
            <div className="p-7">
                <div className="font-Outfit p-3 text-xl font-bold text-black">Please add at least one Mailbox to proceed</div>
                <div className="font-Outfit mt-4 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                    <Button
                        className="font-Outfit flex items-center justify-center rounded-full border-[1px] bg-blue-500 hover:bg-blue-600 py-2 px-4 text-sm font-medium text-white whitespace-nowrap"
                        onClick={() => {
                            navigate("/ai-engagement/mailbox");
                        }}
                    >
                        Add Mailbox
                    </Button>
                </div>
            </div>
        </div>
    );
}
