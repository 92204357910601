import { ReactComponent as ExtensionSVGIcon } from "@/assets/image/home/icon-puzzel-piece.svg";
import { ReactComponent as PeoplesSVGIcon } from "@/assets/image/home/icon-peoples.svg";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../assets/image/icons/Arrow_Right.svg";
import { IconButton } from "@mui/material";

export default function SuggestedComponent({ teamsInfo, isTeamMember, navigate }) {
    return (
        <div className="flex w-full flex-col items-center justify-start gap-4 rounded-xl bg-stone-150 p-4 2xl:p-6">
            <div className="font-Outfit self-stretch text-md 2xl:text-lg text-stone-950">Suggested for you</div>
            <Link
                to="https://chromewebstore.google.com/detail/kompassai/imkmjfajnjfpfkdojdmabcphojonjjjf?hl=en&authuser=2"
                className="inline-flex items-center justify-start gap-2 2xl:gap-4 self-stretch"
            >
                <ExtensionSVGIcon className="h-8 w-8 fill-current text-stone-950" />
                <div className="shrink grow basis-0 flex-col">
                    <div className="text-base font-Outfit self-stretch font-medium text-stone-950">Install Chrome Extension</div>
                    <div className="font-Outfit self-stretch text-xs text-stone-350">Work with KompassAI across the web</div>
                </div>
                <img src={ArrowRightIcon} alt="Right arrow icon" width={24} height={24} />
            </Link>

            {!isTeamMember && (
                <>
                    <hr className="h-px w-full border-0 bg-stone-250" />

                    <Link to="/upgrade" className="inline-flex items-center justify-start gap-2 2xl:gap-4 self-stretch">
                        <PeoplesSVGIcon className="h-8 w-8 fill-current text-stone-950" />
                        <div className="shrink grow basis-0 flex-col">
                            <div className="text-base font-Outfit self-stretch font-medium text-stone-950">Buy more seats</div>
                            <div className="font-Outfit self-stretch text-xs text-stone-350">
                                You have {teamsInfo?.total - teamsInfo?.used || 0} seat in your account
                            </div>
                        </div>
                        <img src={ArrowRightIcon} alt="Right arrow icon" width={24} height={24} />
                    </Link>
                </>
            )}
        </div>
    );
}
