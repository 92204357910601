import React from "react";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { kompassColors } from "theme/palette";
import "./selectDropdown.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "white",

        borderRadius: 6,
        fontSize: 13,
        fontFamily: "Outfit",
        padding: "0px 26px 0px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 6,
            borderColor: kompassColors.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

export default function SelectDropdown({ options, selectedOption, onChange, defaultValue = null, renderValue, ...props }) {
    const handleOptionClick = (event) => {
        onChange(event.target.value);
    };

    return (
        <Select
            className="w-full"
            value={selectedOption}
            onChange={handleOptionClick}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            input={<BootstrapInput />}
            renderValue={renderValue}
            defaultValue={defaultValue}
            {...props}
        >
            {options.map((option) => (
                <MenuItem value={option?.value}>
                    <p className="text-[#b2b2b2] text-base font-normal font-['Outfit'] ">{option?.label}</p>
                </MenuItem>
            ))}
        </Select>
    );
}
