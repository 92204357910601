import LandingLayout from "@/components/LandingLayout";
import BaseContainer from "@/components/BaseContainer";
import { ReactComponent as TextDecorIcon } from "@/assets/image/icons/text-decor.svg";
import QuestionPanel from "@/components/landing/QuestionPanel";
import { Helmet } from "react-helmet";

export default function FAQScene() {
    return (
        <>
            <Helmet>
                <title>FAQ | Kompass AI</title>
                <link rel="canonical" href="https://kompassai.com/faq" />
            </Helmet>
            <LandingLayout pageName={"faq"}>
                <BaseContainer>
                    <div className="flex w-full flex-col items-center pt-12 md:pt-[3.75rem] md:pb-10">
                        <h1 className="mb-9 md:mb-4 px-4 py-2.5 flex items-center justify-center rounded-[40px] bg-[#D9D9D9] font-Outfit text-[#0D0D0D] text-base md:text-[20px] leading-[1.4] uppercase">
                            FAQ
                        </h1>
                        <h2 className="mb-12 md:mb-6 font-OutfitBold font-bold relative text-center text-[38px] md:text-[64px] leading-[1.3] md:leading-[1.1] text-stone-950">
                            Questions you might
                            <span className="relative inline-block ml-2">
                                have
                                <TextDecorIcon className="absolute -bottom-[10%] -right-[20%] md:-bottom-[10%] md:-right-[10%]" />
                            </span>
                        </h2>
                        <p className="hidden md:block font-Outfit text-center text-[26px] leading-[1.2] text-gray-950 opacity-70">
                            Discovering answers to common queries
                        </p>
                    </div>
                    <QuestionPanel isTitleShown={false} />
                </BaseContainer>
            </LandingLayout>
        </>
    );
}
