import React, { useRef, useImperativeHandle } from "react";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

const ScrollArea = React.forwardRef(({ className, children, ...props }, ref) => {
    const viewportRef = useRef(null);

    // Expose the viewport ref to the parent component using the main ref
    useImperativeHandle(ref, () => ({
        scrollToTop: () => {
            if (viewportRef.current) {
                viewportRef.current.scrollTo({ top: 0, behavior: "smooth" });
            }
        },
    }));

    return (
        <ScrollAreaPrimitive.Root className={`relative overflow-hidden ${className}`} {...props}>
            <ScrollAreaPrimitive.Viewport ref={viewportRef} className="h-full w-full rounded-[inherit]">
                {children}
            </ScrollAreaPrimitive.Viewport>
            <ScrollBar />
            <ScrollAreaPrimitive.Corner />
        </ScrollAreaPrimitive.Root>
    );
});
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef(({ className, orientation = "vertical", ...props }, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
        ref={ref}
        orientation={orientation}
        className="flex touch-none select-none transition-colors h-full w-2.5 border-l border-l-transparent p-[1px] mr-1"
        {...props}
    >
        <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-neutral-200" />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
