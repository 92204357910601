import { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { AuthContext } from "@/context/AuthContext";
import useIPsDeanonymization from "@/hooks/useIPsDeanonymization";
import { WEBSITE_VISITORS_REPORT_ITEMS, WEBSITE_VISITORS_PAGE_TABS, NoInfoAvailableVal } from "@/utils/constants";
import LeftBar from "@/components/webpage_visitors/LeftBar";
import WebsiteReport from "@/components/webpage_visitors/WebsiteReport";
import AccountDetails from "@/components/webpage_visitors/AccountDetails";
import NavigationTabs from "@/components/webpage_visitors/NavigationTabs";
import Analytics from "@/components/webpage_visitors/analytics/Analytics";
import ProfileExport from "@/components/webpage_visitors/profile_export/ProfileExport";
import ICPFilter from "@/components/webpage_visitors/target_profiles/ICPFilter";
import ExportButton from "@/components/webpage_visitors/profile_export/ExportButton";
import useICPFilters from "@/hooks/useICPFilters";
import useExportProfiles from "@/hooks/useExportProfiles";
import SearchProspectButton from "@/components/webpage_visitors/SearchProspectButton";
import { Button, Menu, MenuItem } from "@mui/material";
import { KompassFiltersProvider } from "@/hooks/useKompassFilters";

const menuButtonStyles = {
    fontFamily: "Outfit",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "normal",
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#0000001A",
    height: "32px",
    paddingLeft: "12px",
    paddingRight: "12px",
    color: "#0D0D0D",
    whiteSpace: "nowrap",
    "&:hover": {
        borderColor: "#0000005A",
        backgroundColor: "#FFFFFF",
    },
};

const menuItemStyles = {
    fontFamily: "Outfit",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "normal",
};

export default function WebsiteVisitorsScene() {
    const [currentLeftBarTab, setCurrentLeftBarTab] = useState(WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS);
    const [summary, setSummary] = useState("");
    const { userInfo } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [enrichedData, setEnrichedData] = useState([]);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [websiteReportData, setWebsiteReportData] = useState([]);
    const [websiteReportDomain, setWebsiteReportDomain] = useState(null);

    const [allowedDomains, setAllowedDomains] = useState([]);
    const [includedDomains, setIncludedDomains] = useState([]);

    const [tabOpen, setTabOpen] = useState(WEBSITE_VISITORS_PAGE_TABS.ANALYTICS);
    const [dateRange, setDateRange] = useState("week");
    const [analyticsDateRange, setAnalyticsDateRange] = useState("week");

    const [iCPFilters, setICPFilters] = useState([]);
    const [selectedICPFilter, setSelectedICPFilter] = useState(null);
    const [csvs, setCsvs] = useState([]);
    const [filterProps, setFilterProps] = useState([]);
    const [anchorDateEl, setAnchorDateEl] = useState(null);
    const [anchorICPFilterEl, setAnchorICPFilterEl] = useState(null);

    const { createUserEntry, getWebsiteVisitors, getAnalytics, updateAllowedDomains } = useIPsDeanonymization();

    const { getICPFilters } = useICPFilters();
    const getICPFiltersRef = useRef();
    getICPFiltersRef.current = getICPFilters;

    const { getExportedProfiles } = useExportProfiles();
    const getExportedProfilesRef = useRef();
    getExportedProfilesRef.current = getExportedProfiles;

    const getWebsiteVisitorsRef = useRef();
    getWebsiteVisitorsRef.current = getWebsiteVisitors;

    const getAnalyticsRef = useRef();
    getAnalyticsRef.current = getAnalytics;

    const updateAllowedDomainsRef = useRef();
    updateAllowedDomainsRef.current = updateAllowedDomains;

    const createUserEntryRef = useRef();
    createUserEntryRef.current = createUserEntry;

    const handleDateButtonClick = (event) => {
        setAnchorDateEl(event.currentTarget);
    };

    const handleDateButtonClose = () => {
        setAnchorDateEl(null);
    };

    const handleICPFilterButtonClick = (event) => {
        setAnchorICPFilterEl(event.currentTarget);
    };

    const handleICPFilterButtonClose = () => {
        setAnchorICPFilterEl(null);
    };

    const changeDateRange = (tag) => {
        setDateRange(tag);
        handleDateButtonClose();
    };

    const handleFilterSelect = (filter) => {
        setSelectedICPFilter(filter);
        handleICPFilterButtonClose();
    };

    useEffect(() => {
        const fetchICPFilters = async () => {
            try {
                const response = await getICPFiltersRef.current();
                if (response?.filters) {
                    setICPFilters(response.filters);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchICPFilters();
    }, []);

    useEffect(() => {
        if (!selectedICPFilter || enrichedData?.length <= 0) return;

        const applyICPFilter = (filter, data) => {
            let finalData = data;

            if (filter?.size?.length > 0) {
                const includedSizes = selectedICPFilter.size.map((item) => item?.value?.toLowerCase());

                finalData = finalData.filter((element) =>
                    includedSizes.includes(element.enrichedData?.companyDetails?.size?.toLowerCase())
                );
            }
            if (filter?.industry?.length > 0) {
                const includedIndustries = selectedICPFilter.industry.map((item) => item?.value?.toLowerCase());

                finalData = finalData.filter((element) =>
                    includedIndustries.includes(element.enrichedData?.companyDetails?.industry?.toLowerCase())
                );
            }
            if (filter?.location?.length > 0) {
                finalData = finalData.filter((element) =>
                    selectedICPFilter?.location?.includes(element.enrichedData?.ipDetails?.location?.name?.toLowerCase())
                );
            }
            if (filter?.department?.length > 0) {
                const includedDepartments = selectedICPFilter.department.map((item) => item?.value?.toLowerCase());

                finalData = finalData.filter((element) =>
                    includedDepartments.includes(element.enrichedData?.companyDetails?.job_title_role?.toLowerCase())
                );
            }
            if (filter?.level?.length > 0) {
                const includedLevels = selectedICPFilter.level.map((item) => item?.value?.toLowerCase());

                finalData = finalData.filter((element) => {
                    const jobTitleLevels = element.enrichedData?.companyDetails?.job_title_levels || [];
                    return jobTitleLevels.some((level) => includedLevels.includes(level.toLowerCase()));
                });
            }

            return finalData;
        };

        const filteredData = applyICPFilter(selectedICPFilter, enrichedData);
        setEnrichedData(filteredData);
    }, [selectedICPFilter, enrichedData]);

    const reload = async () => {
        const userId = userInfo?._id;
        if (!userId) return;

        setLoading(true);
        const params = { userId, period: dateRange || "all" };
        const query = new URLSearchParams(params).toString();

        try {
            const response = await getWebsiteVisitorsRef.current({ query });
            if (response) {
                setEnrichedData(response?.enrichedIps);
                setAllowedDomains(response?.allowedDomains);
                setSummary(response?.summary);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchEnrichedIPsData = async () => {
            const userId = userInfo?._id;
            if (!userId) return;

            setLoading(true);
            const params = { userId, period: dateRange || "all" };
            const query = new URLSearchParams(params).toString();

            try {
                const response = await getWebsiteVisitorsRef.current({ query });
                if (response) {
                    const domains = [...new Set(response?.enrichedIps?.map((entry) => entry.domain))];
                    setEnrichedData(response?.enrichedIps);
                    setAllowedDomains(response?.allowedDomains);
                    setIncludedDomains(domains);
                    if (domains?.[0]) setWebsiteReportDomain(domains?.[0]);
                    setSummary(response?.summary);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        };
        fetchEnrichedIPsData();
    }, [dateRange, userInfo]);

    useEffect(() => {
        const fetchAnalyticsData = async () => {
            const userId = userInfo?._id;
            if (!userId) return;

            setLoading(true);
            const params = { userId, period: analyticsDateRange || "all" };
            const query = new URLSearchParams(params).toString();

            try {
                const response = await getAnalyticsRef.current({ query });
                if (response) {
                    setAnalyticsData(response);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        };
        fetchAnalyticsData();
    }, [analyticsDateRange, userInfo]);

    useEffect(() => {
        const analyzeDataForCompaniesReport = (data) => {
            if (data?.length <= 0) return [];
            const processedData = [];

            const dataWithCompanyName = data.filter((i) => i?.enrichedData?.companyDetails?.name);
            const sortedData = dataWithCompanyName.sort((a, b) => b?.count - a?.count);

            const processedIds = new Set();

            sortedData.forEach((obj) => {
                const enrichedData = obj.enrichedData;
                const ipDetails = enrichedData.ipDetails;
                const companyDetails = enrichedData.companyDetails;
                const personDetails = enrichedData.personDetails;

                if (ipDetails && companyDetails) {
                    const id = companyDetails.pdlId || companyDetails.id;
                    if (!processedIds.has(id)) {
                        processedData.push({
                            companyName: companyDetails.name || NoInfoAvailableVal,
                            locationCountry: ipDetails.location.country || NoInfoAvailableVal,
                            companyIndustry: companyDetails.industry || NoInfoAvailableVal,
                            companyWebsite: companyDetails.website,
                            count: obj.count,
                            confidenceInterval:
                                (companyDetails.confidence && companyDetails.confidence === "very high"
                                    ? "high"
                                    : companyDetails.confidence) || NoInfoAvailableVal,
                            jobLevel: personDetails?.job_title_role || NoInfoAvailableVal,
                            jobRole: personDetails?.job_title_levels || NoInfoAvailableVal,
                        });
                        processedIds.add(id);
                    }
                }
            });

            return processedData;
        };

        const analyzeDataForIndustriesReport = (data) => {
            if (data?.length <= 0) return [];
            const groupedByIndustry = data.reduce((accumulator, item) => {
                const industry = item.enrichedData?.companyDetails?.industry;

                if (industry) {
                    const key = industry;

                    if (!accumulator[key]) {
                        accumulator[key] = {
                            industry,
                            companies: new Set(),
                            countries: new Set(),
                            locations: new Set(),
                            visitors: new Set(),
                            visits: 0,
                        };
                    }

                    const companyName = item.enrichedData?.companyDetails?.name;
                    if (companyName) {
                        accumulator[key].companies.add(companyName);
                    }

                    const countryName = item.enrichedData?.companyDetails?.country;
                    if (countryName) {
                        accumulator[key].countries.add(countryName);
                    }

                    const locationName = item.enrichedData?.ipDetails?.location?.name;
                    if (locationName) {
                        accumulator[key].locations.add(locationName);
                    }

                    accumulator[key].visitors.add(item.ip);
                    accumulator[key].visits += item.count || 1;
                }

                return accumulator;
            }, {});

            const processedData = Object.values(groupedByIndustry)
                .map((group) => ({
                    industry: group.industry || NoInfoAvailableVal,
                    companies: group.companies.size || NoInfoAvailableVal,
                    countries: group.countries.size || NoInfoAvailableVal,
                    locations: group.locations.size || NoInfoAvailableVal,
                    visitors: group.visitors.size || NoInfoAvailableVal,
                    count: group.visits,
                }))
                .sort((a, b) => b.count - a.count);

            return processedData;
        };

        const analyzeDataForEmployeeRangeReport = (data) => {
            if (data?.length <= 0) return [];
            const groupedByEmployeeRange = data.reduce((accumulator, item) => {
                const employeeRange = item.enrichedData?.companyDetails?.size;

                if (employeeRange) {
                    const key = employeeRange;

                    if (!accumulator[key]) {
                        accumulator[key] = {
                            employeeRange,
                            companies: new Set(),
                            countries: new Set(),
                            visitors: new Set(),
                            visits: 0,
                        };
                    }

                    const companyName = item.enrichedData?.companyDetails?.name;
                    if (companyName) {
                        accumulator[key].companies.add(companyName);
                    }

                    const countryName = item.enrichedData?.companyDetails?.country;
                    if (countryName) {
                        accumulator[key].countries.add(countryName);
                    }

                    accumulator[key].visitors.add(item.ip);
                    accumulator[key].visits += item.count || 1;
                }

                return accumulator;
            }, {});

            const processedData = Object.values(groupedByEmployeeRange)
                .map((group) => ({
                    employeeRange: group.employeeRange === "1-10" ? "1 to 10" : group.employeeRange,
                    companies: group.companies?.size || NoInfoAvailableVal,
                    countries: group.countries?.size || NoInfoAvailableVal,
                    visitors: group.visitors.size || NoInfoAvailableVal,
                    count: group.visits,
                }))
                .sort((a, b) => b.count - a.count);

            return processedData;
        };

        const analyzeDataForLocationsReport = (data) => {
            if (data?.length <= 0) return [];
            const groupedByLocation = data.reduce((accumulator, item) => {
                const locationDetails = item.enrichedData?.ipDetails?.location;
                if (locationDetails?.city && locationDetails?.region && locationDetails?.country) {
                    const employeeLocation = `${locationDetails.city}, ${locationDetails.region}, ${locationDetails.country}`;

                    if (!accumulator[employeeLocation]) {
                        accumulator[employeeLocation] = {
                            employeeLocation,
                            companies: new Set(),
                            industries: new Set(),
                            visitors: new Set(),
                            visits: 0,
                        };
                    }

                    const companyName = item.enrichedData?.companyDetails?.name;
                    if (companyName) {
                        accumulator[employeeLocation].companies.add(companyName);
                    }

                    const industryName = item.enrichedData?.companyDetails?.industry;
                    if (industryName) {
                        accumulator[employeeLocation].industries.add(industryName);
                    }

                    accumulator[employeeLocation].visitors.add(item.ip);
                    accumulator[employeeLocation].visits += item.count || 1;
                }

                return accumulator;
            }, {});

            const processedData = Object.values(groupedByLocation)
                .map((group) => ({
                    employeeLocation: group.employeeLocation || NoInfoAvailableVal,
                    companies: group.companies?.size || NoInfoAvailableVal,
                    industries: group.industries?.size || NoInfoAvailableVal,
                    visitors: group.visitors.size || NoInfoAvailableVal,
                    count: group.visits,
                }))
                .sort((a, b) => b.count - a.count);

            return processedData;
        };

        if (enrichedData) {
            const filteredData = enrichedData.filter((item) => item.domain === websiteReportDomain);
            if (currentLeftBarTab === WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS)
                setWebsiteReportData(analyzeDataForCompaniesReport(filteredData));
            if (currentLeftBarTab === WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES)
                setWebsiteReportData(analyzeDataForIndustriesReport(filteredData));
            if (currentLeftBarTab === WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES)
                setWebsiteReportData(analyzeDataForEmployeeRangeReport(filteredData));
            if (currentLeftBarTab === WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS)
                setWebsiteReportData(analyzeDataForLocationsReport(filteredData));
        }
    }, [enrichedData, websiteReportDomain, currentLeftBarTab]);

    useEffect(() => {
        const fetchCSVData = async () => {
            try {
                const response = await getExportedProfilesRef.current();
                if (response?.profiles) {
                    response.profiles.sort((a, b) => b.created_at - a.created_at);
                    setCsvs(response.profiles);
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchCSVData();
    }, [dateRange, currentLeftBarTab, selectedICPFilter]);

    const updateDomains = async (newDomains) => {
        const userId = userInfo?._id;

        if (userId) {
            const response = await updateAllowedDomainsRef.current({
                userId,
                domain: newDomains,
            });

            if (response === "Domains list updated successfully.") {
                setAllowedDomains(newDomains);
            }
        }
    };

    return (
        <KompassFiltersProvider>
            <MainLayout loading={loading}>
                <MainTitleBar>
                    <p>Website Intent</p>
                </MainTitleBar>
                <NavigationTabs setTabOpen={setTabOpen} tabOpen={tabOpen} />
                {tabOpen === WEBSITE_VISITORS_PAGE_TABS.WEBSITE_VISITORS && (
                    <div className="flex flex-col md:items-center md:flex-row md:justify-between gap-5 lg:gap-8 p-[24px] border-b border-[#E7E7E7]">
                        <div className="flex flex-col gap-2">
                            <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Top companies visitors</h2>
                            <p className="text-[14px] xl:text-[16px] text-[#5D5D5D] leading-none">
                                The top visitors-companies (based on total pageviews) that visited your website{" "}
                                {dateRange === "week"
                                    ? " in the last 7 days"
                                    : dateRange === "month"
                                      ? " in the last one month"
                                      : dateRange === "year"
                                        ? " in the last one year"
                                        : ""}
                                .
                            </p>
                        </div>
                        {/* Select Date Range Section */}
                        <div className="font-Outfit flex flex-col gap-6 lg:flex-row lg:items-center">
                            <div className="flex gap-4 lg:items-center">
                                <div>
                                    <Button
                                        variant="outlined"
                                        onClick={handleDateButtonClick}
                                        sx={menuButtonStyles}
                                        endIcon={
                                            <svg
                                                className="h-5 w-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                    >
                                        {dateRange !== "All" ? "Past " + dateRange : dateRange}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorDateEl}
                                        open={Boolean(anchorDateEl)}
                                        onClose={handleDateButtonClose}
                                        MenuListProps={{
                                            "aria-labelledby": "date-select-button",
                                        }}
                                    >
                                        <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("week")}>
                                            Past 7 Days
                                        </MenuItem>
                                        <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("month")}>
                                            Past 30 Days
                                        </MenuItem>
                                        <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("year")}>
                                            Past Year
                                        </MenuItem>
                                        <MenuItem sx={menuItemStyles} onClick={() => changeDateRange("all")}>
                                            All Data
                                        </MenuItem>
                                    </Menu>
                                </div>
                                <div>
                                    <Button
                                        variant="outlined"
                                        onClick={handleICPFilterButtonClick}
                                        sx={menuButtonStyles}
                                        endIcon={
                                            <svg
                                                className="h-5 w-5"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.72l3.71-3.53a.75.75 0 111.04 1.08l-4 3.75a.75.75 0 01-1.04 0l-4-3.75a.75.75 0 01-.02-1.06z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        }
                                    >
                                        {selectedICPFilter?.name || "Select ICP Filter"}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorICPFilterEl}
                                        open={Boolean(anchorICPFilterEl)}
                                        onClose={handleICPFilterButtonClose}
                                        MenuListProps={{
                                            "aria-labelledby": "icp-filter-button",
                                        }}
                                    >
                                        {iCPFilters && iCPFilters.length > 0 ? (
                                            <>
                                                {iCPFilters.map((filter, index) => (
                                                    <MenuItem key={index} sx={menuItemStyles} onClick={() => handleFilterSelect(filter)}>
                                                        {filter.name}
                                                    </MenuItem>
                                                ))}

                                                <MenuItem
                                                    sx={menuItemStyles}
                                                    onClick={() => {
                                                        setSelectedICPFilter(null);
                                                        handleICPFilterButtonClose();
                                                        reload();
                                                    }}
                                                >
                                                    All data
                                                </MenuItem>
                                            </>
                                        ) : (
                                            <MenuItem sx={menuItemStyles} disabled>
                                                No Filters Available
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </div>
                            </div>

                            <div className="flex gap-2">
                                <SearchProspectButton filterProps={filterProps} />
                                <ExportButton data={websiteReportData} />
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className={
                        tabOpen === WEBSITE_VISITORS_PAGE_TABS.WEBSITE_VISITORS
                            ? "w-full h-full grid grid-cols-1 lg:grid-cols-[auto_1fr] search-table"
                            : ""
                    }
                >
                    {tabOpen === WEBSITE_VISITORS_PAGE_TABS.WEBSITE_VISITORS && (
                        <div className="h-full col-span-1 bg-white p-[24px] lg:overflow-y-scroll border-r border-[#E7E7E7]">
                            <LeftBar currentTab={currentLeftBarTab} setCurrentTab={setCurrentLeftBarTab} summary={summary} />
                        </div>
                    )}
                    <div
                        className={`h-full w-full col-span-1 bg-white ${tabOpen === WEBSITE_VISITORS_PAGE_TABS.WEBSITE_VISITORS ? "lg:overflow-y-scroll" : ""}`}
                    >
                        {tabOpen === WEBSITE_VISITORS_PAGE_TABS.ANALYTICS && (
                            <Analytics analyticsData={analyticsData} duration={analyticsDateRange} setDuration={setAnalyticsDateRange} />
                        )}
                        {tabOpen === WEBSITE_VISITORS_PAGE_TABS.WEBSITE_VISITORS && (
                            <WebsiteReport
                                analyzedData={websiteReportData}
                                currentTab={currentLeftBarTab}
                                filterProps={filterProps}
                                setFilterProps={setFilterProps}
                                allDomains={includedDomains}
                                chosenDomain={websiteReportDomain}
                                setChosenDomain={setWebsiteReportDomain}
                            />
                        )}
                        {tabOpen === WEBSITE_VISITORS_PAGE_TABS.PROFILE_EXPORTS && <ProfileExport data={websiteReportData} csvs={csvs} />}
                        {tabOpen === WEBSITE_VISITORS_PAGE_TABS.TARGET_CUSTOMER_PERSONA && (
                            <ICPFilter iCPFilters={iCPFilters} setICPFilters={setICPFilters} />
                        )}
                        {tabOpen === WEBSITE_VISITORS_PAGE_TABS.ACCOUNT_DETAILS && (
                            <AccountDetails userId={userInfo?._id} allowedDomains={allowedDomains} updateDomains={updateDomains} />
                        )}
                    </div>
                </div>
            </MainLayout>
        </KompassFiltersProvider>
    );
}
