import React from "react";

function Tab({ label, isActive, tabClicked }) {
    const userClicked = async () => {
        tabClicked(label);
    };
    return (
        <div
            onClick={userClicked}
            className={`flex flex-col flex-1 shrink justify-center basis-0 ${isActive ? "shadow-[0px_3px_8px_rgba(0,0,0,0.12)] rounded-lg" : ""} cursor-pointer`}
        >
            <div className="flex overflow-hidden flex-col flex-1 justify-center">
                <div className="flex flex-col flex-1">
                    <div className={`flex flex-col flex-1 justify-center px-1 py-2 rounded-md ${isActive ? "bg-white" : ""}`}>
                        <div className="flex-1">{label}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Tab;
