import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Box, Table, TableCell, TableBody, TableContainer, Pagination, TableRow } from "@mui/material";

import CRMUploadTableHeader from "./CRMUploadTableHeader";
import CRMUploadTableRow from "./CRMUploadTableRow";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { useFormatUploadResults } from "@/hooks/useFormatUploadResults";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";
import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { sumBy } from "lodash";

const headCells = [
    {
        id: "name",
        disablePadding: true,
        label: "Name",
        align: "center",
        width: "35%",
    },
    {
        id: "entity",
        disablePadding: true,
        label: "Target entity",
        align: "center",
        width: "10%",
    },
    {
        id: "currentEntity",
        disablePadding: true,
        label: "Current entity",
        align: "center",
        width: "10%",
    },
    {
        id: "status",
        disablePadding: true,
        label: "Status",
        align: "center",
        width: "10%",
    },
    {
        id: "suggestions",
        disablePadding: true,
        label: "Suggestions",
        align: "center",
        width: "35%",
    },
];

export default function CRMUploadTable() {
    const formattedResult = useFormatUploadResults();
    const { toggleShow, target, targetEntity, uploads } = useCRMPushResultStore();
    const [totalCount, setTotalCount] = useState(formattedResult.length);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("status");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPageResults, setCurrentPageResults] = useState(formattedResult);
    const totalContactsCount = uploads.length ? sumBy(uploads, "numberOfContacts") : 0;
    // contacts ignored due to lack of data(email or phone or name)
    const contactNotPushedCout = (totalContactsCount || 0) - totalCount;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const updateSorting = (column) => {
        if (column.id === orderBy) {
            setOrder(order === "asc" ? "desc" : "asc");
            return;
        }
        setOrderBy(column.id);
        setOrder("asc");
    };
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
        toggleShow();
    });

    useEffect(() => {
        const currentSlice = (page - 1) * rowsPerPage;
        const nextSlice = currentSlice + rowsPerPage;
        const copy = [...formattedResult];

        copy.sort((a, b) => {
            const rule = a?.[orderBy] > b?.[orderBy] ? 1 : -1;
            const ruleInv = a?.[orderBy] < b?.[orderBy] ? 1 : -1;
            const equal = a?.[orderBy] === b?.[orderBy] ? 0 : order === "asc" ? rule : ruleInv;
            return equal;
        });
        setCurrentPageResults(copy.slice(currentSlice, nextSlice));
    }, [page, rowsPerPage, order, orderBy, formattedResult]);
    useEffect(() => {
        setTotalCount(formattedResult.length);
    }, [formattedResult]);
    return (
        <Dialog open={true} fullWidth maxWidth="lg" scroll="body" PaperProps={{ sx: { borderRadius: "10px" } }}>
            <IconButton
                aria-label="close"
                onClick={() => {
                    toggleShow();
                }}
                sx={{
                    position: "absolute",
                    right: 10,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <div className="flex items-center mt-8">
                    <div className="">
                        <div className="font-OutfitBold flex flex-col font-semibold md:text-4xl md:font-extrabold">Upload Completed!</div>
                        <div className="font-OutfitLight flex w-fit flex-col text-sx md:text-lg">
                            Please review the results in the table below
                        </div>
                    </div>
                    <div className="ml-auto">
                        <div className="font-OutfitBold flex flex-col text-end font-semibold md:text-4xl md:font-extrabold">{target}</div>
                        <div className="font-OutfitLight flex flex-col text-end text-xs md:text-lg">{targetEntity}</div>
                    </div>
                </div>
                {contactNotPushedCout > 0 && (
                    <div className="font-OutfitLight text-md">{contactNotPushedCout} contacts were not pushed due to the lack of data</div>
                )}
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    <TableContainer className="bg-white">
                        <Table
                            sx={{
                                minWidth: 750,
                                minHeight: 150,
                                borderCollapse: "separate",
                                border: "none",
                                backgroundColor: "white",
                            }}
                            aria-labelledby="tableTitle"
                        >
                            <CRMUploadTableHeader
                                order={order}
                                orderBy={orderBy}
                                headCells={headCells}
                                onChangeSorting={updateSorting}
                            ></CRMUploadTableHeader>

                            <TableBody
                                className="relative"
                                sx={{
                                    "& .MuiTableCell-sizeMedium": {
                                        padding: "10px 8px",
                                        fontFamily: "Outfit",
                                        height: "fit-content",
                                        fontSize: 14,
                                    },
                                }}
                            >
                                {!formattedResult?.length && (
                                    <TableRow className="w-full ">
                                        <TableCell colSpan={5}>
                                            <Box className="flex w-full flex-col items-center justify-center">
                                                <span className="font-OutfitBold text-2xl">No uploads</span>
                                                <span className="font-Outfit text-md">
                                                    Select different profiles / bulk enrichment results
                                                </span>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {currentPageResults?.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <CRMUploadTableRow key={JSON.stringify(row) + index} row={row} labelId={labelId} tableWidth={5} />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div className="flex w-full justify-end rounded-b-[8px] bg-white py-2">
                        <Pagination
                            className="font-Outfit mt-2 "
                            count={Math.ceil(totalCount / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                            sx={{
                                "& .MuiPaginationItem-text": {
                                    fontFamily: "Outfit",
                                    fontSize: "14px",
                                },
                            }}
                        />
                    </div>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export { CRMUploadTable };
