import React, { useState } from "react";

function InfoCard({ title, content }) {
    const [isContentVisible, setIsContentVisible] = useState(true);

    const toggleVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    return (
        <section className="flex flex-col justify-center self-stretch mt-6 w-full text-base rounded-lg bg-neutral-100 max-md:max-w-full">
            <header className="flex gap-4 justify-between items-center p-4 w-full font-semibold capitalize rounded-lg text-zinc-950 max-md:max-w-full">
                <h2 className="my-auto text-sm md:text-base">{title}</h2>
                <button onClick={toggleVisibility} className="transform transition-transform duration-300 ease-in-out">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 transform ${isContentVisible ? "rotate-0" : "rotate-180"}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
            </header>
            {isContentVisible && (
                <hr className="w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            )}
            <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isContentVisible ? "max-h-[500px]" : "max-h-0"}`}>
                <p className="flex-1 shrink gap-2.5 self-stretch p-4 w-full text-zinc-700 text-sm sm:text-base max-md:max-w-full">
                    {content}
                </p>
            </div>
        </section>
    );
}

export default InfoCard;
