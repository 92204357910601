import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const STRAPI_COMPONENTS = {
    heading: "texts.heading",
    subHeading: "texts.subheading",
    text: "texts.simple-text",
    imageTextLeft: "image-texts.image-text-left",
    imageTextRight: "image-texts.image-text-right",
    customText: "texts.custom-text",
};

export default function Article({ post }) {
    return (
        <article className="blog-post">
            {post &&
                post.attributes?.postContent?.map((content) => {
                    if (content.__component === STRAPI_COMPONENTS.heading) {
                        return (
                            <h2
                                key={content.id}
                                className="text-[22px] md:text-[26px] lg:text-[28px] leading-[1.2] font-semibold mt-10 mb-6"
                            >
                                {content.heading}
                            </h2>
                        );
                    }
                    if (content.__component === STRAPI_COMPONENTS.subHeading) {
                        return (
                            <h3 key={content.id} className="text-[18px] md:text-[20px] lg:text-[24px] leading-[1.2] font-medium mt-8 mb-4">
                                {content.subheading}
                            </h3>
                        );
                    }
                    if (content.__component === STRAPI_COMPONENTS.text) {
                        return content.simpleText.split("\n\n").map((text, index) => (
                            <p key={content.id + index} className="text-[16px] md:text-[18px] lg:text-[20px] leading-[1.4] mb-6">
                                {text}
                            </p>
                        ));
                    }
                    if (content.__component === STRAPI_COMPONENTS.imageTextLeft) {
                        return (
                            <div key={content.id} className="flex flex-col xl:flex-row gap-6 mb-6 items-start">
                                <div className="w-full xl:w-[240px] 2xl:w-[290px] shrink-0">
                                    <img src={`${content?.image?.data?.attributes?.url}`} alt="BlogImage" className="w-full h-auto" />
                                </div>
                                <p className="text-[16px] md:text-[18px] lg:text-[20px] leading-[1.4]">{content.text}</p>
                            </div>
                        );
                    }
                    if (content.__component === STRAPI_COMPONENTS.customText) {
                        return <BlocksRenderer content={content.customText} />;
                    }
                    return null;
                })}
        </article>
    );
}
