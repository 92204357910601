import AllTabs from "@/components/ai_engagement/AllTabs";
import React from "react";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import Inbox from "@/components/ai_engagement/inbox/Inbox";

export default function InboxScene() {
    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white">
                <div className="flex flex-col grow">
                    <AllTabs className="px-[24px]" />
                    <Inbox />
                </div>
            </div>
        </MainLayout>
    );
}
