import React from "react";

function TableHeader({ title, width }) {
    return (
        <div className={`flex flex-col text-sm leading-none min-w-[240px] text-zinc-700 ${width}`}>
            <div
                className={`flex gap-1 items-center  pl-6 pr-24 py-3 w-full text-xs font-bold leading-loose capitalize whitespace-nowrap border-solid min-h-[44px] text-zinc-950 max-md:px-5`}
            >
                <div className="self-stretch my-auto">{title}</div>
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/1c0a4ec61d6a733597f93fb1a578e56dcfeb08041a4e315872f4201002118ab1?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
                    className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square"
                    alt=""
                />
            </div>
        </div>
    );
}

export default TableHeader;
