import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

function BackdropLoader({ active, size, clsName = "" }) {
    return (
        <>
            {active && (
                <Backdrop
                    open
                    className={`!absolute${clsName ? ` ${clsName}` : ""}`}
                    sx={{
                        backgroundColor: "rgba(0,0,0,0.18)",
                        backdropFilter: "blur(2px)",
                        zIndex: 10,
                    }}
                >
                    <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                        <CircularProgress size={size ? size : "32px"}></CircularProgress>
                    </div>
                </Backdrop>
            )}
        </>
    );
}

export default BackdropLoader;
