import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import useAutocomplete from "@/hooks/useAutocomplete";

export default function CustomAutocomplete({
    id,
    options,
    selectedValues,
    onSelectionChange,
    onRemove,
    placeholder = "Select items",
    isUseAPI = false,
    sx = {},
    searchBy = "location",
}) {
    const { searchAutoComplete } = useAutocomplete();
    const [apiOptions, setApiOptions] = React.useState([]);
    const [filterText, setFilterText] = React.useState("");
    const [apiLoading, setApiLoading] = React.useState(false);
    const [lastTimerId, setLastTimerId] = React.useState(null);

    React.useEffect(() => {
        if (filterText || (filterText?.length > 0 && isUseAPI)) {
            if (lastTimerId) clearTimeout(lastTimerId);
            const newLastTimerId = setTimeout(() => {
                loadDataByFilter();
            }, 500);
            setLastTimerId(newLastTimerId);
        }
    }, [filterText]);

    const loadDataByFilter = async () => {
        setApiLoading(true);
        const response = await searchAutoComplete({
            params: {
                query: filterText,
            },
            searchParam: searchBy,
        });
        if (response?.status) {
            const result = response?.data;
            setApiOptions(result);
        }

        setApiLoading(false);
    };

    return (
        <div className="flex gap-5 flex-wrap">
            <div className="w-full md:w-[330px]">
                <Autocomplete
                    multiple={isUseAPI ? false : true}
                    id={id}
                    options={isUseAPI ? apiOptions : options}
                    getOptionLabel={isUseAPI ? (option) => option.name : (option) => option}
                    disableCloseOnSelect
                    noOptionsText={apiLoading ? "Loading..." : "No options"}
                    value={isUseAPI ? null : selectedValues}
                    blurOnSelect={isUseAPI ? true : false}
                    onChange={(event, newValue) => {
                        setFilterText("");
                        onSelectionChange(event, newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setFilterText(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder={placeholder}
                            value={filterText}
                            sx={{
                                "& .css-ka7ti6-MuiAutocomplete-root": {
                                    padding: "0px !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#E8E7E7",
                                    },
                                    paddingRight: "10px !important",
                                    backgroundColor: "#f5f5f5", // Matches bg-stone-100
                                    padding: "1px 8px !important",
                                    fontSize: "0.875rem", // Matches text-sm
                                    borderRadius: "0.375rem", // Matches rounded-md
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E8E7E7", // Maintain border color when focused
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E8E7E7", // Maintain border color on hover
                                    },
                                },
                                "& .MuiInputAdornment-root": {
                                    marginRight: "8px",
                                },
                                "& .MuiAutocomplete-input": {
                                    padding: 0,
                                },
                                "& .MuiAutocomplete-popupIndicator": {
                                    display: "none",
                                },
                                ...sx,
                            }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <SearchOutlinedIcon sx={{ color: "#a1a1aa" }} />
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                                endAdornment: (
                                    <>
                                        {apiLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderTags={() => null}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...restProps } = props;
                        return (
                            <>
                                {isUseAPI ? (
                                    <li
                                        key={key}
                                        {...restProps}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: selected ? "bold" : "normal",
                                            backgroundColor: "transparent",
                                            fontSize: "12px",
                                        }}
                                    >
                                        <span>{option.name}</span>
                                        <span style={{ marginLeft: "auto" }}>({option.count})</span>
                                    </li>
                                ) : (
                                    <li
                                        key={key}
                                        {...restProps}
                                        style={{ fontWeight: selected ? "bold" : "normal", backgroundColor: "transparent" }}
                                    >
                                        {option}
                                    </li>
                                )}
                            </>
                        );
                    }}
                />
            </div>

            {selectedValues.map((item) => (
                <div key={item} className="flex items-center bg-blue-500 text-white gap-2 py-2 px-3 text-sm  rounded-full">
                    <span>{item}</span>
                    <CloseIcon
                        className="cursor-pointer bg-white rounded-full p-1 text-black"
                        fontSize="small"
                        onClick={() => onRemove(item)}
                    />
                </div>
            ))}
        </div>
    );
}
