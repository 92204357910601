import React, { useEffect, useState } from "react";
import { useKompassFilters } from "@/hooks/useKompassFilters";
import useKompassSearch from "@/hooks/useKompassSearch";
import { Country, State } from "country-state-city";
import {
    countryList,
    departmentList,
    industryList,
    levelList,
    numberOfEmployeesList,
    revenueList,
    subDepartmentList,
} from "@/utils/filter";
import { ReactSVG } from "react-svg";
import SearchIcon from "@/assets/image/team/search.svg";
import { toast } from "react-toastify";

export default function FlexSearch({ handleSearchByFilter, setIsLoading, setIsSearchClicked, setContactFilter }) {
    const [flexSearchInput, setFlexSearchInput] = useState(null);

    const { getFiltersByFlexSearch } = useKompassSearch();
    const {
        setIndustryName,
        setRevenue,
        setNumberOfEmployees,
        setLevel,
        setTitle,
        setCountry,
        setStatesList,
        setPersonalLocation,
        setCompanyLocation,
        setDept,
        setSubDepartment,
        setState,
        setHqContinent,
        setHqCountry,
        setContinent,
        planInfo,
        setIsFromSavedFiltersPage,
    } = useKompassFilters();

    const countryListForIsoCode = Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));
    const getIsoCode = (country) => {
        const countryObject = countryListForIsoCode.find((obj) => obj.label.toLowerCase() === country?.toLowerCase());
        return countryObject?.value;
    };

    const handleCountryChange = (selectedCountries, selectedRegion) => {
        setStatesList([]);
        // find the country label from list
        const countryLabelValuePairs = countryList
            .filter((country) => selectedCountries.includes(country.value))
            .map((country) => ({ ...country, status: "include" }));
        setCountry(countryLabelValuePairs);
        if (selectedCountries.length > 0) {
            const allStates = selectedCountries.flatMap((country) => {
                const countryIsoCode = getIsoCode(country);
                return State.getStatesOfCountry(countryIsoCode).map((state) => ({
                    label: state.name,
                    value: state.name.toLowerCase(),
                }));
            });
            const selectedStates = allStates
                .filter((state) => selectedRegion.includes(state.value))
                .map((state) => ({ ...state, status: "include" }));
            setState(selectedStates);

            setStatesList(allStates);
        } else {
            setStatesList([]);
        }
    };

    // watch all relevant states and trigger handleSearch when ready
    const handleFlexSearch = async () => {
        if (!flexSearchInput) {
            toast.error("Please enter a search term");
            return;
        }

        setIsLoading(true);
        setIsSearchClicked(true);
        const res = await getFiltersByFlexSearch(flexSearchInput);
        if (res.status) {
            setIndustryName(
                industryList
                    .filter((industry) => res.data.response.industry.includes(industry.value))
                    .map((industry) => ({ ...industry, status: "include" }))
            );
            setRevenue(
                revenueList
                    .filter((revenue) => res.data.response.job_company_inferred_revenue.includes(revenue.value))
                    .map((revenue) => ({ ...revenue, status: "include" }))
            );
            setNumberOfEmployees(
                numberOfEmployeesList
                    .filter((numberOfEmployees) => res.data.response.job_company_size.includes(numberOfEmployees.value))
                    .map((numberOfEmployees) => ({ ...numberOfEmployees, status: "include" }))
            );
            setLevel(
                levelList
                    .filter((level) => res.data.response.job_title_levels.includes(level.value))
                    .map((level) => ({ ...level, status: "include" }))
            );
            setDept(
                departmentList
                    .filter((department) => res.data.response.job_title_role.includes(department.value))
                    .map((department) => ({ ...department, status: "include" }))
            );
            setSubDepartment(
                subDepartmentList
                    .filter((subDepartment) => res.data.response.job_title_sub_role.includes(subDepartment.value))
                    .map((subDepartment) => ({ ...subDepartment, status: "include" }))
            );
            setTitle(res.data.response.job_title);
            handleCountryChange(res.data.response.location_country, res.data.response.location_region);
            setPersonalLocation(res.data.response.location_name);
            setCompanyLocation(res.data.response.job_company_location_name);

            const resData = res.data.response;

            const filteredData = Object.fromEntries(
                Object.entries(resData).filter(([key, value]) => Array.isArray(value) && value.length > 0)
            );

            // remove location_continents from filteredData
            delete filteredData["location_continent"];

            const transformedData = Object.fromEntries(
                Object.entries(filteredData).map(([key, array]) => [
                    key,
                    key === "job_company_location_continent" || key === "job_title"
                        ? array // Leave as is for these keys
                        : array.map((element) => ({
                              label: element,
                              value: element,
                              status: "include",
                          })),
                ])
            );

            // remove job_company_location_continent and job_title from transformedData
            // delete transformedData["job_company_location_continent"];
            // delete transformedData["job_title"];

            const emptyFiltersInfoMessage = "No filters found in your query";

            if (!Object.keys(transformedData).length) {
                setIsLoading(false);
                return toast.info(emptyFiltersInfoMessage);
            }

            setContactFilter({ params: { ...transformedData, subscriptionName: planInfo.name } });
            setIsFromSavedFiltersPage(true);
            await handleSearchByFilter({ params: { ...transformedData, subscriptionName: planInfo.name } });
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className=" flex gap-2 items-center w-full px-8 mt-[25px]">
                <div className="relative w-full">
                    <input
                        onChange={(e) => setFlexSearchInput(e.target.value)}
                        value={flexSearchInput}
                        type="search"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleFlexSearch();
                            }
                        }}
                        placeholder="Flex Search"
                        className="font-Outfit border border-stone-250 rounded-lg w-full py-2 px-2.5 pl-10 text-sm outline-none mx-auto text-dgray-900 placeholder-dgray-500"
                    />
                    <ReactSVG
                        src={SearchIcon}
                        beforeInjection={(svg) => {
                            svg.setAttribute("style", "width: 18px; height: 18px;");
                        }}
                        className="absolute top-[8px] 2xl:top-[10px] left-3"
                    />
                </div>
                <button
                    onClick={handleFlexSearch}
                    className="bg-black py-2 font-OutfitMedium px-4 text-white whitespace-nowrap rounded-lg text-sm "
                >
                    Flex Search
                </button>
            </div>
        </>
    );
}
