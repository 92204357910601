import React from "react";

function Badge({ status }) {
    return (
        <div className="gap-0.5 self-stretch px-2 py-1 my-auto text-center whitespace-nowrap rounded bg-neutral-100 text-zinc-950">
            {status}
        </div>
    );
}

export default Badge;
