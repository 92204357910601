import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { ReactComponent as DownSVGIcon } from "@/assets/image/icons/down.svg";
import FilterPopup, { filterOptions } from "@/components/popup/FilterPopup";

export default function CreditsUsedByProductComponent({ creditsData = [], filter, setFilter }) {
    const [anchorEl, setAnchorEl] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);

    useEffect(() => {
        const chartOptions = {
            chart: {
                type: "pie",
                height: "40%",
                spacingBottom: 30,
            },
            title: {
                text: "",
            },
            plotOptions: {
                pie: {
                    innerSize: "85%",
                    borderRadius: 8,
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: "#3D3D3D",
                            fontSize: "16px",
                            fontFamily: "Outfit, sans-serif",
                            fontWeight: "normal",
                        },
                    },
                    showInLegend: false,
                },
            },
            colors: ["#4873FA", "#FFA500", "#FFFF66", "#66CCFF", "#FF6666"],
            series: [
                {
                    name: "Credits Used",
                    data: creditsData.map((item) => ({
                        name: item.product,
                        y: item.value,
                    })),
                },
            ],
            credits: {
                enabled: false,
            },
        };

        const chart = Highcharts.chart("creditsUsedByProductChart", chartOptions);

        return () => {
            chart.destroy();
        };
    }, [creditsData]);

    return (
        <div className="flex w-full flex-col items-center justify-start gap-4 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
            <div className="inline-flex w-full shrink basis-0 flex-col items-start justify-start gap-4">
                <div className="inline-flex items-center justify-start gap-4 self-stretch">
                    <div className="font-Outfit shrink grow basis-0 text-md 2xl:text-lg font-normal text-black">
                        Credits Used by Product
                    </div>

                    <div className="flex items-center justify-start gap-4">
                        <button
                            className="flex items-center justify-start gap-2"
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setOpenFilter((cur) => !cur);
                            }}
                        >
                            <div className="font-Outfit leading-tight text-sm 2xl:text-base font-normal text-black">
                                {filterOptions[filter]}
                            </div>
                            <DownSVGIcon className="relative h-4 w-4" />
                        </button>
                    </div>
                </div>
                <div className="font-Outfit leading-tight text-sm font-normal text-neutral-400">Credits used by product over time.</div>
                <div id="creditsUsedByProductChart" className="w-full "></div>
            </div>
            <FilterPopup
                anchorEl={anchorEl}
                open={openFilter}
                handleClose={(value) => {
                    if (value) {
                        setFilter(value);
                    }
                    setAnchorEl(null);
                    setOpenFilter(false);
                }}
            />
        </div>
    );
}
