import logo1 from "@/assets/image/logos/costbo.png";
import logo2 from "@/assets/image/logos/union.png";
import logo3 from "@/assets/image/logos/contemplate.png";
import logo4 from "@/assets/image/logos/config.png";

const partners = [
    { src: logo1, alt: "Costbo logo", width: 100 },
    { src: logo2, alt: "Union Digital logo", width: 80 },
    { src: logo3, alt: "Contemplate logo", width: 180 },
    { src: logo4, alt: "Config GPT logo", width: 60 },
];

export default function SupportBar() {
    return (
        <div className="px-12 py-6 sm:py-10 lg:py-12 mx-auto flex items-center justify-center gap-10 sm:gap-16 lg:gap-20 max-w-[900px] h-[80px] lg:h-[100px]">
            {partners.map((item, ind) => {
                return (
                    <div className="shrink" key={ind}>
                        <img src={item.src} alt={item.alt} width={item.width} />
                    </div>
                );
            })}
        </div>
    );
}
