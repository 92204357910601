import React, { useState } from "react";
import SegmentedControls from "./SegmentedControls";
import PendingEmails from "./PendingEmails";
import ScheduledEmails from "./ScheduledEmails";

const initialTabData = [
    { label: "Pending Emails", isActive: true },
    { label: "Scheduled Emails", isActive: false },
];

function PendingApproval() {
    const [tabData, setTabData] = useState(initialTabData);

    const handleTabChange = (label) => {
        const updatedTabs = tabData.map((tab) => ({
            ...tab,
            isActive: tab.label === label,
        }));
        setTabData(updatedTabs);
    };

    const activeTab = tabData.find((tab) => tab.isActive)?.label;

    return (
        <div className="py-10 px-6">
            <SegmentedControls initialTabData={tabData} onTabChange={handleTabChange} />
            {activeTab === "Pending Emails" && <PendingEmails />}
            {activeTab === "Scheduled Emails" && <ScheduledEmails />}
        </div>
    );
}

export default PendingApproval;
