/* *****************      Libraries     ***************** */

import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

/* *****************    Componenets   ***************** */

import FilterDropdown from "@/components/common/FilterDropdown";

/* *****************    Assets    ***************** */

import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

/* *****************    Context & Hooks   ***************** */

import { useAuthContext } from "@/context/AuthContext";

/* *****************    Constants & Common   ***************** */

import { COGNITO_GOOGLE_DEFAULT_PASSWORD, KompassString } from "@/utils/constants";

/* *****************    Base Dialog   ***************** */

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "10px",
    },
}));

/* *****************    Reset Password Dialog    ***************** */

export default function ChangePasswordDialog({ isGoogleAuth, isPasswordFilled, open, close }) {
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Old password is required"),
        newPassword: Yup.string()
            .min(10, "Password must be at least 10 characters")
            .matches(/[a-z]/, "Password must contain at least one lowercase letter")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .required("New password is required")
            .matches(/[@$!%*#?&]+/, "password must contain at least one special character")
            .matches(/\d+/, "password must contain at least one number"),
        newPasswordAgain: Yup.string()
            .required("Confirmation password is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    });

    // *Define Context & Hooks*
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { changePassword, signOut } = useAuthContext();

    // *Define States*
    const [saving, setSaving] = useState(false);

    // *Define Constants*

    // *Hooks*

    // *Define Functions*

    const onChangePassword = async (values, { setSubmitting }) => {
        setSaving(true);

        try {
            const { oldPassword, newPassword } = values;
            if (oldPassword === newPassword) {
                toast.error("New Password can not be the same as the old password.", {
                    theme: "colored",
                    hideProgressBar: false,
                });
            } else {
                await changePassword(oldPassword, newPassword);

                toast.success(KompassString.PasswordChangedSuccessfully, {
                    theme: "colored",
                    hideProgressBar: false,
                });
                setTimeout(signOut, 1000 * 5);
            }
        } catch (e) {
            toast.error(e?.message || KompassString.SomethingWentWrong, {
                theme: "colored",
            });
        }

        setSaving(false);
    };

    // *Render*

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={close}>
            <button onClick={close} className="absolute right-0 top-0 flex flex-row justify-end pr-4 pt-4">
                <CloseSVGIcon className="relative h-4 w-4" />
            </button>

            <DialogContent>
                <div className="font-Outfit p-3 text-center text-2xl font-bold text-black">Update Password</div>
                <Formik
                    initialValues={{
                        oldPassword: isGoogleAuth && !isPasswordFilled ? COGNITO_GOOGLE_DEFAULT_PASSWORD : "",
                        newPassword: "",
                        newPasswordAgain: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onChangePassword}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <div className="flex min-w-[400px] flex-col">
                                <div className="flex w-full flex-col items-start justify-center gap-1">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">Old Password</div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                        <Field
                                            type="password"
                                            name="oldPassword"
                                            className="font-Outfit leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light text-black"
                                            placeholder="Minimum of 10 characters"
                                            disabled={isGoogleAuth && !isPasswordFilled}
                                        />
                                    </div>
                                    <div className="min-h-[20px]">
                                        <ErrorMessage
                                            name="oldPassword"
                                            component="div"
                                            className="font-Outfit leading-tight text-sm font-normal text-red-500"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-full flex-col items-start justify-center gap-1">
                                    <div className="inline-flex items-center justify-between self-stretch">
                                        <div className="font-Outfit leading-snug text-base font-medium text-black">New Password</div>
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                        <Field
                                            type="password"
                                            name="newPassword"
                                            className="font-Outfit leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light text-black"
                                            placeholder="Minimum of 10 characters"
                                        />
                                    </div>
                                    <div className="min-h-[20px]">
                                        <ErrorMessage
                                            name="newPassword"
                                            component="div"
                                            className="font-Outfit leading-tight text-sm font-normal text-red-500"
                                        />
                                    </div>
                                    <div className="inline-flex items-center justify-start gap-2 self-stretch bg-white">
                                        <Field
                                            type="password"
                                            name="newPasswordAgain"
                                            className="font-Outfit leading-snug shrink grow basis-0 rounded-md border border-stone-250 px-3 py-2 text-base font-light text-black"
                                            placeholder="Minimum of 10 characters"
                                        />
                                    </div>
                                    <div className="min-h-[20px]">
                                        <ErrorMessage
                                            name="newPasswordAgain"
                                            component="div"
                                            className="font-Outfit leading-tight text-sm font-normal text-red-500"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="font-Outfit mt-2 flex w-full flex-row justify-center gap-2 text-lg font-bold text-black">
                                <Button
                                    disabled={saving}
                                    className="font-Outfit flex items-center justify-center rounded-full border-[1px] border-none bg-blue-500 py-2 text-base text-white disabled:bg-[#929292]"
                                    type="submit"
                                >
                                    {saving ? <CircularProgress size="1.2rem" className="mr-2" style={{ color: "white" }} /> : null}
                                    Update
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </BootstrapDialog>
    );
}
