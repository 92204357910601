import React from "react";
import { Button } from "@material-tailwind/react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CloseSVGIcon } from "@/assets/image/icons/close.svg";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        borderRadius: "16px",
        width: "458px",
    },
}));

export default function ConfirmDeleteDialog({ open, handleNo, handleYes }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <BootstrapDialog fullScreen={fullScreen} open={open} onClose={handleNo}>
            <div className="flex flex-row justify-between items-center px-6 py-4 border-b border-[#e7e7e7]">
                <div className=" text-[#050505] text-[18px] font-semibold font-['Outfit']">
                    Are you sure you want to delete your account?
                </div>
                <button onClick={handleNo} className=" flex flex-row justify-end">
                    <CloseSVGIcon className="relative h-4 w-4" />
                </button>
            </div>
            <DialogContent sx={{ padding: 0 }}>
                <div className="flex flex-row px-6 py-4 gap-4 justify-end">
                    <Button
                        className="w-[80px] px-3 py-1.5 border border-[#e7e7e7] text-sm text-center font-normal text-stone-950 capitalize rounded-[8px] font-['Outfit'] bg-white 2xl:px-2"
                        onClick={handleNo}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="w-[80px] px-3 py-1.5 text-sm text-center font-normal text-white capitalize rounded-[8px] font-['Outfit'] bg-stone-950 2xl:px-2"
                        onClick={handleYes}
                    >
                        Delete
                    </Button>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}
