import React from "react";

function Description() {
    return (
        <article className="mt-10 px-5 w-full max-md:mt-10 max-md:max-w-full">
            <h2 className="font-semibold text-lg text-zinc-950 sm:text-center max-md:max-w-full">
                Uncover Direct Contact Information for B2B Professionals
            </h2>
            <div className="mt-2 text-xl text-neutral-500 sm:w-3/5 mx-auto">
                <p>
                    Easily find email addresses, phone numbers, and other key details using our powerful search platform. Access a
                    comprehensive database of over 800 million professional profiles, providing you with unrivaled direct contact
                    information.
                </p>
            </div>
        </article>
    );
}

export default Description;
