import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { kompassColors } from "@/theme/palette";
import PropTypes from "prop-types";

const headCells = [
    {
        id: "accounts",
        numeric: false,
        label: "Accounts",
    },
    {
        id: "dmc",
        disablePadding: true,
        label: "Daily Email Capacity",
        align: "center",
    },
    // hidding, please check SCRUM-713
    // {
    //     id: "status",
    //     numeric: false,
    //     disablePadding: true,
    //     label: "Status",
    //     align: "center",
    // },
    {
        id: "m_health",
        numeric: true,
        disablePadding: true,
        label: "Mailbox Health",
    },
    {
        id: "m_box",
        numeric: true,
        disablePadding: true,
        label: "Use This MailBox",
    },
    {
        id: "extend",
        numeric: true,
        sortable: false,
        disablePadding: true,
        disableSorting: true,
        align: "right",
        label: "",
    },
];

function MailBoxHeader(props) {
    const { order, orderBy } = props;

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "4px 10px",
                    fontFamily: "Outfit",
                    fontSize: "0.875rem",
                    color: kompassColors.black,
                    "@media (min-width: 540px)": {
                        padding: "10px 6px",
                        fontSize: "1rem",
                    },
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                        bgcolor: "#E8E7E7",
                    },
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                    },
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell?.align || "start"} padding={"none"} size="medium">
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
MailBoxHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeSorting: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default MailBoxHeader;
