import { useRef, useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { getShortName, indexString } from "@/utils/common";
import useKompassRouter from "@/hooks/useKompassRouter";
import { ReactComponent as SearchDesignSVGIcon } from "@/assets/image/home/design-eye-search.svg";
import useEnrichment from "@/hooks/useEnrichment";

export default function ProspectSearchComponent(props) {
    const { kompassNavigate } = useKompassRouter();
    const { recentEnrichHistories } = useEnrichment();
    const [loadingRecent, setLoadingRecent] = useState(false);
    const [recents, setRecents] = useState(null);
    const recentEnrichHistoriesRef = useRef();
    recentEnrichHistoriesRef.current = recentEnrichHistories;

    useEffect(() => {
        setLoadingRecent(true);
        recentEnrichHistoriesRef
            .current()
            .then((response) => {
                if (response?.status) setRecents([...response?.data]);
                else toast.error(response?.message ? response?.message : "Something went wrong", { theme: "colored" });
            })
            .catch((error) => {
                toast.error(error?.message ? error?.message : "Something went wrong", {
                    theme: "colored",
                });
            })
            .finally(() => {
                setLoadingRecent(false);
            });
    }, []);

    const handleClickItem = (profile) => {
        kompassNavigate(
            `/search?name=${profile?.name}&title=${profile?.title}&companyName=${profile?.companyName}&nameExactMatch=${profile?.nameExactMatch}`
        );
    };

    return (
        <div className="relative flex w-full flex-col items-center justify-start gap-4 rounded-xl border border-stone-250 bg-white p-4 2xl:p-6">
            <div className="flex flex-col items-center justify-start gap-y-6 self-stretch">
                <div className="inline-flex items-baseline justify-between self-stretch gap-1">
                    <div className="font-Outfit shrink grow basis-0 text-md 2xl:text-lg font-normal text-black">Prospect Searches</div>
                    <div className="flex items-center justify-end gap-8">
                        <button
                            className="font-Outfit cursor-pointer text-sm 2xl:text-base leading-md text-blue-500"
                            onClick={handleClickItem}
                        >
                            View all
                        </button>
                    </div>
                </div>
            </div>

            {!loadingRecent && (
                <div className="relative h-[283px] w-full 2xl:h-[322px]">
                    {recents?.length > 0 ? (
                        <div className="flex h-full w-full cursor-pointer select-none flex-col gap-2 overflow-y-auto">
                            {recents.map((rowData, index) => (
                                <div
                                    key={index}
                                    className="flex w-full flex-row items-center gap-2 rounded-xl border border-stone-250 bg-white p-2"
                                    onClick={() =>
                                        handleClickItem({
                                            name: rowData?.fullName,
                                            title: rowData?.title,
                                            companyName: rowData?.companyName,
                                            nameExactMatch: true,
                                        })
                                    }
                                >
                                    <div className="flex">
                                        <div
                                            className={
                                                "font-OutfitMedium flex h-8 2xl:h-10 w-8 2xl:w-10 items-center justify-center rounded-full text-sm 2xl:text-base" +
                                                [
                                                    " bg-accents-yellow text-black",
                                                    " bg-accents-purple text-black",
                                                    " bg-accents-green text-black",
                                                    " bg-accents-blue text-black",
                                                    " bg-accents-orange text-black",
                                                ][indexString(rowData?.fullName || rowData?.firstName + " " + rowData?.lastName, 5)]
                                            }
                                        >
                                            {getShortName(rowData?.fullName || rowData?.firstName + " " + rowData?.lastName)}
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-col text-sm capitalize">
                                        <p>{rowData?.fullName || rowData?.firstName + " " + rowData?.lastName}</p>
                                    </div>
                                    <div className="font-Outfit text-xs shrink-0">
                                        <p>{rowData?.timeAgo}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <div className="flex shrink grow basis-0 flex-col items-center justify-center gap-6 self-stretch">
                                <div className="mt-12 flex flex-col items-center justify-start gap-6 self-stretch px-6">
                                    <div className="relative h-16 w-16">
                                        <SearchDesignSVGIcon className="absolute left-1 top-1 h-14 w-14" />
                                    </div>
                                    <div className="flex h-16 flex-col items-center justify-start gap-1 self-stretch">
                                        <div className="font-Outfit leading-snug self-stretch text-center text-base font-medium text-black">
                                            No searches yet
                                        </div>
                                        <div className="font-Outfit leading-tight self-stretch text-center text-sm font-normal text-neutral-400">
                                            Use Prospect search to generate targeted lists of decision makers
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="inline-flex items-center justify-center gap-2 rounded-lg border bg-gray-950 px-6 py-3"
                                    onClick={() => {
                                        kompassNavigate("/search");
                                    }}
                                >
                                    <p className="font-OutfitBold text-sm uppercase text-white 2xl:text-base">prospect search</p>
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}

            {loadingRecent && (
                <div className="relative min-h-[380px] w-full">
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform p-4 text-white">
                        <CircularProgress sx={{ left: "50%", top: "50%" }}></CircularProgress>
                    </div>
                </div>
            )}
        </div>
    );
}
