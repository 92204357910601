import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { persist } from "zustand/middleware";
const defaultPrices = {
    main: null,
    phones: null,
    emails: null,
    validations: null,
};

const useBillingStore = create(
    devtools(
        persist(
            (set) => ({
                selectedPrices: defaultPrices,
                plan: null,
                users: 1,
                redirect: false, //route path like 'billingpay'
                changePrices: (update) =>
                    set((state) => {
                        const newPrices = { ...state.selectedPrices, ...update };
                        return { selectedPrices: newPrices };
                    }),
                changePlan: (update) =>
                    set(() => {
                        return { plan: update };
                    }),
                changeUsers: (update) =>
                    set(() => {
                        return { users: update };
                    }),
                cleanPrices: () => {
                    set({ selectedPrices: defaultPrices });
                },
                cleanPlan: () => {
                    set({ plan: null });
                },
                setRedirect: (value) => {
                    set({ redirect: value });
                },
            }),
            {
                name: "pricesStorage",
                partialize: (state) => ({
                    selectedPrices: state.selectedPrices,
                    plan: state.plan,
                    users: state.users,
                    redirect: state.redirect,
                }),
            }
        )
    )
);
export default useBillingStore;
