import { useNavigate, useLocation } from "react-router-dom";
import useLocalStorage from "@/hooks/useLocalStorage";
import { useState, useEffect } from "react";
import { STORAGE_KEY } from "@/utils/constants";
// Off-limits routes by permission group
const protectionMap = {
    0: [],
    1: ["/upgrade", "/billingpay"],
    2: [],
    3: [],
};

//As react-router doesn't have beforeEach methods, and it could be only be implemented via reworking the router, this
//is a quick hack that you could add to layout, to block navigating to route, depending on the permission
export default function useProtectLayout(basicRoute) {
    const location = useLocation();
    const navigate = useNavigate();
    const [flag, setFlag] = useState(false);
    const [userInfo] = useLocalStorage(STORAGE_KEY.USER_INFO, null);
    useEffect(() => {
        if (protectionMap[userInfo?.permission || 0]?.includes(location.pathname)) {
            navigate(basicRoute);
        } else {
            setFlag(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, location]);
    return flag;
}
