import * as React from "react";

function Edit(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                d="M3.333 16.668h-.541a.542.542 0 00.541.541v-.541zm0-3.333l-.383-.384a.542.542 0 00-.158.383h.541zm9.058-9.058l-.383-.383.383.383zm1.885 0l-.383.383.383-.383zm1.448 1.448l.383-.383-.383.383zm0 1.886l-.383-.383.383.383zm-9.057 9.057v.541a.542.542 0 00.383-.158l-.383-.383zM16.28 6.41l.515-.167-.515.167zm0 .515l-.515-.167.515.167zM13.076 3.72l-.168-.515.168.515zm.515 0l.167-.515-.167.515zm-3.208 2.565a.542.542 0 00-.766.766l.766-.766zm2.567 4.1a.542.542 0 00.766-.767l-.766.766zm-9.075 6.283v-3.333H2.792v3.333h1.083zm-.159-2.95l9.058-9.058-.766-.766-9.058 9.057.766.766zM13.893 4.66l1.448 1.448.766-.766-1.448-1.448-.766.766zm1.448 2.568l-9.057 9.057.766.766 9.057-9.057-.766-.766zm-8.674 8.898H3.333v1.083h3.334v-1.083zM15.34 6.108c.171.171.276.277.349.363a.39.39 0 01.076.107l1.03-.335a1.396 1.396 0 00-.28-.473c-.108-.128-.25-.27-.41-.428l-.765.766zm.766 1.886c.159-.159.301-.3.409-.428.112-.132.218-.283.28-.473l-1.03-.335s-.008.026-.076.107a7.02 7.02 0 01-.35.363l.767.766zm-.341-1.416a.292.292 0 010 .18l1.03.335c.09-.276.09-.574 0-.85l-1.03.335zM12.774 4.66c.17-.171.277-.276.362-.349a.39.39 0 01.107-.075l-.335-1.03c-.19.061-.341.167-.473.28-.127.107-.269.25-.428.408l.767.766zm1.885-.766c-.159-.158-.3-.3-.427-.409a1.394 1.394 0 00-.474-.28l-.334 1.03s.026.008.107.076c.085.073.19.178.362.35l.766-.767zm-1.416.342a.292.292 0 01.18 0l.335-1.03a1.375 1.375 0 00-.85 0l.335 1.03zM9.617 7.05l3.333 3.333.766-.766-3.333-3.333-.766.766z"
                fill="#090C05"
            />
        </svg>
    );
}

const MemoEdit = React.memo(Edit);
export default MemoEdit;
