import OpportunitiesPanel from "@/components/advanced_search/OpportunitiesPanel";
import DataAnalyst from "@/components/advanced_search/DataAnalyst";
import Product from "@/components/prospecting/Products";
import LandingLayout from "@/components/LandingLayout";
import SupportPanel from "@/components/landing/SupportPanel";
import WhyKompassAIPanel from "@/components/landing/WhyKompassAIPanel";
import Datapoints from "@/components/landing/common/Datapoints";
import TopBar from "@/components/landing/TopBar";
import ConnectPanel from "@/components/landing/ConnectPanel";
import { Helmet } from "react-helmet";

const topBarData = {
    tag: "Advanced Database Search",
    title: "Prospect clients directly on our platform",
    subTitle: "Power your prospecting with our continuously growing and repeatedly validated database of businesses and professionals",
};

const description = {
    heading: "What is the Advanced Database tool?",
    subHeading:
        "Our platform is powered by a continuously expanding database with multiple levels of cross-references to ensure our data remains fresh and accurate. We refresh our database at least once a month to ensure that our data doesn't become stale.",
};

export default function AdvancedSearchScene() {
    return (
        <>
            <Helmet>
                <title>Automated Warm-Up & Email Tools for B2B Leads | Kompass AI</title>
                <meta
                    name="description"
                    content="Discover automated warm-up services, email automation tools, and strategies for finding quality business leads. Access validated and free B2B emails at Kompass AI."
                />
                <meta
                    name="keywords"
                    content="Automated Warm-Up Services, Email Automation Tools, Strategies For Finding Quality Business Leads, Cold Email Tool For Lead Gen Agency, Validated B2B Emails, Free B2B Emails"
                />
                <link rel="canonical" href="https://kompassai.com/advancedsearch" />
            </Helmet>
            <LandingLayout pageName="advancedsearch" background="#4CD66E">
                <div>
                    <TopBar data={topBarData} background="bg-accents-green" productName="advancedsearch" />
                    <SupportPanel title={false} />
                    <WhyKompassAIPanel data={description} />
                    <OpportunitiesPanel />
                    <DataAnalyst />
                    <Datapoints background="bg-accents-green" opacity="bg-opacity-25" paddingTop="!pt-20" />
                    <Product pageName="advancedsearch" />
                    <ConnectPanel background="bg-accents-green" accent="bg-accents-darkGreen" />
                </div>
            </LandingLayout>
        </>
    );
}
