import useAuth from "@/hooks/useAuth";

const useList = () => {
    const { getAuthToken } = useAuth();

    const getDashboard = async (filter) => {
        const filters = Object.entries(filter || {});
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(
                `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/analytics/getLandingPageInfo?${filters.map(([key, value]) => `${key}=${value}`).join("&")}
        `,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (_resData.ok) {
                return await _resData.json();
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            return {
                data: null,
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    const getMyTeamInfo = async () => {
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/analytics/getMyTeamInfo`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (_resData.ok) {
                return await _resData.json();
            } else {
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            return {
                data: null,
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
    };

    // const getTeamMembers = async ({
    //   searchValue = "",
    //   filterRole = "",
    //   sort = {
    //     sort: -1,
    //     sortBy: "email",
    //   },
    // }) => {
    //   console.log("===============getTeamMembers===============");

    //   let response = null;
    //   const authToken = await getAuthToken();

    //   let url = `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/getTeamMembers?`;

    //   if (searchValue) {
    //     url += `searchValue=${searchValue}&`;
    //   }

    //   if (filterRole) {
    //     url += `filterRole=${filterRole}&`;
    //   }

    //   if (sort) {
    //     url += `sort=${sort.sort}&sortBy=${sort.sortBy}`;
    //   }

    //   try {
    //     const _resData = await fetch(
    //       url,
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     if (_resData.ok) {
    //       const data = await _resData.json();
    //       response = data;
    //     } else {
    //       throw new Error("Something went wrong !");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     response = {
    //       status: false,
    //       message: e.message || "Something went wrong !",
    //     };
    //   }
    //   return response;
    // };

    // const updateTeamMember = async (params) => {
    //   const { creditsMonthly, creditsAnnual, role, id } = params;
    //   let response = null;
    //   const authToken = await getAuthToken();
    //   try {
    //     const _resData = await fetch(
    //       `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/updateMember`,
    //       {
    //         method: "PUT",
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           creditsMonthly,
    //           creditsAnnual,
    //           role,
    //           id,
    //         }),
    //       }
    //     );

    //     if (_resData.ok) {
    //       const data = await _resData.json();
    //       response = data;
    //     } else {
    //       throw new Error("Something went wrong !");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     response = {
    //       status: false,
    //       message: e.message || "Something went wrong !",
    //     };
    //   }
    //   return response;
    // };

    // const acceptInvitation = async (params) => {
    //   const { id } = params;
    //   let response = null;
    //   const authToken = await getAuthToken();
    //   try {
    //     const _resData = await fetch(
    //       `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/acceptInvitation`,
    //       {
    //         method: "PUT",
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ id }),
    //       }
    //     );

    //     if (_resData.ok) {
    //       const data = await _resData.json();
    //       response = data;
    //     } else {
    //       throw new Error("Something went wrong !");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     response = {
    //       status: false,
    //       message: e.message || "Something went wrong !",
    //     };
    //   }
    //   return response;
    // };

    // // Reject Invitation
    // const rejectInvitation = async (params) => {
    //   const { id } = params;
    //   let response = null;
    //   const authToken = await getAuthToken();
    //   try {
    //     const _resData = await fetch(
    //       `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/rejectInvitation`,
    //       {
    //         method: "PUT",
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ id }),
    //       }
    //     );

    //     if (_resData.ok) {
    //       const data = await _resData.json();
    //       response = data;
    //     } else {
    //       throw new Error("Something went wrong !");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     response = {
    //       status: false,
    //       message: e.message || "Something went wrong !",
    //     };
    //   }
    //   return response;
    // };

    // const getInvitationsCount = async (params) => {
    //   let response = null;
    //   const authToken = await getAuthToken();
    //   try {
    //     const _resData = await fetch(
    //       `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/getInvitationsCount`,
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     if (_resData.ok) {
    //       const data = await _resData.json();
    //       response = data;
    //     } else {
    //       throw new Error("Something went wrong !");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     response = {
    //       status: false,
    //       message: e.message || "Something went wrong !",
    //     };
    //   }
    //   return response;
    // };

    // const getCreditsInfo = async (params) => {
    //   let response = null;
    //   const authToken = await getAuthToken();
    //   try {
    //     const _resData = await fetch(
    //       `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/team/getCreditsInfo`,
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: `Bearer ${authToken}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     if (_resData.ok) {
    //       const data = await _resData.json();
    //       response = data;
    //     } else {
    //       throw new Error("Something went wrong !");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     response = {
    //       status: false,
    //       message: e.message || "Something went wrong !",
    //     };
    //   }
    //   return response;
    // };

    return {
        getDashboard,
        getMyTeamInfo,
    };
};

export default useList;
