import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "../src/css/index.css";
// import App from './App';
import Router from "./router";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import { CssBaseline, createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { typography } from "@/theme/typography";
import { AuthProvider } from "@/context/AuthContext";
import { palette } from "./theme/palette";
import DisableZoom from "./pages/DisableZoom";
const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme({
    typography: typography,
    palette: palette,
});
const queryClient = new QueryClient();
//WARN: if you want to test Stripe stuff locally (something that would include working with customization or user sliders)
//remove React.StrictMode from here as it would break Stripe form (by loading it multiple times)
root.render(
    <GoogleOAuthProvider clientId="389225546608-41m8ql3kev01h135ukoq607g2jq1521o.apps.googleusercontent.com">
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <DisableZoom />
                <CssBaseline />
                <BrowserRouter>
                    <AuthProvider>
                        <Router />
                    </AuthProvider>
                </BrowserRouter>
                <ToastContainer />
            </ThemeProvider>
        </QueryClientProvider>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
