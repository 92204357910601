const tabData = [
    { label: "Analytics" },
    { label: "Website Visitors" },
    { label: "Profile Exports" },
    { label: "Target Customer Persona" },
    { label: "Account Details" },
];

function NavigationTabs({ setTabOpen, tabOpen }) {
    return (
        <nav className="font-Outfit flex flex-col gap-4 px-[24px] items-start md:flex-row md:items-center md:gap-6 text-[16px] xl:text-[18px] text-black border-b border-[#E7E7E7]">
            {tabData.map((tab, index) => (
                <button
                    key={index}
                    className={`self-stretch pt-[16px] pb-[21px] border-b-[3px] border-white ${tabOpen === tab.label ? "!border-accents-blue2 !text-accents-blue2" : ""}`}
                    onClick={() => {
                        setTabOpen(tab.label);
                        tabData.forEach(function (t) {
                            if (t.label === tab.label) {
                                t.isActive = true;
                            } else {
                                t.isActive = false;
                            }
                        });
                    }}
                >
                    {tab.label}
                </button>
            ))}
        </nav>
    );
}

export default NavigationTabs;
