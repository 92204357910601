import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Backdrop } from "@mui/material";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import BulkResultTable from "@/components/bulk/BulkResultTable";
import BulkUploadWidget from "@/components/bulk/BulkUploadWidget";
import useCRMPushResultStore from "@/store/useCRMPushResultStore";
import CRMUploadResultsTable from "@/components/common/CRMElements/CRMUploadTable";
import { motion, AnimatePresence } from "framer-motion";
import MainTitleBar from "@/components/MainTitleBar";
import useAuth from "@/hooks/useAuth";
import { toast } from "react-toastify";
import EmailUploadWidget from "@/components/email_validation/EmailUploadWidget";

export default function BulkEnrichScene() {
    const { show, loading: crmLoading } = useCRMPushResultStore();
    const [currentWidgetIndex, setCurrentWidgetIndex] = useState(3);
    const [userInfo, setUserInfo] = useState();
    const { getUserInfo } = useAuth();
    const getUserInfoRef = useRef();
    getUserInfoRef.current = getUserInfo;
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getUserInfoRef
            .current()
            .then((response) => {
                setUserInfo(response?.userInfo);
            })
            .catch((error) => {
                toast.error(error?.message, { theme: "colored" });
            });
    }, []);

    const path = location.pathname;

    return (
        <MainLayout loading={crmLoading || isLoading}>
            <>
                <AnimatePresence>
                    {show && (
                        <motion.div className="w-full" initial={{ opacity: 0, zIndex: 11 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <Backdrop
                                open
                                className="!absolute"
                                sx={{
                                    backgroundColor: "rgba(0,0,0,0.18)",
                                    backdropFilter: "blur(2px)",
                                    zIndex: 11,
                                }}
                            >
                                <CRMUploadResultsTable></CRMUploadResultsTable>
                            </Backdrop>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>

            {path === "/bulkenrich/upload" && (
                <>
                    <MainTitleBar>
                        <div className="flex items-end justify-start">
                            <p>CSV Enrich</p>
                        </div>
                    </MainTitleBar>

                    <BaseContainer>
                        <BulkUploadWidget
                            currentWidgetIndex={currentWidgetIndex}
                            setCurrentWidgetIndex={setCurrentWidgetIndex}
                            userInfo={userInfo}
                            setLoading={setLoading}
                        />
                    </BaseContainer>
                </>
            )}

            {path === "/bulkenrich/validate-emails" && (
                <>
                    <MainTitleBar>
                        <div className="flex items-end justify-start">
                            <p>CSV Enrich</p>
                        </div>
                    </MainTitleBar>

                    <BaseContainer>
                        <EmailUploadWidget
                            currentWidgetIndex={currentWidgetIndex}
                            setCurrentWidgetIndex={setCurrentWidgetIndex}
                            setLoading={setLoading}
                        />
                    </BaseContainer>
                </>
            )}

            {(path === "/bulkenrich" || path === "/bulkenrich/") && (
                <>
                    <MainTitleBar>
                        <div className="flex items-end justify-start">
                            <p>CSV Enrich</p>
                        </div>
                    </MainTitleBar>
                    <BaseContainer>
                        <BulkResultTable setCurrentWidgetIndex={setCurrentWidgetIndex} setLoading={setLoading} />
                    </BaseContainer>
                </>
            )}
        </MainLayout>
    );
}
