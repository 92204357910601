import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 7,
        position: "relative",
        backgroundColor: "white",
        borderColor: "#80bdff",
        border: "none",
        fontSize: 12,
        fontFamily: "Outfit",
        padding: "1px 50px 1px 4px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 7,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
            fontFamily: "Outfit",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
});

export default function SingleListAutoComplete({
    value = [],
    options,
    multiple = false,
    onFilterChange,
    placeholder = null,
    disabled = false,
}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#D6E0FF",
                contrastText: "#000000",
                contrast: "#D6E0FF",
                light: "#D6E0FF",
            },
        },
    });

    const handleChange = (event, newValue) => {
        if (multiple) {
            let values;

            values = newValue?.map((item) => {
                return item?.value || item;
            });

            onFilterChange(values);
        } else {
            onFilterChange(newValue);
        }
    };

    return (
        <StyledAutocomplete
            multiple={multiple}
            disabled={disabled}
            sx={{ padding: 0 }}
            value={multiple ? options?.filter((v) => value?.findIndex((item) => item === v?.value || item === v) >= 0) : value}
            options={[
                // ...(multiple ? [{ label: "Select all", value: "select all" }] : []),
                ...options,
            ]}
            disableCloseOnSelect={multiple}
            noOptionsText="No options available"
            getOptionLabel={(option) => option?.label ?? option}
            disablePortal
            renderInput={(params) => (
                <BootstrapAutocompleteInput
                    {...params}
                    placeholder={placeholder}
                    sx={{ paddingTop: "0px !important", paddingBottom: "0px !important", fontFamily: "Outfit" }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                    <ThemeProvider theme={theme} key={index}>
                        <Chip
                            label={option?.label ?? option}
                            size="small"
                            color="primary"
                            {...getTagProps({ index })}
                            deleteIcon={
                                <ClearIcon
                                    sx={{
                                        color: "black", // Black cross
                                        backgroundColor: "transparent", // No background
                                        borderRadius: "50%",
                                        width: 10,
                                        height: 10,
                                    }}
                                />
                            }
                            sx={{
                                "& .MuiChip-deleteIcon": {
                                    margin: "0 8px 0 -4px",
                                },
                                "& span": {
                                    fontFamily: "Outfit",
                                    fontSize: "12px",
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                    paddingRight: "12px",
                                },
                                borderRadius: "4px", // Border radius for Chip
                            }}
                        />
                    </ThemeProvider>
                ))
            }
            onChange={handleChange}
            renderOption={(props, option, { selected }) => {
                const { key, className, ...restProps } = props;
                return (
                    <>
                        <li
                            key={key}
                            {...restProps}
                            className={`${className} font-Outfit hover:!bg-dgray-100 rounded-lg px-2 ml-2 mr-0.5 text-sm ${selected ? "!bg-dgray-100 font-[600]" : "bg-transparent"}`}
                        >
                            {option.label}
                        </li>
                    </>
                );
            }}
        />
    );
}
