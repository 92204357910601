import { Box } from "@mui/material";
import { ReactComponent as LearnSVG } from "@/assets/image/base/learn.svg";
import RightArrow from "@/components/svgComponent/RightArrow";
import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { goToTop, menuProductList, menuResourceLearnList } from "@/utils/common";

export default function Navbar(props) {
    return (
        <div className="w-full">
            {props?.isProduct && <MenuProducts />}
            {props?.isResources && <MenuResources />}
        </div>
    );
}

const MenuProducts = () => {
    const [hoverIndex, setHoverIndex] = useState(null);
    return (
        <Box className="flex w-full flex-col px-[20px] py-[26px] 2xl:px-6 2xl:py-8">
            <Box className="flex w-full flex-col">
                <Box className="flex flex-col items-start gap-4">
                    {menuProductList.map((item, ind) => {
                        const isHovered = hoverIndex === ind;
                        return (
                            <HashLink
                                key={ind}
                                to={item.router}
                                className="flex items-center justify-between gap-2 self-stretch"
                                onClick={goToTop}
                                onMouseEnter={() => setHoverIndex(ind)}
                                onMouseLeave={() => setHoverIndex(null)}
                            >
                                <Typography className="peer font-Outfit !text-sm 2xl:text-base text-stone-950 hover:text-[#0a85ea]">
                                    {item.label}
                                </Typography>
                                <Box className="peer-hover:translate-x-1">
                                    <RightArrow color={isHovered ? "#0a85ea" : "#0A2E31"} />
                                </Box>
                            </HashLink>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

const MenuResources = () => {
    const [hoverIndex, setHoverIndex] = useState(null);
    return (
        <Box className="flex w-full flex-col gap-8 px-[20px] py-[26px] 2xl:px-6 2xl:py-8 min-w-[150px]">
            <Box className="flex w-full flex-col gap-4">
                <Box className="flex flex-col items-center self-stretch lg:hidden">
                    <Box className="flex items-center gap-2 self-stretch">
                        <LearnSVG className="h-6 w-6 fill-current text-[#4873FA]" />
                        <p className="font-Outfit text-[24px] leading-[1.2] font-normal text-[#4873FA] ">Learn</p>
                    </Box>
                </Box>
                <Box className="flex flex-col items-start gap-4">
                    {menuResourceLearnList.map((item, ind) => {
                        const isHovered = hoverIndex === ind;
                        return (
                            <Link
                                key={ind}
                                to={item.router}
                                className="flex items-center gap-2 self-stretch"
                                onClick={goToTop}
                                onMouseEnter={() => setHoverIndex(ind)}
                                onMouseLeave={() => setHoverIndex(null)}
                            >
                                <Typography className="peer font-Outfit !text-sm 2xl:text-base text-stone-950 hover:text-[#0a85ea]">
                                    {item.label}
                                </Typography>
                                <Box className="peer-hover:translate-x-1">
                                    <RightArrow color={isHovered ? "#0a85ea" : "#0A2E31"} />
                                </Box>
                            </Link>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};
