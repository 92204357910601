import useAuth from "@/hooks/useAuth";

const useEnrichment = () => {
    const { getAuthToken } = useAuth();

    const postEnrichContact = async (params, enrichType) => {
        console.log("===============postEnrichContact===============");
        console.log(`params: ${JSON.stringify(params)}`);

        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/enrich/${enrichType}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });
            const data = await _resData.json();
            if (_resData.ok) {
                response = data;
            } else {
                throw new Error(data?.message || "Something went wrong!");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong!",
            };
        }
        return response;
    };

    const recentEnrichHistories = async () => {
        // console.log('===============recentEnrichHistories===============');
        let response = null;
        const authToken = await getAuthToken();
        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/enrich/recent`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            });

            response = await _resData.json();
            if (!response?.status) {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    return {
        postEnrichContact,
        recentEnrichHistories,
    };
};

export default useEnrichment;
