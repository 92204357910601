import React, { useState } from "react";
import { TextField } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";

export default function CustomInput({ id, selectedValues, onSelectionChange, onRemove, placeholder = "Enter items", sx = {} }) {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && inputValue.trim()) {
            onSelectionChange(inputValue.trim());
            setInputValue(""); // Clear input after adding
        }
    };

    const addClick = () => {
        if (inputValue.trim()) {
            onSelectionChange(inputValue.trim());
            setInputValue(""); // Clear input after adding
        }
    };

    return (
        <div className="flex gap-5 flex-wrap items-center">
            <div className="w-full md:w-1/4 relative">
                <TextField
                    id={id}
                    variant="outlined"
                    placeholder={placeholder}
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#E8E7E7",
                            },
                            backgroundColor: "#f5f5f5", // Matches bg-stone-100
                            padding: "1px 8px !important",
                            fontSize: "0.875rem", // Matches text-sm
                            borderRadius: "0.375rem", // Matches rounded-md
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E8E7E7", // Maintain border color when focused
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E8E7E7", // Maintain border color on hover
                            },
                        },
                        "& .MuiInputAdornment-root": {
                            marginRight: "8px",
                        },
                        ...sx, // Allow additional styling to be passed in
                    }}
                    InputProps={{
                        startAdornment: (
                            <>
                                <SearchOutlinedIcon sx={{ color: "#a1a1aa", marginRight: "5px" }} />
                            </>
                        ),
                    }}
                />

                <button
                    onClick={addClick}
                    className="bg-black absolute top-1/2 -translate-y-1/2 right-[6px] text-white flex items-center gap-2 px-2 py-1 h-[26px] text-sm rounded"
                >
                    {" "}
                    <span className="text-base mt-[1px]">+</span> Add
                </button>
            </div>

            {selectedValues.map((item) => (
                <div key={item} className="flex items-center gap-2 py-2 px-3 text-sm border border-stone-400 rounded-md">
                    <span>{item}</span>
                    <CloseIcon className="cursor-pointer" fontSize="small" onClick={() => onRemove(item)} />
                </div>
            ))}
        </div>
    );
}
