import React from "react";

function LetterBox({ letter, isHighlighted = false, onClick }) {
    const baseClasses =
        "flex justify-center items-center px-2 py-2 sm:px-2 sm:py-4 w-16 h-12 rounded-lg border border-solid shadow-lg border-neutral-200 cursor-pointer";
    const highlightedClasses = isHighlighted ? "font-semibold text-white bg-blue-500" : "bg-white";

    return (
        <div
            className={`${baseClasses} ${highlightedClasses}`}
            role="button"
            tabIndex="0"
            aria-label={`Letter ${letter}`}
            onClick={onClick}
        >
            {letter}
        </div>
    );
}

export default LetterBox;
