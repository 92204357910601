import LandingLayout from "@/components/LandingLayout";
import BaseContainer from "@/components/BaseContainer";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import LeftPannel from "@/components/blog_post/LeftPannel";
import RelatedPannel from "@/components/blog_post/RelatedPannel";
import Article from "@/components/blog_post/Article";
import MainLoader from "@/components/MainLoader";
import useStrapi from "@/hooks/useStrapi";
import MostRecentPannel from "@/components/blog_post/MostRecentPannel";
import StickyTitle from "@/components/blog_post/StickyTitle";

export default function BlogPostScene() {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [isLoading, setLoading] = useState(null);
    const [isStickyTitleShown, setStickyTitleShown] = useState(false);
    const [h2Contents, setH2Contents] = useState([]);
    const { fetchPostById } = useStrapi();
    const fetchPostByIdRef = useRef();
    fetchPostByIdRef.current = fetchPostById;

    useEffect(() => {
        if (!postId) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                const fetchedPost = await fetchPostByIdRef.current(postId);
                setPost(fetchedPost);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [postId]);

    useEffect(() => {
        if (!post) return;
        const h2Tags = document.querySelectorAll("h2");
        const contents = Array.from(h2Tags).map((h2) => {
            return h2 ? h2.textContent.trim() : "";
        });
        setH2Contents(contents);
    }, [post]);

    const scrollToContent = (content) => {
        const h2Tags = document.querySelectorAll("h2");
        h2Tags.forEach((h2) => {
            const headerHeight = 75;
            if (h2 && h2.textContent.trim() === content) {
                const topOffset = h2.offsetTop - headerHeight;
                window.scrollTo({ top: topOffset, behavior: "smooth" });
            }
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const heroSection = document.getElementById("blog-post-hero");
            if (heroSection) {
                const rect = heroSection.getBoundingClientRect();
                const isPastHero = rect.bottom < 0;
                setStickyTitleShown(isPastHero);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <LandingLayout background="#090C05" inverse={true} pageName="blog">
            {isLoading && <MainLoader />}
            {!isLoading && (
                <>
                    <div id="blog-post-hero" className="flex flex-col items-center pt-12 pb-24 px-5 bg-stone-950">
                        <div className="rounded-[40px] px-2 py-1 lg:px-4 lg:py-2.5 text-[16px] 2xl:text-[20px] normal-case leading-[1.4] font-[400] font-Outfit text-white bg-accents-blue2">
                            {post?.attributes?.postTag || "Blog"}
                        </div>
                        <h1 className="mt-4 main-title !text-white max-w-[1000px] !leading-[1.2]">{post?.attributes?.postTitle || ""}</h1>
                    </div>
                    {isStickyTitleShown && post?.attributes?.postTitle && <StickyTitle title={post?.attributes?.postTitle} />}
                    <BaseContainer>
                        <div className="grid grid-cols-1 lg:grid-cols-[245px_auto] 2xl:grid-cols-[285px_auto] gap-10 2xl:gap-20 pb-10 lg:py-20">
                            <LeftPannel titles={h2Contents} scrollToContent={scrollToContent} />
                            <div className="flex flex-col gap-10">
                                <div
                                    style={{
                                        backgroundImage: `url(${post?.attributes?.postCover?.data?.attributes?.url})`,
                                    }}
                                    className="bg-center bg-cover bg-no-repeat w-full h-[179px] sm:h-[279px] md:sm:h-[379px] lg:h-[444px] rounded-[30px]"
                                ></div>
                                <div className="grid grid-cols-1 lg:grid-cols-[auto_283px] 2xl:grid-cols-[auto_323px] gap-10">
                                    {post && <Article post={post} />}
                                    <RelatedPannel tag={post?.attributes?.postTag || ""} currentId={post?.id} />
                                </div>
                            </div>
                        </div>
                    </BaseContainer>
                    <MostRecentPannel currentId={post?.id} />
                </>
            )}
        </LandingLayout>
    );
}
