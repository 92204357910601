import React, { useRef, useState } from "react";
import useScheduledEmails from "@/hooks/useScheduledEmails";
import AffirmationModal from "../AffirmationModal";

function ActionButton({ type, scheduledEmailId, onApproved }) {
    const { approveEmails } = useScheduledEmails();
    const approveEmailsRef = useRef();
    const [isModalVisible, setIsModalVisible] = useState(false);

    approveEmailsRef.current = approveEmails;

    const isApprove = type === "approve";
    const buttonClass = isApprove
        ? "flex gap-1 justify-center items-center py-2 pr-3 pl-3 text-white bg-blue-500 rounded-lg border border-blue-500 border-solid min-h-[36px] w-[101px]"
        : "flex gap-1 justify-center items-center px-3 py-2 text-blue-500 bg-white rounded-lg border border-blue-500 border-solid min-h-[36px] w-[101px]";
    const iconSrc = isApprove
        ? "https://cdn.builder.io/api/v1/image/assets/TEMP/cccc7cfe73d248c5d1859c9b44f91d17ed652fd74d8baec61f4496b96447f2a0?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79"
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/95d2829c05069058262a6044d5108236be87e257db1cec9a5f1152731b578ca4?placeholderIfAbsent=true&apiKey=bf4e6dcb676841ec9c4d776f9502fd79";
    const buttonText = isApprove ? "Approve" : "Decline";

    const approveMail = async () => {
        const response = await approveEmailsRef.current([scheduledEmailId], isApprove);
        setIsModalVisible(false);
        onApproved();
    };

    const handleButtonClick = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <button className={buttonClass} onClick={handleButtonClick}>
                <img loading="lazy" src={iconSrc} className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square" alt="" />
                <span className="self-stretch my-auto">{buttonText}</span>
            </button>
            {isModalVisible && (
                <AffirmationModal
                    message={isApprove ? "Are you sure you want to approve this email?" : "Are you sure you want to decline this email?"}
                    handleConfirm={approveMail}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
}

export default ActionButton;
