import React, { useEffect, useState } from "react";
import { Country, State } from "country-state-city";
import { Button } from "@material-tailwind/react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AccordianComponent } from "@/components/search/AccordianComponents";
import { FilterInputProps } from "@/theme/typography";
import UserIcon from "@/assets/image/search/user.svg";
import HomeIcon from "@/assets/image/search/home.svg";
import LocationIcon from "@/assets/image/search/location.svg";
import DepartmentIcon from "@/assets/image/search/department.svg";
import JobTitleIcon from "@/assets/image/search/jobtitle.svg";
import SubDepartmentIcon from "@/assets/image/search/sub-department.svg";
import PLocationIcon from "@/assets/image/search/p-location.svg";
import CountryIcon from "@/assets/image/search/country.svg";
import StateIcon from "@/assets/image/search/state.svg";
import JobLevelIcon from "@/assets/image/search/level-icon.svg";
import USDIcon from "@/assets/image/search/usd.svg";
import WebsiteAdvancedIcon from "@/assets/image/search/website.svg";
import LinkIcon from "@/assets/image/search/base-link.svg";
import { ScrollArea } from "@/components/common/ScrollArea";
import SingleListAutoComplete from "./SingleListAutoComplete";

const GrayTextField = styled(TextField)({
    "& label.Mui-focused": {
        color: "#E8E7E7",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#E8E7E7",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
    "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        boxShadow: "inset 0 0 0px 1000px transparent",
    },
});

export default function SingleListFilters({ setOpenRowIndex, setFilters, filters, filterTableData, allOptions }) {
    const [isExpandAllTitles, setIsExpandAllTitles] = useState(false);
    const [activeFilterCount, setActiveFilterCount] = useState(0);

    const countryListForIsoCode = Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));

    useEffect(() => {
        const countActiveFilters = () => {
            let count = 0;
            Object.values(filters).forEach((filter) => {
                if (Array.isArray(filter)) {
                    // Count arrays with at least one item
                    if (filter.length > 0) count++;
                } else if (typeof filter === "object" && filter !== null) {
                    // Count objects with non-empty 'min' or 'max'
                    if (filter.min || filter.max) count++;
                } else if (filter) {
                    // Count non-empty strings and non-null values
                    count++;
                }
            });
            return count;
        };
        setActiveFilterCount(countActiveFilters());
    }, [filters]);

    const [heightClssName, setHeightClssName] = React.useState("lg:h-[calc(100vh-298px)] 2xl:h-[calc(100vh-331px)]");
    useEffect(() => {
        if (activeFilterCount) {
            setHeightClssName("lg:h-[calc(100vh-307px)] 2xl:h-[calc(100vh-340px)]");
        } else {
            setHeightClssName("lg:h-[calc(100vh-271px)] 2xl:h-[calc(100vh-304px)]");
        }
    }, [activeFilterCount]);

    const onClearFilterClicked = () => {
        setFilters({
            name: "",
            website: null,
            title: null,
            companyName: [],
            companyLocation: [],
            personalLocation: [],
            dept: null,
            subDepartment: null,
            level: null,
            numberOfEmployees: null,
            revenue: "",
            fundingRaised: { min: "", max: "" },
            linkedinUrl: "",
            industryName: null,
            country: [],
            state: [],
        });
    };

    return (
        <div className="w-full grow flex flex-col justify-between">
            <div className={"mb-6 flex w-full flex-col grow"}>
                <div className="px-4 py-6 border-b-2 border-stone-400">
                    <div className="flex w-full flex-row items-center justify-between gap-2">
                        <p className="font-Outfit lg:text-[16px] xl:text-[18px] text-stone-950">Filters</p>
                    </div>
                    <div className="">
                        {activeFilterCount ? (
                            <div className="flex items-center gap-2 mt-5">
                                <p className="text-sm text-stone-950">Filters: </p>
                                <div className=" px-2 py-1 flex gap-3 items-center bg-highlight rounded">
                                    <p className="font-Outfit text-xs font-normal leading-[1.2] text-black ">{activeFilterCount}</p>
                                </div>
                                <p
                                    onClick={onClearFilterClicked}
                                    className="text-sm text-dgray-500 ml-auto hover:opacity-90 cursor-pointer"
                                >
                                    Clear All
                                </p>
                            </div>
                        ) : null}
                    </div>
                </div>
                <button
                    onClick={() => setIsExpandAllTitles((prev) => !prev)}
                    className="font-Outfit px-6 py-4 text-sm text-dgray-500 font-md ml-auto hover:opacity-90 cursor-pointer text-right"
                >
                    {isExpandAllTitles ? "Hide all" : "Expand All"}
                </button>

                <div className={`flex w-full flex-col ${heightClssName}`}>
                    <ScrollArea>
                        <h2 className="text-dgray-600 text-sm text-start px-4">Contact info</h2>
                        <AccordianComponent title="Full Name" icon={UserIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <GrayTextField
                                    value={filters.name}
                                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                                    className="w-full"
                                    placeholder="First and last name"
                                    InputProps={FilterInputProps}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent title="Country" icon={CountryIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.country}
                                    options={allOptions.country}
                                    onFilterChange={(selectedCountries) => {
                                        setFilters({ ...filters, country: selectedCountries });
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent title="State/Province" icon={StateIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.state}
                                    options={allOptions.state}
                                    onFilterChange={(selectedStates) => {
                                        setFilters({ ...filters, state: selectedStates });
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent title="Personal Location" icon={PLocationIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.personalLocation}
                                    options={allOptions.personalLocation}
                                    onFilterChange={(selectedLocations) => {
                                        setFilters({ ...filters, personalLocation: selectedLocations });
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        <h2 className="text-dgray-600 text-sm text-start mt-3 px-4">Job info</h2>
                        <AccordianComponent title="Job Title" icon={JobTitleIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <SingleListAutoComplete
                                value={filters.title}
                                multiple={true}
                                options={allOptions.title}
                                onFilterChange={(title) => setFilters({ ...filters, title: title })}
                            />
                        </AccordianComponent>
                        <AccordianComponent title="Job Level" icon={JobLevelIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.level}
                                    options={allOptions.level}
                                    onFilterChange={(value) => {
                                        setFilters({ ...filters, level: value });
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent title="Department" isAddBorder={true} icon={DepartmentIcon} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.dept}
                                    options={allOptions.dept}
                                    onFilterChange={(value) => {
                                        setFilters({ ...filters, dept: value });
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent
                            title="Sub-Department"
                            icon={SubDepartmentIcon}
                            isAddBorder={true}
                            expandAll={isExpandAllTitles}
                        >
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.subDepartment}
                                    options={allOptions.subDepartment}
                                    onFilterChange={(value) => setFilters({ ...filters, subDepartment: value })}
                                />
                            </div>
                        </AccordianComponent>

                        <h2 className="text-dgray-600 text-sm text-start mt-3 px-4">Company Info</h2>
                        <AccordianComponent title="Company Name" icon={HomeIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    value={filters.companyName}
                                    multiple={true}
                                    options={allOptions.companyName}
                                    onFilterChange={(company) => setFilters({ ...filters, companyName: company })}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent title="Website" icon={WebsiteAdvancedIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    value={filters.website}
                                    multiple={true}
                                    options={allOptions.website}
                                    onFilterChange={(website) => setFilters({ ...filters, website: website || "" })}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent title="Industry" icon={LocationIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <SingleListAutoComplete
                                    multiple={true}
                                    value={filters.industryName}
                                    options={allOptions.industryName}
                                    onFilterChange={(selectedIndustry) => {
                                        setFilters({ ...filters, industryName: selectedIndustry });
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        {allOptions.revenue?.length > 0 && (
                            <AccordianComponent title="Company Revenue" icon={USDIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                                <div className="mt-1 w-full">
                                    <SingleListAutoComplete
                                        multiple={true}
                                        value={filters.revenue}
                                        options={allOptions.revenue}
                                        onFilterChange={(selectedOption) => setFilters({ ...filters, revenue: selectedOption?.value })}
                                    />
                                </div>
                            </AccordianComponent>
                        )}
                        {allOptions.numberOfEmployees?.length > 0 && (
                            <AccordianComponent title="Size" icon={DepartmentIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                                <div className="mt-1 w-full">
                                    <SingleListAutoComplete
                                        multiple={true}
                                        value={filters.numberOfEmployees}
                                        options={allOptions.numberOfEmployees}
                                        onFilterChange={(value) => setFilters({ ...filters, numberOfEmployees: value })}
                                    />
                                </div>
                            </AccordianComponent>
                        )}
                        <AccordianComponent title="Company Location" icon={PLocationIcon} isAddBorder={true} expandAll={isExpandAllTitles}>
                            <div className="mt-1 flex w-full flex-col gap-1">
                                <SingleListAutoComplete
                                    value={filters.companyLocation}
                                    multiple={true}
                                    options={allOptions.companyLocation}
                                    onFilterChange={(location) => setFilters({ ...filters, companyLocation: location })}
                                />
                            </div>
                        </AccordianComponent>
                        <h2 className="text-dgray-600 text-sm text-start mt-3 px-4">Social Presence</h2>
                        <AccordianComponent title="Linkedin URL" icon={LinkIcon} isAddBorder={false} expandAll={isExpandAllTitles}>
                            <div className="mt-1 w-full">
                                <GrayTextField
                                    value={filters.linkedinUrl}
                                    onChange={(e) => setFilters({ ...filters, linkedinUrl: e.target.value })}
                                    className="w-full"
                                    placeholder="linkedin.com/in/somename"
                                    InputProps={FilterInputProps}
                                />
                            </div>
                        </AccordianComponent>
                    </ScrollArea>
                </div>
            </div>
            <div className="lg:sticky bottom-0 z-10 flex w-full flex-row items-center justify-between border-y border-l border-stone-250 bg-white">
                <Button
                    onClick={() => {
                        setOpenRowIndex(null);
                        filterTableData();
                    }}
                    className="font-Outfit m-5 w-full rounded-md bg-stone-950 p-3 text-base font-normal capitalize text-white"
                >
                    Search
                </Button>
            </div>
        </div>
    );
}
