import React from "react";
import InfoCard from "./InfoCard";
import ScheduleDetails from "./ScheduleDetails";
import EditDropdown from "../EditDropdown";
import { formatDateTime } from "@/utils/common";

function ScheduledMessage({ data, onCardRemove, handleEditEmail }) {
    return (
        <article className="flex gap-5 items-start text-sm my-7">
            <div className="flex flex-col flex-1 shrink items-start p-4 w-full bg-white rounded-lg border border-solid basis-0 border-neutral-200 min-w-[240px] max-md:max-w-full">
                <header className="flex flex-wrap gap-10 justify-between items-center self-stretch w-full max-md:max-w-full">
                    <div className="flex gap-2 self-stretch my-auto">
                        <span className="gap-0.5 self-stretch px-2 py-1 my-auto text-center whitespace-nowrap rounded bg-neutral-100 text-zinc-950">
                            Scheduled
                        </span>
                        <time className="gap-3 self-stretch px-2 py-2 h-full rounded text-zinc-500">
                            {formatDateTime(data.scheduledDateUTC)}
                        </time>
                    </div>
                    <EditDropdown
                        data={data}
                        onDecline={() => onCardRemove(data.id)}
                        scheduledEmailId={data.id}
                        handleEditEmail={handleEditEmail}
                    />
                </header>

                <ScheduleDetails
                    name={data.name}
                    email={data.email}
                    emailDays={data.emailDays}
                    emailStartHour={data.emailStartHour}
                    emailEndHour={data.emailEndHour}
                    campaignStartDate={data.campaignStartDate}
                />
                <InfoCard title={data.subject} content={data.body} />
            </div>
        </article>
    );
}

export default ScheduledMessage;
