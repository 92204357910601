import useAuth from "@/hooks/useAuth";

const useProfile = () => {
    const getKompassProfile = async (params) => {
        let response = null;
        const { userName, userId, url, type, skip, initial } = params;
        try {
            const _resData = await fetch(
                `${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/profile/getKompassProfile?type=${type}&userName=${userName}&userId=${userId}&url=${url}&skip=${skip}&initial=${initial}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("This is response data: ", _resData);

            if (_resData.ok) {
                const data = await _resData.json();
                response = data;
            } else {
                if (_resData.status === 400) {
                    return await _resData.json();
                }
                throw new Error("Something went wrong !");
            }
        } catch (e) {
            console.log(e);
            response = {
                status: false,
                message: e.message || "Something went wrong !",
            };
        }
        return response;
    };

    return {
        getKompassProfile,
    };
};

export default useProfile;
