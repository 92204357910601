export default function SortIcon() {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.638446 4.53169L3.62295 0.812688C3.88845 0.468688 4.32595 0.468688 4.59195 0.812688L7.57645 4.53169C7.84195 4.87569 7.71695 5.15669 7.26395 5.15669H0.951446C0.498446 5.15669 0.373446 4.87519 0.638946 4.53169H0.638446ZM7.57595 7.46919L4.59145 11.1882C4.32595 11.5322 3.88845 11.5322 3.62245 11.1882L0.637946 7.46919C0.372446 7.12519 0.497446 6.84419 0.950446 6.84419H7.26295C7.71595 6.84419 7.84095 7.12569 7.57545 7.46919H7.57595Z"
                fill="black"
            />
        </svg>
    );
}
