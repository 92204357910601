import React from "react";

import { useQuery } from "@tanstack/react-query";
import { useHTTPRequestObject } from "@/hooks/useHTTPRequest";
import { ReactComponent as StripeIcon } from "@/assets/image/logos/stripe.svg";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { Skeleton } from "@mui/material";

// function AddPaymentMethod({ stripe }) {
//   <Elements stripe={stripe}>
//     <CardElement ></CardElement>
//   </Elements>;
// }

// export default AddPaymentMethod;
const options = {
    ui_mode: true,
    mode: "setup",
};
function AddPaymentMethod({ stripe }) {
    const fetchClientSecret = useHTTPRequestObject({
        link: "/billing/getSetupLink",
        method: "POST",
    });

    const { data, isLoading } = useQuery({
        queryKey: ["PaymentLinkSetup"],
        queryFn: () => fetchClientSecret(JSON.stringify(options)),
    });
    // useEffect(() => {
    //   if (data.status) {
    //   }
    // }, [data]);
    return (
        <>
            {data && !isLoading && (
                <div id="checkout" className="w-full">
                    <EmbeddedCheckoutProvider stripe={stripe} options={{ clientSecret: data.data.client_secret }}>
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                </div>
            )}
            {isLoading && (
                <div className="relative w-full overflow-hidden rounded-[20px]">
                    <Skeleton variant="rounded" height={600}></Skeleton>
                    <StripeIcon className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"></StripeIcon>
                </div>
            )}
        </>
    );
}

export default AddPaymentMethod;
