import React, { useEffect, useState } from "react";
import LandingLayout from "@/components/LandingLayout";
import useProfile from "@/hooks/useProfile";
import PersonDirectory from "./person_directory/PersonDirectory";
import AlphabetGrid from "./alphabet_grid/AlphabetGrid";
import ContactList from "./contacts/ContactList";

function DirectoryScene() {
    const { getKompassProfile } = useProfile();
    const [loading, setLoading] = useState(true);

    const [selectedLetter, setSelectedLetter] = useState("A");

    return (
        <LandingLayout>
            <PersonDirectory />
            <AlphabetGrid selectedLetter={selectedLetter} setSelectedLetter={setSelectedLetter} />
            <ContactList selectedLetter={selectedLetter} />
        </LandingLayout>
    );
}

export default DirectoryScene;
