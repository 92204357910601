import React, { useState } from "react";

function MessagePreview({ title, content }) {
    const [isContentVisible, setIsContentVisible] = useState(false);

    const toggleVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    return (
        <div className="flex flex-col justify-center mt-6 w-full text-base rounded-lg bg-neutral-100 max-md:max-w-full">
            <div className="flex gap-10 justify-between items-center p-4 w-full font-semibold capitalize rounded-lg text-zinc-950 max-md:max-w-full">
                <div>
                    <h2 className="my-auto text-sm md:text-base">{title}</h2>
                </div>
                <button onClick={toggleVisibility} className="transform transition-transform duration-300 ease-in-out">
                    <div className={`transform transition-transform duration-300 ${isContentVisible ? "rotate-0" : "rotate-180"}`}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </div>
                </button>
            </div>
            {isContentVisible && (
                <div className="w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            )}
            <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isContentVisible ? "max-h-[500px]" : "max-h-0"}`}>
                <div className="flex-1 shrink gap-2.5 self-stretch p-4 w-full text-zinc-700 max-md:max-w-full">{content}</div>
            </div>
        </div>
    );
}

export default MessagePreview;
