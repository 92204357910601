import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CookieStorage } from "amazon-cognito-identity-js";

const userPoolId = "us-east-1_U94Z3C3bH";
const clientId = "7l48iqc7obdo3ctgkalc7fjq60";

const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
    Storage: new CookieStorage({ expires: 7 }),
};

const userPool = new CognitoUserPool(poolData);
const oneHourToDay = 1 / 24;

let currentUser = userPool.getCurrentUser();

export function deleteUser() {
    return new Promise(function (resolve, reject) {
        if (currentUser !== null) {
            currentUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                    return;
                }
                currentUser.deleteUser((err, result) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            });
        } else {
            console.log("No user is currently signed in.");
        }
    }).catch((err) => {
        throw err;
    });
}

function getCognitoUser(email, rememberMe) {
    const _poolData = {
        UserPoolId: `${userPoolId}`,
        ClientId: `${clientId}`,
        Storage: new CookieStorage({ expires: rememberMe ? 7 : oneHourToDay }),
    };

    const _userPool = new CognitoUserPool(_poolData);

    const userData = {
        Username: email,
        Pool: _userPool,
        Storage: new CookieStorage({ expires: rememberMe ? 7 : oneHourToDay }),
    };

    return new CognitoUser(userData);
}

export async function getSession() {
    return new Promise(function (resolve, reject) {
        currentUser.getSession(function (err, session) {
            if (err) {
                reject(err);
            } else {
                resolve(session);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function signUpUserWithEmail(email, password, customData, validationData) {
    return new Promise(function (resolve, reject) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: "email",
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: "custom:password",
                Value: password,
            }),
            new CognitoUserAttribute({
                Name: "custom:userName",
                Value: customData.userName,
            }),
            new CognitoUserAttribute({
                Name: "custom:companyName",
                Value: customData.companyName,
            }),
            new CognitoUserAttribute({
                Name: "custom:mobile",
                Value: customData.mobile,
            }),
            new CognitoUserAttribute({
                Name: "custom:isGoogleAuth",
                Value: customData.isGoogleAuth ? "true" : "false",
            }),
        ];

        const validateList = [
            new CognitoUserAttribute({
                Name: "captcha",
                Value: validationData.captcha,
            }),
        ];

        userPool.signUp(email, password, attributeList, validateList, function (err, res) {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function verifyCode(email, code) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email);

        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function signInWithEmail(email, password, validationData = null, rememberMe = true) {
    return new Promise(function (resolve, reject) {
        const authenticationData = {
            Username: email,
            Password: password,
            ValidationData: validationData,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        currentUser = getCognitoUser(email, rememberMe);
        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (res) {
                resolve(res);
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    }).catch((err) => {
        throw err;
    });
}

export function signOut() {
    if (currentUser) {
        // localStorage.removeItem(STORAGE_KEY.KOMPASS_SEARCH_CONTACT_FILTER);
        // localStorage.removeItem(STORAGE_KEY.KOMPASS_SEARCH_CONTACT_FILTER);
        currentUser.signOut();
    }
}

export async function getAttributes() {
    return new Promise(function (resolve, reject) {
        currentUser.getUserAttributes(function (err, attributes) {
            if (err) {
                reject(err);
            } else {
                resolve(attributes);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function setAttribute(attribute, rememberMe) {
    return new Promise(function (resolve, reject) {
        const attributeList = [];
        const res = new CognitoUserAttribute(attribute);
        attributeList.push(res);

        currentUser.updateAttributes(attributeList, (err, res) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function sendCode(email) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email);
        if (!cognitoUser) {
            reject(`could not find ${email}`);
            return;
        }

        cognitoUser.forgotPassword({
            onSuccess: function (res) {
                resolve(res);
            },
            onFailure: function (err) {
                reject(err);
            },
            inputVerificationCode: function (data) {
                resolve(data);
            },
        });
    }).catch((err) => {
        throw err;
    });
}

export async function resendCode(email) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email);
        if (!cognitoUser) {
            reject(`could not find ${email}`);
            return;
        }

        cognitoUser.resendConfirmationCode((err) => {
            resolve(err);
        });
    }).catch((err) => {
        throw err;
    });
}

export async function forgotPassword(email, code, password) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(email);

        if (!cognitoUser) {
            reject(`could not find ${email}`);
            return;
        }

        cognitoUser.confirmPassword(code, password, {
            onSuccess: function () {
                resolve("password updated");
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    });
}

export async function changePassword(oldPassword, newPassword) {
    return new Promise(function (resolve, reject) {
        currentUser.changePassword(oldPassword, newPassword, function (err, res) {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}
