import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import React, { useState, useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useAIEngagement from "@/hooks/useAIEngagement";
import { useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function EmailHealth() {
    const [healthLoading, setHealthLoading] = useState(false);
    const [emailHealthData, setEmailHealthData] = useState(null);

    const { getEmailHealth } = useAIEngagement();

    const { email } = useParams();

    useEffect(() => {
        const fetchEmailHealth = async () => {
            setHealthLoading(true);
            const response = await getEmailHealth(email);
            if (response.status) {
                setEmailHealthData(response.data);
            }
            setHealthLoading(false);
        };
        if (email) {
            fetchEmailHealth();
        }
    }, []);

    // if (healthLoading) {
    //     return (
    //         <MainLayout>
    //             <MainTitleBar>
    //                 <p>AI Email Warmup</p>
    //             </MainTitleBar>
    //             <BaseContainer>
    //                 <div className="flex justify-center items-center h-[500px]">
    //                     <CircularProgress />
    //                 </div>
    //             </BaseContainer>
    //         </MainLayout>
    //     );
    // } else if (!emailHealthData) {
    //     return (
    //         <MainLayout>
    //             <MainTitleBar>
    //                 <p>AI Email Warmup</p>
    //             </MainTitleBar>
    //             <BaseContainer>
    //                 <div className="flex justify-center items-center h-[500px]">
    //                     {" "}
    //                     <p>Health Data not found for email: {email}</p>{" "}
    //                 </div>
    //             </BaseContainer>
    //         </MainLayout>
    //     );
    // }

    return (
        <MainLayout>
            <MainTitleBar>{/* <p>AI Email Warmup</p> */}</MainTitleBar>

            <div className="w-full bg-white mt-4 pb-10 ">
                <BaseContainer>
                    <p className="mt-5 text-xl font-medium">Email Health</p>
                    <hr className="mt-2 border-t border-dgray-300" />

                    <h1 className="text-2xl mt-10">Health Score</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mt-2">
                        <div className="border border-stone-400 rounded-lg p-5 flex gap-7">
                            <div>
                                <h1 className="text-md font-medium whitespace-nowrap">DNS Grade</h1>
                                {emailHealthData?.hasDMARC && emailHealthData?.hasSPF ? (
                                    <CheckIcon className="text-green-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                                ) : (
                                    <CloseIcon className="text-red-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                                )}
                            </div>
                            <div className="w-full">
                                <div className="flex gap-1 items-center ">
                                    <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                                    <h2>SPF</h2>
                                    <div className="ml-auto flex items-center gap-2">
                                        <p className="mb-[3px]">: </p>
                                        {emailHealthData?.hasSPF ? (
                                            <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                        ) : (
                                            <span className="h-[8px] w-[8px] inline-block bg-red-500 rounded-full"></span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                                    <h2>DMARC</h2>
                                    <div className="ml-auto flex items-center gap-2">
                                        <p className="mb-[3px]">: </p>
                                        {emailHealthData?.hasDMARC ? (
                                            <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                        ) : (
                                            <span className="h-[8px] w-[8px] inline-block bg-red-500 rounded-full"></span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                                    <h2>MX</h2>
                                    <div className="ml-auto flex items-center gap-2">
                                        <p className="mb-[3px]">: </p>
                                        <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border border-stone-400 rounded-lg p-5 flex">
                            <div>
                                <div className="flex items-center gap-1">
                                    <h1 className="text-md font-medium whitespace-nowrap">Domain Age</h1>
                                    <InfoOutlinedIcon className="!h-5 !w-5 mt-1" />
                                </div>
                                {emailHealthData?.ageGrade === "A" ? (
                                    <h1 className="text-green-500 text-5xl">A</h1>
                                ) : (
                                    <h1 className="text-yellow-600 text-5xl">{emailHealthData?.ageGrade}</h1>
                                )}
                            </div>
                            <div className="flex items-center justify-center flex-col w-full">
                                <h1 className="text-3xl font-semibold" style={{ lineHeight: "25px" }}>
                                    {emailHealthData?.domainAgeInDays}
                                </h1>
                                <p className="text-md">Days</p>
                            </div>
                        </div>

                        <div className="border border-stone-400 rounded-lg p-5 flex">
                            <div>
                                <div className="flex items-center gap-1">
                                    <h1 className="text-md font-medium whitespace-nowrap">Spam Rate</h1>
                                    <InfoOutlinedIcon className="!h-5 !w-5 mt-1" />
                                </div>
                                <CheckIcon className="text-green-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                            </div>
                            <div className="flex items-center justify-center flex-col w-full">
                                <h1 className="text-5xl font-semibold" style={{ lineHeight: "25px" }}>
                                    0 %
                                </h1>
                                <p className="text-md whitespace-nowrap mt-1">Last 14 Days</p>
                            </div>
                        </div>

                        <div className="border border-stone-400 rounded-lg p-5 flex gap-7">
                            <div>
                                <h1 className="text-md font-medium whitespace-nowrap">Blacklist</h1>
                                {!emailHealthData?.blacklisted ? (
                                    <CheckIcon className="text-green-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                                ) : (
                                    <CloseIcon className="text-red-500 !h-10 !w-10 mt-2 font-semibold text-center" />
                                )}
                            </div>
                            <div className="w-full">
                                <div className="flex gap-1 items-center ">
                                    <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                                    <h2>Email Blacklist</h2>
                                    <div className="ml-auto flex items-center gap-2">
                                        <p className="mb-[3px]">: </p>
                                        <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                    </div>
                                </div>
                                <div className="flex gap-1 items-center mt-1">
                                    <InfoOutlinedIcon className="!h-[15px] !w-[15px]" />
                                    <h2>IP Blacklist</h2>
                                    <div className="ml-auto flex items-center gap-2">
                                        <p className="mb-[3px]">: </p>
                                        {!emailHealthData?.blacklisted ? (
                                            <span className="h-[8px] w-[8px] inline-block bg-green-500 rounded-full"></span>
                                        ) : (
                                            <span className="h-[8px] w-[8px] inline-block bg-red-500 rounded-full"></span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border border-stone-400 rounded-lg  grid grid-cols-1 md:grid-cols-4 mt-10 md:grid-rows-4">
                        <div className="p-5 md:pl-0 md:col-span-1 md:border-r md:border-b md:ml-5 md:border-stone-400">
                            <div className="flex items-center gap-1">
                                <h1 className="text-md font-medium whitespace-nowrap">DNS Grade</h1>
                                {emailHealthData?.hasDMARC && emailHealthData?.hasSPF ? (
                                    <CheckIcon className="text-green-500 !h-7 !w-7 font-semibold text-center" />
                                ) : (
                                    <CloseIcon className="text-red-500 !h-7 !w-7 font-semibold text-center" />
                                )}
                            </div>
                            <p className="mt-2">Your DNS record configured property</p>
                            <p className="mt-5">
                                SPF -
                                {emailHealthData?.hasSPF ? (
                                    <span className="text-green-500"> Valid</span>
                                ) : (
                                    <span className="text-red-500"> Invalid</span>
                                )}
                            </p>
                            <p>
                                MX - <span className="text-green-500"> Valid</span>
                            </p>
                            <p>
                                DMARC -
                                {emailHealthData?.hasDMARC ? (
                                    <span className="text-green-500"> Valid</span>
                                ) : (
                                    <span className="text-red-500"> Invalid</span>
                                )}
                            </p>
                        </div>
                        <div className="md:col-span-3 md:p-5 md:pr-0  border-b border-stone-400 mx-5 md:ml-0 md:mr-5">
                            <div className="justify-end hidden md:flex">
                                <h1 className="text-md font-medium">Recommendation</h1>
                            </div>
                            {emailHealthData?.hasDMARC && emailHealthData?.hasSPF ? (
                                <p className="md:mt-5">
                                    Congratulations! Your DNS records are correctly configured, ensuring optimal inbox placement and email
                                    deliverability.
                                </p>
                            ) : (
                                <p className="md:mt-5">
                                    Your DNS records are not correctly configured. This can lead to poor inbox placement and email
                                    deliverability. Please review and update your DNS records.
                                </p>
                            )}
                            <p className="mt-2">
                                {" "}
                                <strong>SPF :</strong> These are records on your domain's DNS setting that lists the mail servers authorized
                                to send emails from your domain.{" "}
                                <a className="text-blue-500 hover:underline hover:text-blue-600" href="#">
                                    Learn more!
                                </a>
                            </p>
                            <p className="mt-2">
                                {" "}
                                <strong>DMARC :</strong> This is a method of authenticating email messages by telling receiving email
                                servers what to do after checking a domain.{" "}
                                <a className="text-blue-500 hover:underline hover:text-blue-600" href="#">
                                    Learn more!
                                </a>
                            </p>
                            <p className="mt-2 pb-5 md:pb-0">
                                {" "}
                                <strong>MX :</strong> This is a DNS record that directs email to the specified mail server. Learn more!{" "}
                                <a className="text-blue-500 hover:underline hover:text-blue-600" href="#">
                                    Learn more!
                                </a>
                            </p>
                        </div>

                        <div className="p-5 md:pl-0 md:col-span-1 md:border-r md:border-b md:ml-5 md:border-stone-400">
                            <h1 className="text-md font-medium whitespace-nowrap">Domain Age</h1>
                            {emailHealthData?.ageGrade === "A" ? (
                                <h1 className="text-green-500 text-5xl mt-1">A</h1>
                            ) : (
                                <h1 className="text-yellow-600 text-5xl mt-1">{emailHealthData?.ageGrade}</h1>
                            )}
                            <p>Completed minimum 30 days</p>
                            <p className="mt-5 text-sky-500 font-semibold text-3xl">{emailHealthData?.domainAgeInDays}</p>
                            <p className="mt-1">Age of domain</p>
                            <p className="mt-3">{emailHealthData?.humanReadableAge}</p>
                        </div>
                        <div className="md:col-span-3 md:p-5 md:pr-0  border-b border-stone-400 mx-5 md:ml-0 md:mr-5">
                            <div className="justify-end hidden md:flex">
                                <h1 className="text-md font-medium">Recommendation</h1>
                            </div>
                            {emailHealthData?.ageGrade === "A" ? (
                                <p className="md:mt-5">Domain meets recommended 30- day aging period</p>
                            ) : (
                                <p className="md:mt-5">
                                    Domain has not met the recommended 30-day aging period. This can impact email deliverability and inbox
                                    placement.
                                </p>
                            )}

                            <p className="text-lg font-semibold">Grading Criteria</p>
                            <p className="mt-2">Under 7 days = D</p>
                            <p className="mt-2">7 to 13 days = C</p>
                            <p className="mt-2">14 to 30 days = B</p>
                            <p className="mt-2 pb-5 md:pb-0">30 or more days = A</p>
                        </div>

                        <div className="p-5 md:pl-0 md:col-span-1 md:border-r md:border-b md:ml-5 md:border-stone-400">
                            <div className="flex items-center gap-1">
                                <h1 className="text-md font-medium whitespace-nowrap">Spam Rate</h1>
                                <CheckIcon className="text-green-500 !h-7 !w-7 font-semibold text-center" />
                            </div>
                            <p>Excellent!</p>
                            <p className="mt-5 text-sky-500 font-semibold text-3xl">0%</p>
                            <p className="mt-2">Spam rate in last 14 days</p>
                        </div>
                        <div className="md:col-span-3 md:p-5 md:pr-0  border-b border-stone-400 mx-5 md:ml-0 md:mr-5">
                            <div className="justify-end hidden md:flex">
                                <h1 className="text-md font-medium">Recommendation</h1>
                            </div>
                            <p className="md:mt-5 font-medium">Nice work! Keep these tips in mind for high deilverabillty:</p>
                            <p className="mt-5">Make sure your DNS records are properly configured</p>
                            <p className="mt-2">Continue email warm up to improve sender reputation</p>
                            <p className="mt-2 pb-5 md:pb-0">
                                Regularly clean your email lists. Remove bed contacts to improve deliverability and reduce spam rates.
                            </p>
                        </div>
                        <div className="p-5 md:pl-0 md:col-span-1 md:border-r md:ml-5 md:border-stone-400">
                            <div className="flex items-center gap-1">
                                <h1 className="text-md font-medium whitespace-nowrap">Blacklist</h1>
                                {!emailHealthData?.blacklisted ? (
                                    <CheckIcon className="text-green-500 !h-7 !w-7 font-semibold text-center" />
                                ) : (
                                    <CloseIcon className="text-red-500 !h-7 !w-7 font-semibold text-center" />
                                )}
                            </div>
                            <p className="mt-2">
                                IP Address: <strong>{emailHealthData?.domainIP}</strong>
                            </p>
                            {!emailHealthData?.blacklisted ? (
                                <p className="mt-5">
                                    Domain IP - <span className="text-green-500 mt-2">Not Blacklisted</span>
                                </p>
                            ) : (
                                <p className="mt-5">
                                    Domain IP - <span className="text-red-500 mt-2">Blacklisted</span>
                                </p>
                            )}
                        </div>
                        <div className="md:col-span-3 md:p-5 md:pr-0 mx-5 md:ml-0 md:mr-5">
                            <div className="justify-end hidden md:flex">
                                <h1 className="text-md font-medium">Recommendation</h1>
                            </div>
                            <p className="md:mt-12 pb-5 md:pb-0">
                                A blacklist is a real-time list that identifies IP addresses or domains that are known to send spam - it is
                                imperative to ensure your email isn't blacklisted so your emails are landing in prospect inboxes.
                            </p>
                        </div>
                    </div>
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
