import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, styled } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";

const BootstrapAutocompleteInput = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderRadius: 7,
        position: "relative",
        backgroundColor: "white",
        borderColor: "#80bdff",
        border: "none",
        fontSize: 12,
        fontFamily: "Outfit",
        padding: "1px 50px 1px 4px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 7,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)({
    fontFamily: "Outfit",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#E8E7E7",
            fontFamily: "Outfit",
        },
        "&:hover fieldset": {
            borderColor: "#E8E7E7",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#E8E7E7",
        },
    },
});

export default function FilterAutocomplete({
    value = null,
    options,
    multiple = false,
    onFilterChange,
    placeholder = null,
    disabled = false,
    showExcludeButton = true,
}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#D6E0FF",
                contrastText: "#000000",
                contrast: "#D6E0FF",
                light: "#D6E0FF",
            },
        },
    });

    const handleChange = (event, newValue) => {
        if (!multiple) {
            onFilterChange(newValue);
        }
    };

    const toggleStatus = (itemValue, newStatus) => {
        // check if the value is already in the list
        const isValueExist = value?.find((item) => item?.value === itemValue);
        if (isValueExist) {
            if (isValueExist.status !== newStatus) {
                // if the value is already in the list and the status is different, update the status
                const updatedValues = value?.map((item) => {
                    if (item?.value === itemValue) {
                        return { ...item, status: newStatus };
                    }
                    return item;
                });
                onFilterChange(updatedValues);
                return;
            }

            return;
        }
        let foundValue = options?.find((item) => item?.value === itemValue);
        const newValue = { ...(foundValue || {}), status: newStatus }; // create a copy and set the status
        const updatedValues = [...(value || []), newValue];
        onFilterChange(updatedValues);
    };

    const handleDeleteChip = (chipToDelete) => {
        const updatedValues = value
            .filter((item) => item.value !== chipToDelete?.value)
            .map((item) => {
                if (item?.value === chipToDelete?.value) {
                    const { status, ...rest } = item;
                    return rest;
                }
                return item;
            });
        onFilterChange(updatedValues);
    };
    return (
        <StyledAutocomplete
            multiple={multiple}
            sx={{ padding: 0 }}
            value={
                multiple
                    ? options?.filter((v) => value?.findIndex((item) => item?.value === v?.value || item === v?.value) >= 0)
                    : value?.label
            }
            options={options}
            onChange={handleChange}
            clearIcon={multiple ? null : <ClearIcon className="!h-4 !w-4" />}
            disablePortal
            disableCloseOnSelect={multiple}
            getOptionLabel={(option) => option?.label ?? option}
            renderInput={(params) => (
                <BootstrapAutocompleteInput
                    {...params}
                    placeholder={placeholder}
                    sx={{ paddingTop: "0px !important", paddingBottom: "0px !important", fontFamily: "Outfit" }}
                />
            )}
            renderTags={(v, getTagProps) =>
                value?.map((option, index) => (
                    <ThemeProvider theme={theme} key={index}>
                        <Chip
                            label={option?.label}
                            size="small"
                            color="primary"
                            {...getTagProps({ index })}
                            onDelete={() => handleDeleteChip(option)}
                            deleteIcon={
                                <ClearIcon
                                    sx={{
                                        color: "black",
                                        backgroundColor: "transparent",
                                        borderRadius: "50%",
                                        width: 10,
                                        height: 10,
                                    }}
                                />
                            }
                            sx={{
                                backgroundColor: option?.status === "include" ? "#D6E0FF" : "#E7E7E7",
                                color: "black",
                                "& .MuiChip-deleteIcon": {
                                    margin: "0 8px 0 -4px",
                                },
                                "& span": {
                                    fontFamily: "Outfit",
                                    fontSize: "12px",
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                    paddingRight: "12px",
                                },
                                borderRadius: "4px",
                            }}
                        />
                    </ThemeProvider>
                ))
            }
            disabled={disabled}
            renderOption={(props, option, { selected }) => {
                const { key, className, ...restProps } = props;
                if (multiple) {
                    const selectedOption = value?.find((item) => item?.value === option?.value);
                    return (
                        <li
                            key={key}
                            {...restProps}
                            className={`${className} ${selected && "!bg-dgray-100"} font-Outfit hover:!bg-dgray-100 !cursor-default flex items-center rounded-lg px-2 ml-2 mr-0.5 text-sm !z-0`}
                        >
                            <div className="mr-1">{option?.label}</div>
                            <div className="ml-auto flex items-center gap-1 text-dgray-400">
                                <p
                                    className={`cursor-pointer ${selectedOption?.status === "include" ? "text-blue-500 font-medium " : ""}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleStatus(option?.value, "include");
                                    }}
                                >
                                    Include
                                </p>
                                {showExcludeButton && (
                                    <>
                                        <p>|</p>
                                        <p
                                            className={`cursor-pointer ${selectedOption?.status === "exclude" ? "text-dgray-600 font-medium " : ""}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                toggleStatus(option?.value, "exclude");
                                            }}
                                        >
                                            Exclude
                                        </p>
                                    </>
                                )}
                            </div>
                        </li>
                    );
                } else {
                    const selectedOption = value?.value === option?.value;
                    return (
                        <li
                            {...props}
                            className={`${className} ${selectedOption && "!bg-dgray-100 font-medium"} !cursor-pointer hover:!bg-dgray-100 rounded-lg px-2 ml-2 mr-0.5 text-sm !font-normal`}
                        >
                            {option?.label}
                        </li>
                    );
                }
            }}
        />
    );
}
