import ResultsTable from "./ResultsTable";

export default function WebsiteReport({
    analyzedData,
    currentTab,
    filterProps,
    setFilterProps,
    allDomains,
    chosenDomain,
    setChosenDomain,
}) {
    console.log("analyzedData: ", analyzedData);
    return (
        <div className="p-[24px]">
            {allDomains && allDomains.length > 0 && (
                <div className="flex flex-wrap gap-1 mb-5 bg-white p-1.5 rounded-[8px] border border-[#E7E7E7]">
                    {allDomains.map((domain) => (
                        <button
                            key={domain}
                            onClick={() => setChosenDomain(domain)}
                            className={`${chosenDomain === domain ? "bg-[#f6f6f6]" : "bg-white"} relative rounded-[4px] text-[14px] text-stone-950 px-3 py-1.5 z-0`}
                        >
                            <p>{domain}</p>
                        </button>
                    ))}
                </div>
            )}
            {analyzedData && analyzedData.length > 0 && (
                <div className="overflow-x-scroll overflow-y-scroll">
                    <ResultsTable data={analyzedData} currentTab={currentTab} filterProps={filterProps} setFilterProps={setFilterProps} />
                </div>
            )}
        </div>
    );
}
