import { useState } from "react";
import DownBtnIcon from "@/assets/image/icons/downBtn.svg";
import Text from "../Text";

export default function QuestionTag(props) {
    const [showContent, setShowContent] = useState(false);
    const handleShowContent = () => {
        setShowContent(!showContent);
    };

    return (
        <div className="z-0 border-t border-stone-950 w-full max-w-[1450px]" onClick={handleShowContent}>
            <div
                className={` justify-center p-2.5 2xl:p-[12px] bg-white w-full rounded-[16px] z-0 my-6 transition-transform duration-1000`}
            >
                <div className="flex flex-row ml-3">
                    <Text variant="SubHeader1" className="w-full text-start text-stone-950 lg:!text-[22px] !font-medium">
                        {props.question}
                    </Text>
                    <button>
                        <img
                            src={DownBtnIcon}
                            width={26}
                            height={26}
                            style={showContent === true ? { transform: "rotate(180deg)" } : null}
                            alt="DownBtnIcon"
                        />
                    </button>
                </div>
                {showContent &&
                    props.answer.map((line) => <p className="text-stone-950 opacity-70 text-base md:text-xl ml-3 mt-5">{line}</p>)}
            </div>
        </div>
    );
}
