import React, { useEffect, useRef, useState } from "react";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { ReactComponent as MailSendIcon } from "@/assets/image/ai_engagement/mail-send-icon.svg";
import { ReactComponent as MailWaitIcon } from "@/assets/image/ai_engagement/mail-wait-icon.svg";
import { ReactComponent as DeleteIcon } from "@/assets/image/ai_engagement/delete.svg";
import { ReactComponent as AddIcon } from "@/assets/image/ai_engagement/add.svg";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/Done";
import useAIEngagement from "@/hooks/useAIEngagement";
import { CircularProgress, Switch, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { truncateText } from "@/utils/common";

export default function CampaignSequence({ setSelectedStep, campaignId, selectedStep }) {
    const [sequenceData, setSequenceData] = useState([]);
    const [activeSequence, setActiveSequence] = useState(null);
    const [sampleMail, setSampleMail] = useState(null);
    const [loading, setLoading] = useState({
        data: false,
        sampleMail: false,
        deleting: false,
        savingSequenceData: false,
        savingStep: false,
    });
    const [sequenceListHeight, setSequenceListHeight] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [newDaysBetween, setNewDaysBetween] = useState(0);
    const [aiTopic, setAiTopic] = useState("");
    const [isAutomatic, setIsAutomatic] = useState(true);
    const [isAiTopicEditable, setIsAiTopicEditable] = useState(false);

    const sequenceListRef = useRef(null);

    const { getAiSequences, getSampleMail, deleteSequence, addSequence, updateAllSequenceSteps, updateSingleStep } = useAIEngagement();

    useEffect(() => {
        const fetchSequenceData = async () => {
            setLoading((prev) => ({ ...prev, data: true }));
            const response = await getAiSequences(campaignId);
            if (response.status && response.data.length > 0) {
                setSequenceData(response.data);
                setActiveSequence(response.data[0]);
            }
            setLoading((prev) => ({ ...prev, data: false }));
        };
        // fetch sample mail for first sequence step
        const fetchSampleMail = async () => {
            setLoading((prev) => ({ ...prev, sampleMail: true }));
            const response = await getSampleMail(campaignId, 0);
            if (response.status) {
                setSampleMail(response.data);
            }
            setLoading((prev) => ({ ...prev, sampleMail: false }));
        };
        if (selectedStep === "3. Sequence") {
            fetchSequenceData();
            fetchSampleMail();
        }
    }, [selectedStep]);

    // for setting the height of line between icons
    useEffect(() => {
        if (sequenceListRef.current) {
            setSequenceListHeight(sequenceListRef.current.clientHeight);
        }
    }, [sequenceData.length]);

    // handle toggling for active sequence change
    useEffect(() => {
        if (activeSequence) {
            setIsAutomatic(activeSequence.templateType === "ai_topic");
            setAiTopic(activeSequence.topic || "");
            setIsAiTopicEditable(false);

            const controller = new AbortController();
            const signal = controller.signal;

            hanldeNewSampleMail(signal);

            return () => controller.abort();
        } else {
            setAiTopic("");
        }
    }, [activeSequence]);

    const hanldeNewSampleMail = async (signal) => {
        if (!activeSequence) return;
        setLoading((prev) => ({ ...prev, sampleMail: true }));
        const response = await getSampleMail(campaignId, activeSequence.sequenceNumber, signal);
        if (response.status) {
            setSampleMail(response.data);
            setLoading((prev) => ({ ...prev, sampleMail: false }));
        } else if (response.aborted) {
            setLoading((prev) => ({ ...prev, sampleMail: true }));
        } else {
            setLoading((prev) => ({ ...prev, sampleMail: false }));
        }
    };

    // handle delete sequence
    const handleDeleteSequence = async (sequenceId) => {
        const deletePromise = deleteSequence(sequenceId);
        setLoading({ ...loading, deleting: true });
        toast
            .promise(deletePromise, {
                pending: "Deleting sequence...",
                success: "Sequence deleted successfully!",
                error: "Failed to delete sequence.",
            })
            .then((response) => {
                if (response.status) {
                    const newSequenceData = sequenceData.filter((sequence) => sequence._id !== sequenceId);
                    setSequenceData(newSequenceData);

                    // check if last sequence is deleted
                    if (newSequenceData.length === 0) {
                        setActiveSequence(null);
                        setSampleMail(null);
                    } else {
                        // check if active sequence is deleted
                        if (activeSequence._id === sequenceId) {
                            setActiveSequence(newSequenceData[0]);
                        }
                    }
                }
            });
        setLoading({ ...loading, deleting: false });
    };

    // handle add sequence
    const handleAddSequence = async () => {
        const maxDaysBetween = sequenceData.length > 0 ? Math.max(...sequenceData.map((sequence) => sequence.daysBetween)) + 3 : 0;
        const payload = {
            campaignId: campaignId,
            sequenceNumber: sequenceData.length,
            daysBetween: maxDaysBetween,
            templateType: "ai_topic",
        };
        const addPromise = addSequence(payload);
        toast
            .promise(addPromise, {
                pending: "Adding sequence...",
                success: "Sequence added successfully!",
                error: "Failed to add sequence.",
            })
            .then((response) => {
                if (response.status) {
                    setSequenceData([...sequenceData, response.data]);
                    setActiveSequence(response.data);
                }
            });
    };

    // handle click on step
    const handleStepClick = (sequence) => {
        if (activeSequence._id !== sequence._id) {
            // check if ai topic is changed
            const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
            if (activeStep.topic !== activeSequence.topic) {
                updateSingleSequenceStep(sequenceData);
            }
            setActiveSequence(sequence);
        }
    };

    // handle switch for automatic and manual mode
    const handleSwitch = (e) => {
        const newIsAutomatic = e.target.checked;
        setIsAutomatic(newIsAutomatic);
        const updatedSequenceData = sequenceData.map((sequence) => {
            if (sequence._id === activeSequence._id) {
                if (newIsAutomatic) {
                    return {
                        ...sequence,
                        templateType: "ai_topic",
                    };
                }
                if (!sequence.template) {
                    return {
                        ...sequence,
                        templateType: "template",
                        template: {
                            subject: "",
                            body: "",
                        },
                    };
                }
                return {
                    ...sequence,
                    templateType: "template",
                };
            }
            return sequence;
        });
        setSequenceData(updatedSequenceData);
        if (newIsAutomatic) {
            setIsAiTopicEditable(false);
        } else {
            const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
            if (activeStep.topic == activeSequence.topic && activeStep?.template?.subject == activeSequence?.template?.subject) return;
            updateSingleSequenceStep(updatedSequenceData);
        }
    };

    // handle onChange for subject and body inputs for manual mode
    const handleTemplateDataChange = (e, field) => {
        if (activeSequence) {
            const updatedSequenceData = sequenceData.map((sequence) => {
                if (sequence._id === activeSequence._id) {
                    const updatedSequence = { ...sequence };
                    if (!updatedSequence.template) {
                        updatedSequence.template = {};
                    }
                    if (field === "subject") {
                        updatedSequence.template.subject = e.target.value;
                    } else {
                        updatedSequence.template.body = e.target.value;
                    }
                }
                return sequence;
            });
            setSequenceData(updatedSequenceData);
            // setActiveSequence(updatedSequenceData.find(sequence => sequence._id === activeSequence._id));
        }
    };

    // handle edit days
    const handleEditDays = (index) => {
        const updatedSequenceData = [...sequenceData];
        updatedSequenceData[index].daysBetween = newDaysBetween;
        const sortedData = updatedSequenceData.sort((a, b) => a.daysBetween - b.daysBetween);
        // Update the sequenceNumber based on the new order
        sortedData.forEach((sequence, idx) => {
            sequence.sequenceNumber = idx;
        });
        setSequenceData(sortedData);
        setEditIndex(null);
    };

    // save all sequence steps and go to next stage
    const handleSaveAllSequenceSteps = async () => {
        if (sequenceData.length === 0) {
            toast.error("Please add at least one sequence step.");
            return;
        }
        setLoading({ ...loading, savingSequenceData: true });
        const response = await updateAllSequenceSteps(sequenceData);
        if (response.status) {
            toast.success("All sequence steps saved successfully!");
            setSelectedStep("4. Schedule");
        } else {
            toast.error("Failed to save sequence steps.");
        }
        setLoading({ ...loading, savingSequenceData: false });
    };

    const updateSingleSequenceStep = async (sequenceData) => {
        const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
        const response = await updateSingleStep(activeStep);
        if (response.status) {
            const title = response.title;
            const updatedSequenceData = sequenceData.map((sequence) => {
                if (sequence._id === activeSequence._id) {
                    return {
                        ...sequence,
                        title,
                    };
                }
                return sequence;
            });
            setSequenceData(updatedSequenceData);
        } else {
            console.log("Failed to update sequence step");
        }
    };

    const handleAiTopicSave = async () => {
        setIsAiTopicEditable(false);
        const activeStep = sequenceData.find((sequence) => sequence._id === activeSequence._id);
        if (activeStep.topic == activeSequence.topic) return;
        setLoading({ ...loading, savingStep: true });
        await updateSingleSequenceStep(sequenceData);
        setLoading({ ...loading, savingStep: false });
        hanldeNewSampleMail();
    };

    if (loading.data) {
        return (
            <div className="flex justify-center items-center h-[400px]">
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col md:flex-row gap-5">
                {/* left side */}
                <div className="w-full md:w-[35%] min-w-[300px] relative h-full max-h-[500px] overflow-y-auto custom-scrollbar-section pr-5">
                    {/* Vertical Line */}
                    <div
                        className={`absolute left-[17.5px] 2xl:left-[19.5px] top-10 border-l-2 border-stone-200`}
                        style={{ height: sequenceListHeight + 5 }}
                    ></div>
                    <div ref={sequenceListRef}>
                        {sequenceData.length > 0 &&
                            sequenceData.map((sequence, index) => (
                                <>
                                    <div className={`flex items-center mt-10 relative z-10 ${index == 0 && "!mt-0"}`}>
                                        <div className="flex items-center justify-center p-[6px] h-10 w-10 bg-white shadow border-2 border-stone-200 rounded-full">
                                            <MailWaitIcon className="w-full h-full" />
                                        </div>
                                        {editIndex === index ? (
                                            <input
                                                type="number"
                                                value={newDaysBetween}
                                                onChange={(e) => setNewDaysBetween(e.target.value)}
                                                onBlur={() => handleEditDays(index)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleEditDays(index);
                                                    }
                                                }}
                                                className="ml-10 p-2 pl-4 bg-transparent outline-none no-spinner-input rounded-lg border border-[#E8E7E7] min-w-56"
                                                autoFocus
                                            />
                                        ) : (
                                            <p className="ml-10 p-2 pl-4 flex items-center rounded-lg border border-[#E8E7E7] min-w-56">
                                                <span>Wait for {sequence.daysBetween} days then </span>
                                                <ModeEditOutlinedIcon
                                                    className="ml-auto cursor-pointer hover:scale-110 transition-all !h-[16px] !w-[16px]"
                                                    onClick={() => {
                                                        if (!loading.deleting) {
                                                            setEditIndex(index);
                                                            setNewDaysBetween(sequenceData[index].daysBetween);
                                                        }
                                                    }}
                                                />
                                            </p>
                                        )}
                                    </div>

                                    <div className={`flex items-center mt-10 relative z-10`}>
                                        <div className="flex items-center justify-center p-[6px] h-10 w-10 bg-white shadow border-2 border-stone-200 rounded-full">
                                            <MailSendIcon className="w-full h-full" />
                                        </div>
                                        <p className="text-md font-semibold ml-10">Email follow up</p>
                                    </div>

                                    <div
                                        onClick={() => handleStepClick(sequence)}
                                        className={`ml-[68px] h-[108.5px] cursor-pointer border ${activeSequence?._id == sequence._id ? "border-blue-500" : "border-none bg-stone-100"} rounded-xl p-5 mt-3`}
                                    >
                                        <div className="flex items-center">
                                            <p>Email</p>
                                            <DeleteIcon
                                                className="ml-auto cursor-pointer hover:scale-110 transition-all"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteSequence(sequence._id);
                                                }}
                                            />
                                        </div>
                                        <p className="mt-3">
                                            {sequence.templateType == "ai_topic"
                                                ? truncateText(sequence?.title)
                                                : truncateText(sequence?.template?.subject)}
                                        </p>
                                    </div>
                                </>
                            ))}
                    </div>
                    <div className={`flex items-center mt-10 relative z-10`}>
                        <div
                            onClick={handleAddSequence}
                            className="flex items-center justify-center cursor-pointer p-[9px] h-10 w-10 bg-white shadow border-2 border-blue-500 hover:bg-stone-100 rounded-full"
                        >
                            <AddIcon className="w-full h-full" />
                        </div>
                        <p className="text-md font-semibold ml-10 text-blue-500">Add Step</p>
                    </div>
                </div>

                {/* right side */}
                <div className="w-full md:w-[65%]">
                    <div className="flex items-center">
                        <p>Stage 1: Email</p>
                        <div className="ml-auto flex gap-1 items-center">
                            {" "}
                            <Switch checked={isAutomatic} onChange={(e) => handleSwitch(e)} /> <p> Automatic </p>{" "}
                        </div>
                    </div>

                    {isAutomatic && (
                        <div className="flex w-full mt-2 relative">
                            <div className="flex items-center bg-stone-100 rounded mr-2">
                                <p className="whitespace-nowrap text-xs px-5">AI Topic</p>
                            </div>
                            <textarea
                                rows="3"
                                onChange={(e) => {
                                    setAiTopic(e.target.value);
                                    if (activeSequence) {
                                        setSequenceData(
                                            sequenceData.map((sequence) => {
                                                if (sequence._id === activeSequence._id) {
                                                    return { ...sequence, topic: e.target.value };
                                                }
                                                return sequence;
                                            })
                                        );
                                    }
                                }}
                                value={aiTopic}
                                disabled={!isAutomatic || sequenceData.length === 0 || !isAiTopicEditable}
                                className={`campaign-card-input !bg-transparent !p-2 !font-medium !rounded-md !w-full resize-none !pr-10 ${!isAiTopicEditable || !isAutomatic ? "text-dgray-500" : ""} `}
                            ></textarea>

                            {isAutomatic && (
                                <div className="absolute top-1.5 right-2">
                                    {loading.savingStep ? (
                                        <CircularProgress className="text-black !h-[18px] !w-[18px]" />
                                    ) : isAiTopicEditable ? (
                                        <CheckCircleOutlineIcon
                                            onClick={handleAiTopicSave}
                                            className="text-black !h-[18px] !w-[18px] cursor-pointer"
                                        />
                                    ) : (
                                        <ModeEditOutlinedIcon
                                            onClick={() => setIsAiTopicEditable(true)}
                                            className="text-black !h-[18px] !w-[18px] cursor-pointer"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="border-2 border-stone-200 mt-5">
                        <div className="p-3 bg-stone-200 flex items-center">
                            <span className="text-lg font-bold mr-2">Subject:</span>
                            <input
                                readOnly={isAutomatic || sequenceData.length === 0}
                                onChange={(e) => handleTemplateDataChange(e, "subject")}
                                type="text"
                                value={
                                    isAutomatic
                                        ? sampleMail?.subject || ""
                                        : sequenceData.find((seq) => seq._id == activeSequence._id)?.template?.subject
                                }
                                className="border-none bg-transparent outline-none w-full"
                            />
                            {!isAutomatic && (
                                <Tooltip
                                    title={
                                        <>
                                            Supported variables:
                                            <br />
                                            {`{contact_name}`}
                                            <br />
                                            {`{contact_company}`}
                                            <br />
                                            {`{contact_job_title}`}
                                            <br />
                                            {`{contact_industry}`}
                                        </>
                                    }
                                    placement="bottom"
                                >
                                    <span className="text-lg text-primary-blue font-bold ml-2">{"Variables"}</span>
                                </Tooltip>
                            )}
                        </div>
                        <textarea
                            readOnly={isAutomatic || sequenceData.length === 0}
                            onChange={(e) => handleTemplateDataChange(e, "body")}
                            value={
                                isAutomatic
                                    ? sampleMail?.body || ""
                                    : sequenceData.find((seq) => seq._id == activeSequence._id)?.template?.body
                            }
                            rows="12"
                            className="w-full h-full outline-none resize-none p-3"
                        ></textarea>
                    </div>

                    <div className="mt-5 flex flex-col sm:flex-row items-start sm:items-center">
                        {isAutomatic && (
                            <button
                                onClick={() => hanldeNewSampleMail()}
                                disabled={loading.sampleMail}
                                className="flex items-center gap-3 border border-blue-500 bg-stone-100 text-blue-500 text-sm py-2 px-3 rounded-md font-medium hover:opacity-85"
                            >
                                {loading.sampleMail ? (
                                    <>
                                        <CircularProgress size={14} /> <span> Refreshing... </span>
                                    </>
                                ) : (
                                    <>
                                        <CachedOutlinedIcon sx={{ fontSize: "14px" }} /> <span> Refresh Sample Contact with AI </span>
                                    </>
                                )}
                            </button>
                        )}
                        <button
                            disabled={loading.savingSequenceData}
                            onClick={handleSaveAllSequenceSteps}
                            className="sm:ml-auto sm:mt-0 mt-5 bg-blue-500 hover:bg-blue-600 cursor-pointer text-white text-sm py-2 px-16 rounded-md font-medium"
                        >
                            {loading.savingSequenceData ? (
                                <div className="flex items-center gap-3">
                                    <CircularProgress style={{ width: "20px", height: "20px" }} /> <span>Saving...</span>
                                </div>
                            ) : (
                                <span className="whitespace-nowrap">Next</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
