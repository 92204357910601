import useAuth from "@/hooks/useAuth";
import SalesforceIcon from "@/assets/image/integrations/salesforce.svg";
import HubspotIcon from "@/assets/image/integrations/hubspot.svg";
import ZapierIcon from "@/assets/image/integrations/zapier.svg";
// import OutreachIcon from "@/assets/image/integrations/outreach.svg";
import { handleCRMPushData } from "@/utils/integration";
import { handleZapierPushData, getZapierSettingsFunction, validateZapierSettings } from "@/utils/zapier";
import { handleAuthCheck } from "@/utils/integrationOAuth2";

import { useState, useEffect } from "react";

const integrations = [
    {
        label: "Salesforce",
        checkAuth: async (token) => await handleAuthCheck(token, "salesforce"),
        ui: "table",
        pushOptions: [
            {
                label: "As leads",
                handler: (data, profileType, token) => handleCRMPushData("leads", "salesforce", profileType, data, token),
                targetEntity: "Lead",
            },
            {
                label: "As contacts",
                handler: (data, profileType, token) => handleCRMPushData("contacts", "salesforce", profileType, data, token),
                targetEntity: "Contact",
            },
        ],
        icon: SalesforceIcon,
    },
    // {
    //   label: "Outreach",
    //   checkAuth: async (token) => await handleAuthCheck(token, "outreach"),
    //   pushOptions: [
    //     {
    //       label: "As prospects",
    //       handler: (data, profileType, token) =>
    //         handleCRMPushData("prospects", "outreach", profileType, data, token),
    //       targetEntity: "Prospect",
    //     },
    //   ],
    //   icon: OutreachIcon,
    // },
    {
        label: "Hubspot",
        checkAuth: async (token) => await handleAuthCheck(token, "hubspot"),
        ui: "table",
        pushOptions: [
            {
                label: "As contacts",
                handler: (data, profileType, token) => handleCRMPushData("contacts", "hubspot", profileType, data, token),
                targetEntity: "Contact",
            },
        ],
        icon: HubspotIcon,
    },
    {
        label: "Zapier",
        checkAuth: async (token) => {
            const settingsRequestResult = await getZapierSettingsFunction(token)();
            const result = await validateZapierSettings(settingsRequestResult.data);
            console.log(result);
            return result;
        },
        ui: "notification",
        pushOptions: [
            {
                label: "Send to webhooks",
                handler: (data, profileType, token) => handleZapierPushData(profileType, data, token),
                targetEntity: "Contact",
            },
        ],
        icon: ZapierIcon,
    },
];

export function useAvailableIntegrations() {
    const { getAuthToken } = useAuth();
    const [computedIntegrations, setComputedIntegrations] = useState([]);
    useEffect(() => {
        const handleCheck = async () => {
            const result = await Promise.all(
                integrations.map(async (integration) => {
                    return {
                        ...integration,
                        available: !!(await integration.checkAuth(await getAuthToken())),
                    };
                })
            );
            setComputedIntegrations(result);
        };
        handleCheck();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return computedIntegrations;
}
