export const WarmupCount = ({ title, count, maxCount, icon: Icon }) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <p className="text-black text-[20px] 2xl:text-[24px] font-semibold font-OutfitBold">
                {count}
                {maxCount ? `/${maxCount}` : ""}
            </p>
            <p className="font-Outfit text-[12px] font-normal text-black flex items-center justify-center gap-2">
                {title}
                {Icon ? (
                    <button>
                        <Icon className="w-[12px] h-[12px]" />
                    </button>
                ) : null}
            </p>
        </div>
    );
};
