import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

const ENRICHMENT = "Contact Enrichment";
const EMAIL_VALIDATION = "Email Validation";
const EMAIL_WARMUP = "Email Warmup";
const WEBSITE_VISITORS = "Website Traffic";
const AI_ENGAGEMENT = "AI Engagement";
const ALL = "All Products";

export const productFilterOptions = {
    ENRICHMENT,
    EMAIL_VALIDATION,
    EMAIL_WARMUP,
    WEBSITE_VISITORS,
    AI_ENGAGEMENT,
    ALL,
};

const options = [
    {
        label: ENRICHMENT,
        value: "ENRICHMENT",
    },
    {
        label: EMAIL_VALIDATION,
        value: "EMAIL_VALIDATION",
    },
    {
        label: EMAIL_WARMUP,
        value: "EMAIL_WARMUP",
    },
    {
        label: WEBSITE_VISITORS,
        value: "WEBSITE_VISITORS",
    },
    {
        label: AI_ENGAGEMENT,
        value: "AI_ENGAGEMENT",
    },
    {
        label: ALL,
        value: "ALL",
    },
];

export default function ProductsFilterPopup({ anchorEl, handleClose, open }) {
    return (
        <Popper anchorEl={anchorEl} open={open} transition placement="bottom-end">
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper className="mt-2">
                        {options.map((item) => {
                            return (
                                <MenuItem
                                    key={item.value}
                                    value={item.value || "ALL"}
                                    onClick={() => handleClose(item.value)}
                                    sx={{
                                        "&.MuiMenuItem-root": {
                                            fontFamily: "Outfit",
                                            textAlign: "start",
                                            fontSize: "1rem",
                                            fontWeight: 400,
                                            color: "#090C05",
                                        },
                                    }}
                                >
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
}
