import BaseContainer from "./BaseContainer";

const MainTitleBar = ({ children }) => {
    return (
        <div className="z-[52] w-full bg-white flex items-center border-b border-[#e7e7e7] filter font-Outfit text-2xl font-medium h-fit py-4 leading-[1.2]">
            <BaseContainer>{children}</BaseContainer>
        </div>
    );
};

export default MainTitleBar;
