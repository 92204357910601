import useAuth from "@/hooks/useAuth";

const useICPFilters = () => {
    const { getAuthToken } = useAuth();

    const createICPFilter = async (params) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/icp-filters/create`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params),
            });
            response = await _resData.json();
            console.log(response.data);
            console.log(response.message);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const getICPFilters = async () => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_WEB_TRAFFIC_IP_ENDPOINT}/icp-filters/get`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            response = await _resData.json();
            console.log(response.data);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    return {
        createICPFilter,
        getICPFilters,
    };
};

export default useICPFilters;
