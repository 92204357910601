function ExportsTable({ csvs }) {
    // Function to convert the array of objects to a CSV string
    function convertArrayToCSV(array) {
        const headers = Object.keys(array[0]).join(","); // Get headers
        const rows = array.map((obj) => Object.values(obj).join(",")); // Get rows
        return [headers, ...rows].join("\n");
    }

    // Function to download CSV file
    async function downloadCSV(csvContent, filename) {
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // await createExportProfileRef.current({ filename, recordCount, csv_content: data, created_at: new Date() })
    }

    const startDownload = async (index) => {
        // console.log(dataToExport)
        if (csvs[index]?.csv_content && csvs[index].csv_content.length) {
            const csvContent = convertArrayToCSV(csvs[index]?.csv_content);
            await downloadCSV(csvContent, csvs[index].filename);
        }
    };

    return (
        <div className="p-[24px] font-Outfit">
            <div className="overflow-x-auto rounded-[8px] border border-[#E7E7E7]">
                <table className="w-full table-auto border-collapse">
                    <thead className="bg-[#F6F6F6]">
                        <tr className="border-b border-[#E7E7E7] text-[12px] font-bold text-[#050505] leading-[20px]">
                            <th className="px-4 py-3 text-left">
                                <p>File</p>
                            </th>
                            <th className="px-4 py-3 text-left capitalize">
                                <p>
                                    Record Count <span className="text-[#5D5D5D] font-normal">(Includes new and repeat visitors)</span>
                                </p>
                            </th>
                            <th className="px-4 py-3 text-left">
                                <p>Created At</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {csvs &&
                            csvs.length > 0 &&
                            csvs.map((csv, index) => (
                                <tr key={index} className="border-b border-[#E7E7E7] text-[14px] leading-[20px] text-[#5D5D5D]">
                                    <td
                                        className="px-4 py-6 cursor-pointer underline hover:text-blue-500 whitespace-nowrap"
                                        onClick={() => startDownload(index)}
                                    >
                                        <p>{csv.filename}</p>
                                    </td>
                                    <td className="px-4 py-6">{csv.record_count}</td>
                                    <td className="px-4 py-6 whitespace-nowrap">{new Date(csv.created_at).toLocaleString()}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ExportsTable;
