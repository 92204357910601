import React from "react";

function LoadingSpinner() {
    return (
        <div className="flex justify-center items-center h-screen">
            <div className="spinner w-12 h-12 border-4 border-t-4 border-gray-800 rounded-full animate-spin"></div>
            <style jsx>{`
                .spinner {
                    border-color: #4b5563 #4b5563 #4b5563 transparent; /* Darker gray shade */
                }
                .animate-spin {
                    animation: spin 1s linear infinite;
                }
                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </div>
    );
}

export default LoadingSpinner;
