import useAuth from "@/hooks/useAuth";

const useScheduledEmails = () => {
    const { getAuthToken } = useAuth();

    const getPendingEmails = async () => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/getEmailsByStatus`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ emailStatus: "approval_required" }),
            });

            response = await _resData.json();
            console.log("Response: ", response);
            console.log(response.data);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const getTargetContacts = async (campaignId) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/getTargetContacts`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ campaignId }),
            });

            response = await _resData.json();
            console.log("TCL Response: ", response);
            console.log(response.data);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };

    const getScheduledEmails = async () => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/getEmailsByStatus`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ emailStatus: "scheduled" }),
            });

            response = await _resData.json();
            console.log("Response: ", response);
            console.log(response.data);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };
    const approveEmails = async (scheduledEmailIds, isApproved) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/approveEmails`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ scheduledEmailIds, isApproved }),
            });

            response = await _resData.json();
            console.log("Response: ", response);
            console.log(response.data);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };
    const editScheduledEmail = async (scheduledEmailId, subject, body, scheduledDateUTC) => {
        let response = null;
        const authToken = await getAuthToken();

        try {
            const _resData = await fetch(`${process.env.REACT_APP_KOMPASSAI_BACKEND_ENDPOINT}/campaigns/build/modifyScheduledEmail`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ scheduledEmailId, subject, body, scheduledDateUTC }),
            });

            response = await _resData.json();
            console.log("Response: ", response);
            console.log(response.data);
            if (response.status) {
                response = response.data;
            } else {
                throw new Error(response?.message);
            }
        } catch (e) {
            console.log(e);
        }
        return response;
    };
    return { getPendingEmails, getTargetContacts, getScheduledEmails, approveEmails, editScheduledEmail };
};

export default useScheduledEmails;
