import * as React from "react";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { visuallyHidden } from "@mui/utils";
import { kompassColors } from "theme/palette";
import useList from "@/hooks/useList";
import { downloadProfilesCSV, getFormattedDateFromTimestamp } from "@/utils/common";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import SearchInput from "@/components/common/SearchInput";
import MenuIcon from "@/assets/image/team/menu.svg";
import { ReactComponent as NewListIcon } from "@/assets/image/lists/newlist.svg";
import { ReactComponent as DownloadIcon } from "@/assets/image/lists/file_download.svg";
import SortIcon from "@/components/icons/SortIcon";
import { PaginationItem } from "@mui/material";
import FirstPaginationIcon from "@/components/icons/FirstPaginationIcon";
import LastPaginationIcon from "@/components/icons/LastPaginationIcon";
import SearchIcon from "@/assets/image/team/search.svg";

function descendingComparator(a, b, orderBy) {
    console.log("Sorting Here: ", orderBy);
    if (orderBy === "listTitle") {
        if (b[orderBy].toUpperCase() < a[orderBy].toUpperCase()) {
            return -1;
        }
        if (b[orderBy].toUpperCase() > a[orderBy].toUpperCase()) {
            return 1;
        }
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "listTitle",
        numeric: false,
        disablePadding: true,
        label: "List name",
    },
    {
        id: "contacts",
        numeric: false,
        disablePadding: true,
        label: "Contacts",
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: true,
        label: "Created date",
    },
    {
        id: "updatedAt",
        numeric: false,
        disablePadding: true,
        label: "Last updated",
    },
    {
        id: "",
        numeric: false,
        disablePadding: false,
        label: "",
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, numSelected, onSelectAllClick, rowCount } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                "& .MuiTableCell-sizeMedium": {
                    padding: "3px 7px",
                    fontFamily: "Outfit",
                    fontSize: 13,
                    color: kompassColors.black,
                },
                "& .MuiTableRow-head": {
                    bgcolor: "#F6F6F6",
                },
            }}
        >
            <TableRow
                sx={{
                    "& .MuiTableCell-root:first-child": {
                        borderTopLeftRadius: "8px",
                    },
                    "& .MuiTableCell-root:last-child": {
                        borderTopRightRadius: "8px",
                    },
                    "& .MuiTableCell-root": {
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < props.rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.black,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.black,
                                fill: kompassColors.black,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={"left"} padding={"none"} sortDirection={orderBy === headCell.id ? order : false}>
                        {headCell.label && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={SortIcon}
                                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                            >
                                {headCell.label}
                                {orderBy === headCell.id && (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                )}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, setSearchFilter, onCreateNewList, setSelected, onDownloadSelected } = props;

    return (
        <>
            <div className={`flex p-2 rounded-lg w-full md:w-[350px] max-w-[350px] items-center bg-white border border-stone-250`}>
                <ReactSVG
                    src={SearchIcon}
                    beforeInjection={(svg) => {
                        svg.setAttribute("style", "width: 18px; height: 18px;");
                    }}
                />
                <input
                    type="text"
                    className="w-full rounded-lg outline-none pl-2 font-Outfit font-normal text-base placeholder-dgray-500"
                    placeholder="Search"
                    onChange={(event) => {
                        setSearchFilter(event.currentTarget.value);
                    }}
                />
            </div>

            <div className="flex items-center mt-5">
                <div className="font-Outfit font-semibold">{numSelected} selected</div>
                {numSelected > 0 ? (
                    <div className="flex items-center gap-4">
                        <button
                            onClick={() => {
                                setSelected([]);
                            }}
                            className="px-2 text-base text-center underline text-blue-500 cursor-pointer font-Outfit disabled:text-stone-350"
                        >
                            Clear All
                        </button>
                    </div>
                ) : null}
                <button
                    onClick={() => {
                        onDownloadSelected();
                    }}
                    className="bg-black py-2.5 ml-auto font-OutfitMedium px-4 flex items-center gap-2 text-white whitespace-nowrap rounded-lg text-sm "
                >
                    <DownloadIcon className="w-4 h-4 text-white fill-current" /> Download Selected List
                </button>
                <button
                    onClick={() => {
                        onCreateNewList(true);
                    }}
                    className="bg-black py-2.5 ml-3 font-OutfitMedium px-4 flex items-center gap-2 text-white whitespace-nowrap rounded-lg text-sm "
                >
                    <NewListIcon className="w-4 h-4 text-white fill-current" /> Create New List
                </button>
            </div>
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function ContactListRow(props) {
    const { row, isItemSelected, labelId, handleClick, handleItemClick, handleDeleteItem, handleDownloadItem } = props;
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const menuOptions = [
        {
            icon: null,
            label: "Download",
        },
        {
            icon: null,
            label: "Delete",
        },
    ];

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const openMenu = () => {
        return menuAnchorEl !== null;
    };

    return (
        <>
            <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row._id}
                selected={false}
                sx={{
                    cursor: "pointer",
                    background: "white",
                    "& .MuiTableCell-root": {
                        border: "none",
                        borderTop: props.index === 0 ? "none" : 1,
                        borderColor: kompassColors.light,
                    },
                }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                            "aria-labelledby": labelId,
                        }}
                        sx={{
                            "&.Mui-checked": {
                                color: kompassColors.blue,
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: kompassColors.blue,
                                fill: kompassColors.blue,
                            },
                            "& .MuiSvgIcon-root": {
                                fontSize: 16,
                            },
                        }}
                        onClick={(event) => handleClick(event, row._id)}
                    />
                </TableCell>
                <TableCell onClick={() => handleItemClick(row)} component="th" id={labelId} scope="row" padding="none">
                    <div className="flex flex-col w-full">
                        <p className="font-Outfit text-base text-stone-950 font-light leading-[1.4]!">{row.listTitle}</p>
                    </div>
                </TableCell>
                <TableCell onClick={() => handleItemClick(row)} padding="none" align="left">
                    <p className="text-base font-light text-stone-950">{row.contacts}</p>
                </TableCell>
                <TableCell onClick={() => handleItemClick(row)} padding="none" align="left">
                    <p className="text-base font-light text-stone-950">{getFormattedDateFromTimestamp(row.createdAt)}</p>
                </TableCell>
                <TableCell onClick={() => handleItemClick(row)} padding="none" align="left">
                    <p className="text-base font-light text-stone-950">{getFormattedDateFromTimestamp(row.updatedAt)}</p>
                </TableCell>
                <TableCell padding="none" align="right">
                    <IconButton
                        onClick={(event) => {
                            setMenuAnchorEl(event.currentTarget);
                        }}
                    >
                        <ReactSVG
                            src={MenuIcon}
                            beforeInjection={(svg) => {
                                svg.setAttribute("style", "width: 16px; height: 16px;");
                            }}
                        />
                    </IconButton>
                </TableCell>
            </TableRow>

            <Menu
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={menuAnchorEl}
                open={openMenu()}
                onClose={handleMenuClose}
            >
                <MenuItem
                    key={menuOptions[0]}
                    onClick={() => {
                        handleMenuClose();
                        handleDownloadItem(row);
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Outfit",
                        }}
                        variant="inherit"
                    >
                        {menuOptions[0].label}
                    </Typography>
                </MenuItem>
                <MenuItem
                    key={menuOptions[1]}
                    onClick={() => {
                        handleMenuClose();
                        handleDeleteItem(row._id);
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Outfit",
                        }}
                        variant="inherit"
                    >
                        {menuOptions[1].label}
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    );
}

export default function ContactListTable(props) {
    const { setLoading, contactTableData, onContactSelected, reload, onCreateNewList } = props;

    const { deleteContactList, getProfilesByContactId } = useList();
    const [visibleDeleteDialog, handleVisibleDeleteDialog] = useState(false);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchFilter, setSearchFilter] = React.useState("");

    const tableData = contactTableData.filter((item) => {
        return item?.listTitle?.toLowerCase()?.indexOf(searchFilter.toLowerCase()) >= 0;
    });

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = tableData.map((n) => n._id);
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }
    };

    const handleDeleteSelected = async (confirmed) => {
        if (!(confirmed === true)) {
            handleVisibleDeleteDialog(true);
        } else {
            setLoading(true);
            const response = await deleteContactList({ ids: selected });
            setLoading(false);
            if (response?.status) {
                toast.success(response?.message, { theme: "colored" });
                setSelected([]);
                reload();
            } else {
                toast.error(response?.message, { theme: "colored" });
            }
        }
    };

    const handleDeleteItem = async (itemId) => {
        setLoading(true);
        const response = await deleteContactList({ ids: [itemId] });
        setLoading(false);
        if (response?.status) {
            toast.success(response?.message, { theme: "colored" });
            setSelected([]);
            reload();
        } else {
            toast.error(response?.message, { theme: "colored" });
        }
    };

    const handleDownloadSelected = async () => {
        tableData.forEach((item) => {
            const index = selected.indexOf(item?._id);
            if (index >= 0) {
                handleDownloadItem(item);
            }
        });
    };

    const handleDownloadItem = async (listItem) => {
        setLoading(true);

        const response = await getProfilesByContactId({ contactId: listItem?._id });
        console.log("PROFILES: ", response.data);

        if (response.status) {
            downloadProfilesCSV(listItem?.listTitle, [...response.data]);
        } else {
            toast.error(response.message, { theme: "colored" });
        }

        setLoading(false);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = React.useMemo(
        () => stableSort(tableData, getComparator(order, orderBy)).slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage, tableData]
    );

    return (
        <>
            <Box sx={{ width: "100%", mt: "10px" }}>
                <EnhancedTableToolbar
                    setSearchFilter={setSearchFilter}
                    numSelected={selected.length}
                    onDownloadSelected={handleDownloadSelected}
                    onCreateNewList={onCreateNewList}
                    setSelected={setSelected}
                />
                <div className="border rounded-[8px] border-stone-250 mt-5">
                    <TableContainer sx={{ borderRadius: "8px" }}>
                        <Table
                            sx={{
                                minWidth: {
                                    xs: 300,
                                    lg: 750,
                                },
                                borderCollapse: "separate",
                            }}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={tableData.length}
                                selected={selected}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody
                                sx={{
                                    "& .MuiTableCell-sizeMedium": {
                                        padding: "5px 8px",
                                        fontFamily: "Outfit",
                                        fontSize: 14,
                                        border: "1px solid #E8E7E7",
                                    },
                                }}
                            >
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <ContactListRow
                                            isItemSelected={isItemSelected}
                                            row={row}
                                            index={index}
                                            labelId={labelId}
                                            handleClick={handleClick}
                                            handleItemClick={onContactSelected}
                                            handleDeleteItem={handleDeleteItem}
                                            handleDownloadItem={handleDownloadItem}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="flex justify-center w-full pt-10 pb-20">
                    <Pagination
                        className="font-Outfit"
                        count={Math.ceil(tableData.length / rowsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                        boundaryCount={1}
                        siblingCount={0}
                        renderItem={(item) => <PaginationItem slots={{ first: FirstPaginationIcon, last: LastPaginationIcon }} {...item} />}
                        sx={{
                            "& .MuiPaginationItem-text": {
                                fontFamily: "Outfit",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: 1.43,
                                color: "#090C05",
                            },
                            "& .MuiPaginationItem-text.Mui-selected": {
                                color: "#fff",
                                backgroundColor: "black",
                                "&:hover": {
                                    backgroundColor: "gray",
                                },
                            },
                        }}
                    />
                </div>
                {/* </Paper> */}
            </Box>
            <ConfirmDeleteDialog
                open={visibleDeleteDialog}
                handleClose={() => {
                    handleVisibleDeleteDialog(false);
                }}
                handleYes={() => {
                    handleDeleteSelected(true);
                    handleVisibleDeleteDialog(false);
                }}
            />
        </>
    );
}
