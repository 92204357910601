import { useEffect, useState } from "react";
import TrendDashboard from "./TrendDashboard";
import DailyCollectedProfiles from "./DailyCollectedProfiles";
import SelectDropdown from "@/components/common/SelectDropdown";

function Analytics({ analyticsData, duration, setDuration }) {
    const [newProfiles, setNewProfiles] = useState(0);
    const [returningProfiles, setReturningProfiles] = useState(0);
    const [siteViews, setSiteViews] = useState(0);

    const [dataByDays, setDataByDays] = useState([]);
    const [allDomains, setAllDomains] = useState([]);
    const [chosenDomain, setChosenDomain] = useState(null);

    useEffect(() => {
        if (analyticsData?.length > 0) {
            const allDomains = analyticsData.map((data) => data.domain);

            if (allDomains?.length > 0) {
                setAllDomains(allDomains);
                setChosenDomain(allDomains[0]);
            }
        }
    }, [analyticsData]);

    useEffect(() => {
        const analyzeData = async () => {
            if (analyticsData?.length <= 0) return;

            const domainData = analyticsData?.find((data) => data.domain === chosenDomain);
            if (!domainData) return;

            const { overallAnalytics, dailyAnalytics } = domainData;

            setNewProfiles(overallAnalytics?.nonRepeatedIPs || 0);
            setReturningProfiles(overallAnalytics?.repeatedIPs || 0);
            setSiteViews(overallAnalytics?.totalIPs || 0);
            setDataByDays(dailyAnalytics || []);
        };
        analyzeData();
    }, [analyticsData, chosenDomain]);

    return (
        <>
            <div className="flex flex-wrap gap-10 justify-between items-center mb-12 p-[24px] border-b border-[#E7E7E7]">
                <div className="flex flex-col gap-2">
                    <h2 className="text-[18px] xl:text-[20px] text-[#050505] font-medium leading-[1.2]">Profile Export</h2>
                    <p className="text-[14px] xl:text-[16px] text-[#5D5D5D] md:whitespace-nowrap leading-none">
                        Analysis of your website traffic for the past {duration} for {chosenDomain}
                    </p>
                </div>
                <div className="flex gap-4 flex-col md:flex-row">
                    {allDomains?.length > 0 && (
                        <SelectDropdown
                            options={allDomains.map((domain) => {
                                return { label: domain, value: domain };
                            })}
                            selectedOption={chosenDomain}
                            defaultValue={chosenDomain}
                            onChange={setChosenDomain}
                        />
                    )}
                    <SelectDropdown
                        options={[
                            { label: "Past 7 Days", value: "week" },
                            { label: "Past 30 Days", value: "month" },
                        ]}
                        selectedOption={duration}
                        defaultValue={duration}
                        onChange={setDuration}
                    />
                </div>
            </div>
            <div className="p-[24px]">
                <TrendDashboard newProfiles={newProfiles} returningProfiles={returningProfiles} siteViews={siteViews} />
                {dataByDays?.length > 0 && (
                    <div className="mt-12 lg:w-3/4">
                        <DailyCollectedProfiles dataByDays={dataByDays} />
                        {/* <VisitorsAnalytics /> */}
                    </div>
                )}
            </div>
        </>
    );
}

export default Analytics;
